import { getRandomInt } from '@/helpers'

export class SystemNotification {

  private _id: number
  private _message: string
  private _type: 'error' | 'success' | 'warning' | 'message'
  private _action?: SystemNotificationAction
  private _errors?: { [key: string]: string | Array<{ [key: string]: string }> }

  private _show: boolean = true
  private _showTime: number = 5000

  constructor({ message, type = 'message', action, errors }: SetSystemNotification) {
    this._id = getRandomInt(0, 100)
    this._type = type
    this._message = message
    this._action = action
    this._errors = errors
    // If no action is provided the notification will disappear automatically
    if (!action) {
      this.setInterval()
    }
  }

  private setInterval (): void {
    setTimeout(() => {
      this._show = false
    }, this._showTime)
  }

  public get id(): number {
    return this._id
  }

  public get message(): string {
    return this._message
  }

  public get action(): SystemNotificationAction | undefined {
    return this._action
  }

  public get errors(): { [key: string]: string | Array<{ [key: string]: string }> } | undefined {
    return this._errors
  }

  public get type(): 'error' | 'success' | 'warning' | 'message' {
    return this._type
  }

  public get show(): boolean {
    return this._show
  }
}
