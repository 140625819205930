import { API }              from '@/api'
import { canPerformAction } from '@/helpers/canPerformAction'

import { DocumentPatchRequest } from '@/requests/documents/DocumentPatchRequest'
import { DocumentPermissions }  from '@/models/documents/DocumentResource'
import { documentsModule } from '@/store'

export interface DocumentProjectResource {
  name: string
}

export interface IDocumentCollectionResource {
  id: number
  newDoc?: boolean
  name: string
  publish_date: string
  created_at: string
  type: string
  issuer: string
  relevance: string
  bookmarked: boolean
  embedment: string
  versions: number
  permissions: DocumentPermissions[]
  in_use: string
  projects: DocumentProjectResource[]
}

export class DocumentCollectionResource implements IDocumentCollectionResource {
  public id: number
  public newDoc?: boolean
  public name: string
  public publish_date: string
  public created_at: string
  public type: string
  public issuer: string
  public relevance: string
  public bookmarked: boolean
  public embedment: string
  public versions: number
  public permissions: DocumentPermissions[]
  public in_use: string
  public projects: DocumentProjectResource[]
  constructor({ id, newDoc, name, publish_date, created_at, type, issuer, relevance, bookmarked, embedment, versions, permissions, in_use, projects }: IDocumentCollectionResource) {
    this.id = id
    this.newDoc = newDoc
    this.name = name
    this.publish_date = publish_date
    this.created_at = created_at
    this.type = type
    this.issuer = issuer
    this.relevance = relevance
    this.bookmarked = bookmarked
    this.embedment = embedment
    this.versions = versions
    this.permissions = permissions
    this.in_use = in_use
    this.projects = projects
  }

  public async patch(body: DocumentPatchRequest): Promise<DocumentCollectionResource> {
    const { data } = await API.patch(`documents/${this.id}`, body)
    this.bookmarked = data.data.bookmarked
    this.relevance = data.data.relevance
    return this
  }


  public async markAsRead(): Promise<DocumentCollectionResource> {
    await API.patch(`documents/${this.id}/markAsRead`)
    documentsModule.setDocumentAsRead(this.id)
    this.newDoc = false
    return this
  }

  public async delete(): Promise<DocumentCollectionResource> {
    await API.delete(`documents/${this.id}`)
    return this
  }

  public canPerformAction(key: DocumentPermissions): boolean {
    return canPerformAction<DocumentPermissions>(this.permissions, key)
  }

}
