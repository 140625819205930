import { IStatusResourceCollection } from '@/models/status/StatusResourceCollection'

export interface IStatusResource {
  id: number
  label: string
  value: string
  verb: string
  color: string
  count?: number
  substantiation: boolean
  assign_roles?: string[]
  order?: number
  is_locked?: boolean
  need_allocation?: boolean
}

export class StatusResource {
  public id: number
  public label: string
  public value: string
  public verb: string
  public color: string
  public count?: number
  public substantiation: boolean = false
  public assign_roles?: string[]
  public order: number
  public is_locked: boolean
  public need_allocation: boolean

  [key: string]: number | string | boolean | string[] | undefined

  constructor(status: IStatusResource | IStatusResourceCollection) {
    this.id = status.id
    this.label = status.label
    this.value = status.value
    this.verb = status.verb
    this.color = status.color
    this.count = status.count
    this.substantiation = status.substantiation ?? false
    this.assign_roles = status.assign_roles
    this.order = status.order ?? 0

    this.is_locked = status.is_locked ?? false
    this.need_allocation = status.need_allocation ?? false
  }

}
