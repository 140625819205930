import { ComponentResource } from './ComponentResource'

export class ComponentValue {
  public component_id: number
  public data: string
  public start_id?: number
  public end_id?: number

  constructor({ component, data = '', start_id, end_id }: { component: ComponentResource, data?: string, start_id?: number, end_id?: number }) {
    this.component_id = component.id
    this.data = data
    this.start_id = start_id
    this.end_id = end_id
  }

}
