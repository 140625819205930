import { FlexibleDocumentItemResource, IFlexibleDocumentItemResource } from '@/models/flexibleDocument/FlexibleDocumentItemResource'

export interface IFlexibleDocumentComponentResource extends IFlexibleDocumentItemResource {
  label: string
  component_type: string
  component_value: string | number | Array<string | string[]>
}

export class FlexibleDocumentComponentResource extends FlexibleDocumentItemResource {
  public readonly label: string
  public component_type: string
  public component_value: string | number | Array<string | string[]>

  constructor({ label, component_value, component_type, ...item }: IFlexibleDocumentComponentResource, project_id: number) {
    super(item, project_id)
    this.label = label
    this.component_value = component_value
    this.component_type = component_type
  }

  public get heading(): string {
    return this.title || this.component_type
  }
}
