import { ProposalService }                                 from '@/services/proposal'
import { CommentsService }                                 from '@/services/comments'
import { CommentResource, CommentFilter, CommentableType } from '@/models/comments/CommentResource'
import { FlexibleDocumentElementService }                  from '@/services/flexibleDocumentElement'
import { SuggestionService }                               from '@/services/suggestion'
import { ImplementationProposal }                          from '@/services/implementationProposal'
import { CommentCreateRequest }                            from '@/requests/comments/CommentCreateRequest'
import { FlexibleDocumentService }                         from '@/services/flexibleDocument'
import { Socket }                                          from 'socket.io-client'

type Services = ProposalService | FlexibleDocumentElementService | ImplementationProposal | SuggestionService | FlexibleDocumentService

export class CommentsController {
  public loading: boolean = false
  public initialLoading: boolean = false
  public data: CommentResource[] = []
  public filter: CommentFilter = null

  private id: number | string
  private commentableType: CommentableType
  private params?: Dictionary<any>
  private project_id?: number
  private entity_id?: number

  private service: Services
  private commentsService: CommentsService

  constructor({ id, commentableType, service, params, project_id, entity_id }: { id: number | string, service: Services, commentableType: CommentableType, params?: Dictionary<any>, project_id?: number, entity_id?: number }) {
    this.id = id
    this.service = service
    if (params) this.params = params
    if (project_id) this.project_id = project_id
    if (entity_id) this.entity_id = entity_id
    this.commentableType = commentableType
    this.commentsService = new CommentsService()

    this.get()
  }

  public get items(): CommentResource[] {
    return this.data.filter((c) => this.filter ? c.status === this.filter : true)
  }

  public get hasComments(): boolean {
    return this.items.length > 0
  }

  public async get(): Promise<void> {
    this.loading = true
    try {
      const { data: comments } = await this.service.getComments(this.params)
      this.data = [...comments]
    } finally {
      this.loading = false
      this.initialLoading = false
    }
  }

  public async post(message: string): Promise<void> {
    const body: CommentCreateRequest = {
      message,
      commentable_type: this.commentableType,
      commentable_id: this.id,
      status: 'unresolved',
    }

    if (this.project_id) body.project_id = this.project_id
    if (this.entity_id) body.entity_id = this.entity_id

    const { data: comment } = await this.commentsService.post(body)
    this.data.push(comment)
  }

  public deleteComment(id: number): void {
    if (this.data) {
      const index = this.data.findIndex((comment) => comment.id === id)
      if (index > -1) {
        this.data.splice(index, 1)
      }
    }
  }

}
