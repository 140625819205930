import { TaskCollectionResource } from '@/models/tasks/TaskCollectionResource'

export class TaskPatchRequest {
  public archived: boolean = false
  public active: boolean = false
  public message: string = ''

  constructor(task: TaskCollectionResource) {
    this.archived = task.archived
    this.active = task.active
    this.message = task.message

  }

  public get isValid(): boolean {
    return !!this.message
  }

}
