
















import { Location } from 'vue-router'
import { Component, Vue, Prop } from 'vue-property-decorator'

import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'
import StatusBadge from '@/components/statuses/StatusBadge.vue'
@Component({
  components: {StatusBadge}
})
export default class ProposalSimple extends Vue {
  @Prop()
  private proposal!: ProposalSummaryCollectionResource

  @Prop({ default: false })
  private isSelected!: boolean

  @Prop({ default: false })
  private canSelect!: boolean

  @Prop({default: true})
  private canSelectMultiple!: boolean

  @Prop({ default: false })
  private isDisabled!: boolean

  private select(): void {
    this.$emit('select')
  }

  private get selectComponent(): string {
    if (this.canSelectMultiple) {
      return this.isSelected ? 'selectedBoxIcon' : 'selectBoxIcon'
    }
    return this.isSelected ? 'radiobuttonActiveIcon' : 'radiobuttonIcon'
  }
}
