



























import { truncate } from '@/helpers'
import { mixin as clickaway } from '@/plugins/vue-clickaway'
import { Component, Vue, Prop, Model } from 'vue-property-decorator'

@Component({
  mixins: [clickaway]
})
export default class DropdownInput extends Vue {

  @Model('input', { type: [String, Array, Number] })
  private readonly value!: string | number | Array<number | string>

  @Prop(String)
  private label!: string

  @Prop(String)
  private name!: string

  @Prop(Boolean)
  private disabled!: boolean

  @Prop(Array)
  private options!: SelectItem[]

  @Prop({ default: true })
  private canDeselect!: boolean

  @Prop({ required: false })
  private tooltip!: string

  private open: boolean = false

  private get hasTooltip(): boolean {
    return !!this.tooltip
  }

  private get classes(): { [key: string]: boolean } {
    return {
      disabled: this.disabled,
    }
  }

  private get selectedItem(): SelectItem | undefined {
    return this.options.find((item: SelectItem) => item.value === this.value)
  }

  private isSelected(value: string | number): boolean {
    return value === this.value
  }

  private selectItem(value: string | number): void {
    this.$emit('input', value)
    this.closeDropdown()
  }

  private closeDropdown(): void {
    this.open = false
  }

  private toggleDropdown(): void {
    if (this.disabled) {
      return
    }
    this.open = !this.open
  }

}
