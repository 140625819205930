







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Likes extends Vue {

  @Prop()
  private liked!: boolean

  @Prop()
  private likes_count!: number

  @Prop({ default: false })
  private disabled!: boolean

  private get likeClass (): object {
    return {
      'user-has-liked': this.liked,
      'has-likes': this.likes_count,
      'disabled': this.disabled
      // 'can-like' : this.userCanLike,
    }
  }

  private like (): void {
    if (this.disabled) return
    this.$emit('like')
  }
}

