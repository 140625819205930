





import { Vue, Component, Prop } from 'vue-property-decorator'

import { ProcessResource } from '@/models/process/ProcessResource'

import Modal          from '@/components/widgets/Modal.vue'
import CreateProposal from '@/components/proposal/create/Index.vue'

@Component({
  name: 'DataObjectCreateProposal',
  components: {
    Modal,
    CreateProposal
  }
})
export default class DataObjectCreateProposal extends Vue {
  // props
  @Prop({ default: false })
  private open!: boolean

  @Prop()
  private process!: ProcessResource
}
