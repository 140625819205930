



















import UserImage from '@/components/user/UserImage.vue'

import { CommentResource } from '@/models/comments/CommentResource'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    UserImage,
  },
})
export default class SimpleComment extends Vue {
  @Prop()
  private comment!: CommentResource

  private get formattedDate(): string {
    return this.comment.created_at
  }
}
