import { AxiosPromise } from 'axios'
import { BaseService }  from '@/services/base'

import { IUserResource, UserResource }                             from '@/models/users/UserResource'
import { IProcessResource, ProcessResource }                       from '@/models/process/ProcessResource'
import { ProjectResource }                                         from '@/models/projects/ProjectResource'
import { DocumentResource }                                        from '@/models/documents/DocumentResource'
import { IProjectUserResource, ProjectUserResource }               from '@/models/projects/ProjectUserResource'
import { INotificationResource, NotificationResource }             from '@/models/notifications/NotificationResource'
import { DocumentCollectionResource, IDocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'

import { ProjectPatchUserRequest }           from '@/requests/projects/ProjectPatchUserRequest'
import { ProjectUserIndexRequest }           from '@/requests/projects/ProjectUserIndexRequest'
import { ProjectUpdateNameRequest }          from '@/requests/projects/ProjectUpdateNameRequest'
import { ProjectUsersCreateRequest }         from '@/requests/projects/ProjectUsersCreateRequest'
import { ProjectDocumentStoreRequest }       from '@/requests/projects/ProjectDocumentStoreRequest'
import { ProjectUpdateEndDateRequest }       from '@/requests/projects/ProjectUpdateEndDateRequest'
import { CommentResource, ICommentResource } from '@/models/comments/CommentResource'

export class ProjectService extends BaseService {

  public project_id: number

  constructor({ project_id }: { project_id: number }) {
    super(`projects/${project_id}`)
    this.project_id = project_id
  }

  public async get(): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.get(`${this.namespace}`)
    data.data = new ProjectResource(data.data)
    return data
  }

  public async patch(body: ProjectUpdateNameRequest | ProjectUpdateEndDateRequest): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.patch(`${this.namespace}`, body.getRequestData())
    data.data = new ProjectResource(data.data)
    return data
  }

  public delete(): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}`)
  }

  /**
   * Add users to a project
   */
  public async attachUsers(users: ProjectUsersCreateRequest): Promise<IndexResponse<ProjectUserResource>> {
    const { data } = await this.client.post(`${this.namespace}/users`, users)
    data.data = data.data.map((user: any) => new ProjectUserResource(user))
    return data
  }

  /**
   * Modify the data of a user
   */
  public async patchUser(userId: number, body: ProjectPatchUserRequest): Promise<DetailResponse<ProjectUserResource>> {
    const { data } = await this.client.patch(`${this.namespace}/users/${userId}`, body)
    data.data = new ProjectUserResource(data.data)
    return data
  }

  /**
   * delete user
   */
  public deleteUser(userId: number): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/users/${userId}`)
  }

  /**
   *  Get all available users
   */
  public async getAvailableUsers(params?: ProjectUserIndexRequest): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get(`${this.namespace}/users/available`, { params })
    data.data = data.data.map((item: IUserResource) => new UserResource(item))
    return data as IndexResponse<UserResource>
  }

  /**
   *  Retrieve project users (team members) by their role.
   */
  public async getUsers(params?: ProjectUserIndexRequest): Promise<IndexResponse<ProjectUserResource>> {
    const { data } = await this.client.get(`${this.namespace}/users`, { params })
    data.data = data.data.map((item: IProjectUserResource) => new ProjectUserResource(item))
    return data as IndexResponse<ProjectUserResource>
  }

  /**
   *  Retrieve project processes.
   */
  public async getProcesses(): Promise<IndexResponse<ProcessResource>> {
    const { data } = await this.client.get(`${this.namespace}/processes`)
    data.data = data.data.map((process: IProcessResource) => new ProcessResource(process))
    return data as IndexResponse<ProcessResource>
  }

  /**
   *  Retrieve project process.
   */
  public async getProcess(process_id: number): Promise<DetailResponse<ProcessResource>> {
    const { data } = await this.client.get(`${this.namespace}/processes/${process_id}`)
    data.data = new ProcessResource(data.data)
    return data
  }

  /**
   * Add documents to a project
   */
  public async addDocuments(documents: ProjectDocumentStoreRequest): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.post(`${this.namespace}/add-documents`, documents)
    data.data = new ProjectResource(data.data)
    return data
  }

  /**
   * Delete a document form a project.
   */
  public deleteDocument(document_id: number): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/documents/${document_id}`)
  }

  /**
   * Get all documents by project ID
   */
  public async getDocuments(params: IndexParameters): Promise<IndexResponse<DocumentCollectionResource>> {
    const { data } = await this.client.get(`${this.namespace}/documents`, { params })
    data.data = data.data.map((document: IDocumentCollectionResource) => new DocumentCollectionResource(document))
    return data
  }

  /**
   * Get document by document ID and project ID
   */
  public async getDocument(document_id: number): Promise<DetailResponse<DocumentResource>> {
    const { data } = await this.client.get(`${this.namespace}/documents/${document_id}`)
    data.data = new DocumentResource(data.data)
    return data
  }

  public async getDocumentMeta(document_id: number): Promise<DetailResponse<{ [key: string]: string }>> {
    const { data } = await this.client.get(`/documents/${document_id}/meta`)
    return data
  }

  /**
   * Get diff document by document ID and project ID
   */
  public async getDocumentDiff(document_id: number): Promise<DetailResponse<DocumentResource>> {
    const { data } = await this.client.get(`${this.namespace}/documents/${document_id}/diff`)
    data.data = new DocumentResource(data.data)
    return data
  }

  public async getNotifications(namespace: 'activities' | 'notifications'): Promise<IndexResponse<NotificationResource>> {
    const { data } = await this.client.get<IndexResponse<INotificationResource>>(`${this.namespace}/${namespace}`)
    return {
      ...data,
      data: data.data.map((notification: INotificationResource) => new NotificationResource((notification)))
    }
  }

  public async readNotifications(namespace: 'activities' | 'notifications'): Promise<void> {
    await this.client.patch<IndexResponse<INotificationResource>>(`${this.namespace}/${namespace}/read`)
    return
  }

  public async getChat(params: IndexParameters): Promise<IndexResponse<CommentResource>> {
    const { data } = await this.client.get<IndexResponse<ICommentResource>>(`${this.namespace}/messages`, { params })
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async archive(): Promise<void> {
    await this.client.post(`${this.namespace}/archive`)
    return
  }

}
