











import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class StarIcon extends Vue {

  @Prop({ default: false })
  private fill!: boolean

  private get fillColor(): string {
    return this.fill ? 'currentColor' : 'transparent'
  }

}
