import { createSocket } from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'

import { ProjectUserResource, IProjectUserResource } from '@/models/projects/ProjectUserResource'
import { SuggestionService }                         from '@/services/suggestion'
import { CommentResource }                           from '@/models/comments/CommentResource'
import { CommentsController }                        from '@/controllers/comments/CommentsController'
import { Socket }                                    from 'socket.io-client'

export type SuggestableType = 'component' | 'proposal' | 'widget' | 'document_component' | 'document_widget' | 'document_chapter' | 'document_section'
export type SuggestionPermissions = 'can_suggest' | 'can_edit_suggestion' | 'can_resolve_suggestion' | 'can_delete_suggestion'
export type SuggestionFilter = 'approved' | 'declined' | null

export interface ISuggestionResource {
  id: number
  date: string
  user: IProjectUserResource
  status: 'unresolved' | 'approved' | 'declined'
  original_text: string
  modified_text: string
  diffed_text: string
  owned_by_current_user: boolean
  likes_count: number
  liked: boolean
  comments: CommentsController
  replies: CommentResource[]
  permissions: SuggestionPermissions[]
  channel: string

  created_at: string
  updated_at: string
}

export class SuggestionResource implements ISuggestionResource {
  public id: number
  public date: string
  public user: ProjectUserResource
  public status: 'unresolved' | 'approved' | 'declined'
  public original_text: string
  public modified_text: string
  public diffed_text: string
  public owned_by_current_user: boolean
  public likes_count: number
  public liked: boolean
  public comments: CommentsController
  public replies: CommentResource[]
  public permissions: SuggestionPermissions[]
  public created_at: string
  public updated_at: string

  public channel: string
  public socket?: Socket

  private suggestionService: SuggestionService

  constructor(suggestion: ISuggestionResource) {
    this.suggestionService = new SuggestionService()

    this.id = suggestion.id
    this.date = suggestion.date
    this.user = new ProjectUserResource(suggestion.user)
    this.status = suggestion.status
    this.original_text = suggestion.original_text
    this.modified_text = suggestion.modified_text
    this.diffed_text = suggestion.diffed_text
    this.owned_by_current_user = suggestion.owned_by_current_user
    this.likes_count = suggestion.likes_count
    this.liked = suggestion.liked
    this.comments = new CommentsController({
      id: suggestion.id,
      service: this.suggestionService,
      commentableType: 'suggestion',
      params: { suggestionId: suggestion.id }
    })
    this.replies = suggestion.replies ? suggestion.replies.map((reply: CommentResource) => new CommentResource(reply)) : []
    this.permissions = suggestion.permissions
    this.channel = suggestion.channel
    this.created_at = suggestion.created_at
    this.updated_at = suggestion.updated_at

    this.openSocket()
  }

  public canPerformAction(key: SuggestionPermissions): boolean {
    return canPerformAction<SuggestionPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<SuggestionResource> {
    const { data } = await this.suggestionService.get(this.id)
    this.setData(data)
    return this
  }

  public async approve(): Promise<SuggestionResource> {
    return this.updateStatus('approved')
  }

  public async decline(): Promise<SuggestionResource> {
    return this.updateStatus('declined')
  }

  public async updateStatus(status: 'declined' | 'approved'): Promise<SuggestionResource> {
    await this.suggestionService.patch(this.id, { status })
    return this
  }

  public async delete(): Promise<SuggestionResource> {
    await this.suggestionService.delete(this.id)
    return this
  }

  private setData(suggestion: SuggestionResource): void {
    this.status = suggestion.status
    this.original_text = suggestion.original_text
    this.modified_text = suggestion.modified_text
    this.diffed_text = suggestion.diffed_text
    this.replies = suggestion.replies
    this.permissions = suggestion.permissions
    this.updated_at = suggestion.updated_at
    this.likes_count = suggestion.likes_count
    this.liked = suggestion.liked
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
  }
}
