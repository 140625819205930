
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PanelSplit extends Vue {

  @Prop({ default: true }) private showPanel?: boolean
  @Prop({ default: false }) private collapsable?: boolean

  private collapseHandler(): void {
    this.$emit('panelCollapse')
  }
}
