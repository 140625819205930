import { ProposalResource }           from '@/models/proposals/ProposalResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'
import { ProposalIndexRequest }       from '@/requests/proposals/ProposalIndexRequest'
import { PROPOSALS_PER_PAGE }         from '@/helpers/constants'

export class ProposalOrder {
  public id: number
  public order: number
  public linked_proposals?: ProposalOrder[]

  constructor({ id, order, linked_proposals }: ProposalOrder) {
    this.id = id
    this.order = order
    this.linked_proposals = linked_proposals?.map((proposal) => new ProposalOrder(proposal))
  }
}

// Sort proposals based on the order property
export const orderProposals = ({ proposals, order }: { proposals: ProposalCollectionResource[], order?: ProposalOrder[] }): ProposalCollectionResource[] => {
  // If order is undefined dont sort anything and just return the proposals as is.

  if (order && order.length > 0) {
    // Also try to reorder linked_proposals recursively
    for (const proposal of proposals) {
      const linked_order = order.find(({ id }) => id === proposal.id)
      if (proposal.linked_proposals && linked_order) {
        orderProposals({ proposals: proposal.linked_proposals, order: linked_order.linked_proposals })
      }
    }

    return proposals.sort((a: ProposalCollectionResource, b: ProposalCollectionResource) => {
      const aOrder = order.find((o) => o.id === a.id)
      const bOrder = order.find((o) => o.id === b.id)
      // We need to compare proposal A to proposal B so they both need to exist in the order array to do this comparison
      return aOrder && bOrder ? aOrder.order - bOrder.order : -1
    })
  }
  return proposals
}

export const getProposalOrder = ({ proposals }: { proposals?: ProposalCollectionResource[] }): ProposalOrder[] | undefined => {
  return proposals?.map(({ id, linked_proposals  }, index) => {
    return {
      id,
      order: index,
      linked_proposals: getProposalOrder({ proposals: linked_proposals })
    }
  })
}

export const DEFAULT_PROPOSAL_FILTERS: ProposalIndexRequest  = {
  status: [],
  page: '1',
  coverage: undefined,
  search: '',
  per_page: PROPOSALS_PER_PAGE,
  column: 'created_at',
  dir: 'desc',
  parent_ids: [],
  child_ids: [],
  component_values: {},
  document_id: ''
}
