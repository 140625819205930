




























































































































































import {projectModule} from '@/store'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {PROPOSALS_PER_PAGE} from '@/helpers/constants'

// components
import Users from '@/components/list/Users.vue'
import Modal from '@/components/widgets/Modal.vue'
import TextInput from '@/components/inputs/Text.vue'
import NoItems from '@/components/partials/NoItems.vue'
import Checkbox from '@/components/inputs/Checkbox.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import PanelSplit from '@/components/widgets/PanelSplit.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import DetailsHeader from '@/components/header/DetailsHeader.vue'
import ProposalsList from '@/components/project/ProposalsList.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import WidgetList from '@/components/proposal/create/WidgetList.vue'
import ConnectionsModal from '@/components/proposal/create/Connections.vue'
import SimilarityModal from '@/components/proposal/partials/Similarity.vue'

import { WidgetResource } from '@/models/widgets/WidgetResource'
import { ProcessResource } from '@/models/process/ProcessResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'
import {ProposalSummaryCollectionResource} from '@/models/proposals/ProposalSummaryCollectionResource'

import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'
import { ProposalCreateRequest } from '@/requests/proposals/ProposalCreateRequest'
import { ProposalSimilarityRequest } from '@/requests/proposals/ProposalSimilarityRequest'
import ProposalSimple from '@/components/tiles/ProposalSimple.vue'
import NoProposals from '@/components/icons/noProposals.vue'

@Component({
  components: {
    NoProposals,
    ProposalSimple,
    Users,
    UserPicker,
    Modal,
    NoItems,
    Checkbox,
    TextInput,
    WidgetList,
    PanelSplit,
    SmallLoader,
    ResponseModal,
    DetailsHeader,
    ProposalsList,
    // Modals
    SimilarityModal,
    ConnectionsModal
  }
})
export default class CreateProposal extends Vue {
  @Prop()
  private readonly process!: ProcessResource

  @Prop({default: () => []})
  private readonly preselectedConnectedProposals!: number[]

  @Prop({default: true})
  private readonly redirect!: boolean

  private showConnect: boolean = false
  private loading: boolean = false
  private form: ProposalCreateRequest = new ProposalCreateRequest({
    widgets: this.process.widgets
  })

  private overrideRedirect: boolean = false
  private isFetchingProposals: boolean = false
  private proposals: ProposalSummaryCollectionResource[] = []

  private similarityModal: {
    open: boolean
    proposals: ProposalCollectionResource[]
    proposal: ProposalCollectionResource | null
    selected: number
  } = {
    open: false,
    proposals: [],
    proposal: null,
    selected: 0
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get needsAllocation(): boolean {
    return this.process.statuses.find((status) => status.order === 0)?.need_allocation ?? false
  }

  private get allocatedUsers(): ProjectUserResource[] {
    return this.projectUsers.filter((user) => this.form.notify_user.includes(user.id))
  }

  private get canSubmit(): boolean {
    return true
  }

  private get submitText(): string {
    return 'Create'
  }

  private get connectedProposals(): ProposalSummaryCollectionResource[] {
    return this.proposals.filter((p) => this.form.parent_ids?.includes(p.id) ?? false)
  }

  private get widgets(): WidgetResource[] | null {
    return this.process ? this.process.widgets : null
  }

  private get previousProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) - 1 ?? 0)
  }

  private get project(): ProjectResource | null {
    return projectModule.detail
  }

  // Computed user related properties
  private get canDefine(): boolean {
    return this.process
        ? this.process.canPerformAction('can_create_proposal')
        : false
  }

  private async created(): Promise<void> {
    if (this.form.parent_ids) {
      this.form.parent_ids.push(...this.preselectedConnectedProposals)
    }
    if (this.previousProcess) {
      await this.getProposals()
      this.inheritPreviousProposalName()
    }
  }

  private async getProposals(): Promise<void> {
    if (!this.previousProcess) return
    this.isFetchingProposals = true
    const data = await this.previousProcess.getAvailableProposals({ pagination: 'false' })
    this.proposals = data.data
    this.isFetchingProposals = false
  }

  private openConnectionModal(): void {
    this.showConnect = true
  }

  private closeConnectionModal(): void {
    this.showConnect = false
  }

  private addConnections(connectedProposals: number[]): void {
    this.showConnect = false
    this.$set(this.form, 'parent_ids', connectedProposals)
    this.inheritPreviousProposalName()
  }

  private inheritPreviousProposalName(): void {
    // If one proposal is selected, the proposal name is not filled yet AND the process wants to inherit proposals names from its parents we will set the proposal name with the select parent proposal
    if (this.form.parent_ids?.length === 1 && !this.form.proposal_name && this.process.inherit_parent_title) {
      const proposal = this.proposals.find((p) => p.id === this.form.parent_ids?.[0])
      if (proposal) {
        this.form.proposal_name = proposal.proposal_name
      }
    }
  }

  private createNewProposal() {
    this.checkSimilarity()
  }

  private async checkSimilarity(): Promise<void> {
    if (!this.process) return

    this.loading = true
    try {
      const body = new ProposalSimilarityRequest({proposal: this.form})
      const {proposal, similarity} = await this.process.checkSimilarity(body)
      // If similarities found show similarities modal
      if (similarity && similarity.length > 0) {
        this.$set(this.similarityModal, 'proposals', similarity)
        this.$set(this.similarityModal, 'proposal', proposal)
        this.similarityModal.selected = similarity[0].id
        this.similarityModal.open = true
        this.loading = false
        // otherwise just create
      } else this.createProposal()
    } catch (e) {
      // If api isn't working just create proposal.
      this.createProposal()
    }
  }

  private closeSimilarityModal(): void {
    this.$set(this.similarityModal, 'proposals', [])
    this.$set(this.similarityModal, 'proposal', null)
    this.similarityModal.selected = 0
    this.similarityModal.open = false
  }

  private reuseProposal(): void {
    this.createProposal(this.similarityModal.selected)
  }

  private createNew(): void {
    this.createProposal()
  }

  private async createProposal(similarity_id?: number): Promise<void> {
    if (!this.process) throw Error('process not present')

    const body = {...this.form}
    if (similarity_id) {
      body.similarity_id = similarity_id
    }

    this.loading = true
    try {
      const {data} = await this.process.createProposal(body)
      this.$emit('submit')
      this.$emit('close')
      if (this.redirect && !this.overrideRedirect) {
        await this.$router.push({
          name: 'projects-detail-proposal-detail',
          params: {
            project_id: data.project_id.toString(),
            process_id: data.process_id.toString(),
            proposal_id: data.id.toString()
          }
        })
      }
    } finally {
      this.loading = false
    }
  }

  private overrideRedirectValue(value: boolean): void {
    this.overrideRedirect = value
  }

  private isRedirectOverrideSelected(value: boolean): boolean {
    return value === this.overrideRedirect
  }

  private async createProposals(): Promise<void> {
    if (!this.process) throw Error('process not present')
    const body = {...this.form}
    this.loading = true
    try {
      const {data} = await this.process.createProposals(body)
      this.$emit('submit')
      this.$emit('close')
    } finally {
      this.loading = false
    }
  }

}
