























import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { WidgetMixin }                  from '@/plugins/widget-mixin'

import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import TextInput   from '@/components/inputs/Text.vue'
import GridViewer  from '@/components/viewer/Grid.vue'
import TableInput  from '@/components/inputs/Table.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

@Component({
  components: {
    TextInput,
    TableInput,
    GridViewer,
    SmallLoader
  }
})
export default class TableWidget extends Mixins(WidgetMixin) {
  private get titleItem(): FlexibleDocumentComponentCollectionResource | null {
    return this.item.items.find((item) => item.component_key === 'title') || null
  }
  private get tableItem(): FlexibleDocumentComponentCollectionResource | null {
    return this.item.items.find((item) => item.component_key === 'table') || null
  }
}
