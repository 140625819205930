import { formatDate, convertToDate, formatFullDate } from '@/helpers/formatDate'
import { IUserResource, UserResource }               from '@/models/users/UserResource'

export interface IDocumentPresetVersionResource {
  id: number
  user: IUserResource
  name: string
  message?: string
  diffed_values: string
  created_at: string
}

export class DocumentPresetVersionResource {
  public id: number
  public user: UserResource
  public name: string
  public message?: string
  public diffed_values: string
  public created_at: string

  constructor(version: IDocumentPresetVersionResource) {
    this.id = version.id
    this.user = new UserResource(version.user)
    this.name = version.name
    this.message = version.message
    this.diffed_values = version.diffed_values
    this.created_at = version.created_at
  }

  public get createAtDate() {
    return formatDate(convertToDate(this.created_at))
  }

  public get createAtDateTime() {
    return formatFullDate(convertToDate(this.created_at))
  }



}


