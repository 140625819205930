import { render, staticRenderFns } from "./reused.vue?vue&type=template&id=61114d3a&scoped=true&"
var script = {}
import style0 from "./reused.vue?vue&type=style&index=0&id=61114d3a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61114d3a",
  null
  
)

export default component.exports