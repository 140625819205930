export default {
  overview: {
    header: {
      title: 'All documents',
      bookmark_title: 'My bookmarks',
      search: {
        placeholder: 'search documents...',
      },
      actions: {
        upload: 'Upload document',
      },
    },
    filters: {
      all: 'All documents',
      bookmarks: 'My bookmarks'
    },
    presets: {
      update_preset: 'Update <strong>\'{preset}\'</strong> preset',
      save_preset: 'Save as new preset',
      mine: {
        title: 'My presets',
        open: 'Show all my presets',
        close: 'Show less presets',
      },
      predefined: {
        title: 'Predefined presets',
        open: 'Show all predefined presets',
        close: 'Show less predefined presets',
      }
    },
    empty: 'There are no documents found.',
    table: {
      headers: {
        name: 'Name',
        title: 'Name',
        version: 'Version',
        published_date: 'Published date',
        bookmarked: 'Bookmarked',
        document_name: 'Name',
        subject: 'Subject',
        submission_date: 'Submission date',
        publish_date: 'Publish date',
        created_at: 'Upload date',
        implementation_date: 'Implementation date',
        issuer: 'Issuer',
        type: 'Document type',
        document_type: 'Document type',
        status: 'Status',
        relevance: 'Relevance',
        embedment: 'Embedment',
        last_update: 'Last update',
        updated_at: 'Last update',
        owner: 'Owner',
        in_use: 'In use',
      },
    },
  },
  single: {
    headers: {
      submission_date: 'Published Date',
      implementation_date: 'Implementation Date',
      consultation_date: 'Consultation Date',
      consultation_deadline: 'Consultation Deadline',
      end_date: 'End Date',
      industry: 'Type of Industry',
      type: 'Issuer',
      countries: 'Applicable countries',
    },
    relevance: {
      'Relevant': 'Relevant',
      'Irrelevant': 'Not relevant',
      'NotRelevant': 'Not relevant',
      'Not Relevant': 'Not relevant',
      'Unmarked': 'Unmarked',
      'relevant': 'Relevant',
      'irrelevant': 'Not relevant',
      'notrelevant': 'Not relevant',
      'unmarked': 'Unmarked'
    }
  },
}
