

















import { formatDate }           from '@/helpers/formatDate'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway'

import Datepicker from 'vuejs-datepicker'


@Component({
  mixins: [clickaway],
  components: {
    Datepicker,
  },
})
export default class DateInput extends Vue {
  // Props
  @Prop(String)
  private name!: string

  @Prop(String)
  private label!: string

  @Prop(String)
  private readonly description!: string

  @Prop(Array)
  private errors!: ErrorResponse[]

  @Prop(String)
  private placeholder!: string

  @Prop()
  private value!: Date

  @Prop()
  private wrapper!: HTMLElement

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private fromToday!: boolean

  private horizontalClass: string = ''
  private verticalClass: string = ''

  private date: Date | null = null

  @Watch('value')
  private valueChanged(val: any): void {
    if (val === '') {
      // @ts-ignore
      this.$refs.datepicker.clearDate()
    } else {
      this.setInitialValue(val)
      this.setValue(val)
    }
  }

  private created (): void {
    if (this.value) {
      this.setInitialValue(this.value)
    } else {
      this.setInitialValue(null)
    }
  }

  private setValue (val: Date): void {
    this.$emit('input', val)
  }

  private setInitialValue (date: Date | null): void {
    if (date) {
      this.date = new Date(date)
    }
  }

  // Computed properties
  private get hasErrors (): boolean {
    return typeof this.errors === 'object' ? this.errors.length > 0 : false
  }

  private get errorString (): string | undefined {
    return this.hasErrors ? this.errors.join(', ') : undefined
  }

  private get labelString (): string {
    return this.errorString ? this.errorString : this.label
  }

  private get disabledDates (): { [key: string]: Date } {
    if (this.fromToday) {
      return {
        to: new Date()
      }
    }
    return {}
  }

  private getPosition (): void {
    const EL = this.$refs.el as HTMLElement
    const WRAPPER = this.wrapper as HTMLElement
    if (WRAPPER && WRAPPER.getBoundingClientRect) {
      this.horizontalClass = (WRAPPER.offsetWidth / 2) > (EL.getBoundingClientRect().left - WRAPPER.getBoundingClientRect().left) ? '' : 'right'
      this.verticalClass = (WRAPPER.offsetHeight / 2) > (EL.getBoundingClientRect().top - WRAPPER.getBoundingClientRect().top) ? '' : 'top'
    } else {
      this.horizontalClass = (window.innerWidth / 2) > EL.getBoundingClientRect().left ? '' : 'right'
      this.verticalClass = (window.innerHeight / 2) > EL.getBoundingClientRect().top ? '' : 'top'
    }
    if (window.innerHeight < 700) {
      this.verticalClass = ''
    }
  }

  private format(date: Date): string {
    return formatDate(date)
  }


  private toggleDatepicker (): void {
    const DATEPICKER = this.$refs.datepicker as any
    if (DATEPICKER.isOpen) {
      DATEPICKER.close()
    } else {
      DATEPICKER.showCalendar()
    }
  }

  private closeDatepicker(): void {
    const DATEPICKER = this.$refs.datepicker as any
    DATEPICKER.close()
  }

  private reopenDatepicker(): void {
    const DATEPICKER = this.$refs.datepicker as any
    setTimeout(() => {
      if (!DATEPICKER.isOpen) {
        DATEPICKER.showCalendar()
      }
    }, 100)
  }
}
