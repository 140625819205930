import account from './account.vue'
import add from './add.vue'
import addUser from './addUser.vue'
import arrow from './arrow.vue'
import arrowThick from './arrowThick.vue'
import articles from './articles.vue'
import bin from './bin.vue'
import bold from './bold.vue'
import bookmark from './bookmark.vue'
import calendar from './calendar.vue'
import chevron from './chevron.vue'
import caret from './caret.vue'
import chat from './chat.vue'
import checkmark from './checkmark.vue'
import connectParent   from './connectParent.vue'
import connectChild   from './connectChild.vue'
import comment   from './comment.vue'
import cross from './cross.vue'
import checkmarkCross from './checkmarkCross.vue'
import defineChild from './defineChild.vue'
import del from './delete.vue'
import drag from './drag.vue'
import directives from './directives.vue'
import dots from './dots.vue'
import documents from './documents.vue'
import dataobjects from './dataobjects.vue'
import doubleCheckmark from './doubleCheckmark.vue'
import download from './download.vue'
import editRound from './edit-round.vue'
import elipse from './elipse.vue'
import exclamationmark from './exclamationmark.vue'
import eye from './eye.vue'
import filter from './filter.vue'
import filterFunnel from './filterFunnel.vue'
import font from './font.vue'
import fontType from './fontType.vue'
import flexibleDocument from './flexibleDocument.vue'
import grid from './grid.vue'
import home from './home.vue'
import halfSelectedBox from './halfSelectedBox.vue'
import index from './index.vue'
import issue from './issue.vue'
import info from './info.vue'
import italic from './italic.vue'
import lock from './lock.vue'
import logo from './logo.vue'
import library from './library.vue'
import min from './min.vue'
import notification from './notification.vue'
import open from './open.vue'
import openDocument from './openDocument.vue'
import orderedList from './orderedList.vue'
import pen from './pen.vue'
import plus from './plus.vue'
import print from './print.vue'
import projectLibrary from './projectLibrary.vue'
import qa from './qa.vue'
import questionMark from './questionMark.vue'
import rating from './rating.vue'
import radiobutton from './radiobutton.vue'
import radiobuttonActive from './radiobuttonActive.vue'
import radiobuttonChecked from './radiobuttonChecked.vue'
import refresh from './refresh.vue'
import reused from './reused.vue'
import reorder from './reorder.vue'
import roundCross from './roundCross.vue'
import search from './search.vue'
import status from './status.vue'
import star from './star.vue'
import suggestion from './suggestion.vue'
import support from './support.vue'
import section from './section.vue'
import singleArticle from './singleArticle.vue'
import selectBox from './selectBox.vue'
import selectedBox from './selectedBox.vue'
import success from './success.vue'
import settings from './settings.vue'
import subscript from './subscript.vue'
import superscript from './superscript.vue'
import team from './team.vue'
import thumb from './thumb.vue'
import title from './title.vue'
import underline from './underline.vue'
import unorderedList from './unorderedList.vue'
import userDefinedTable from './userDefinedTable.vue'
import user from './user.vue'
import unlock from './unlock.vue'
import documentSearch from './documentSearch.vue'
import noDocument from './noDocument.vue'
import indentLeft from './indentLeft.vue'
import indentRight from './indentRight.vue'
import imageUpload from './imageUpload.vue'
import plusCircle from './plusCircle.vue'
import noProjects from './noProjects.vue'
import noNotifications from './noNotifications.vue'
import noDocuments from './noDocuments.vue'
import noBookmarks from './noBookmarks.vue'
import noActivities from './noActivities.vue'
import noProposals from './noProposals.vue'
import noProposalsStatus from './noProposalsStatus.vue'
import noProjectDocuments from './noProjectDocuments.vue'
import noFirstProcess from './noFirstProcess.vue'
import noComments from './noComments.vue'
import noUsers from './noUsers.vue'
import userHardHat from './userHardHat.vue'

// component icons
import frontPageTitle from './frontPageTitle.vue'
import chapter from './chapter.vue'
import footer from './footer.vue'
import image from './image.vue'
import paragraph from './paragraph.vue'
import titlePage from './titlePage.vue'
import toc from './toc.vue'
import dataTable from './dataTable.vue'
import tableList from './tableList.vue'

export const COMPONENTS: { [key: string]: any } = {
  account,
  add,
  addUser,
  arrow,
  arrowThick,
  articles,
  bin,
  bookmark,
  bold,
  calendar,
  chat,
  connectParent,
  connectChild,
  checkmark,
  comment,
  chevron,
  caret,
  cross,
  checkmarkCross,
  defineChild,
  del,
  drag,
  dots,
  directives,
  documents,
  dataobjects,
  doubleCheckmark,
  download,
  editRound,
  elipse,
  exclamationmark,
  eye,
  filter,
  filterFunnel,
  frontPageTitle,
  font,
  fontType,
  flexibleDocument,
  grid,
  home,
  halfSelectedBox,
  index,
  issue,
  info,
  italic,
  lock,
  logo,
  library,
  min,
  noComments,
  notification,
  open,
  openDocument,
  orderedList,
  pen,
  plus,
  print,
  projectLibrary,
  qa,
  questionMark,
  rating,
  refresh,
  reused,
  reorder,
  roundCross,
  search,
  status,
  star,
  singleArticle,
  selectBox,
  selectedBox,
  support,
  success,
  section,
  settings,
  subscript,
  superscript,
  team,
  thumb,
  title,
  tableList,
  underline,
  unorderedList,
  userDefinedTable,
  user,
  unlock,
  documentSearch,
  radiobutton,
  radiobuttonActive,
  radiobuttonChecked,
  noDocument,
  chapter,
  footer,
  image,
  paragraph,
  titlePage,
  toc,
  dataTable,
  indentLeft,
  indentRight,
  imageUpload,
  plusCircle,
  noProjects,
  noNotifications,
  noDocuments,
  noBookmarks,
  noActivities,
  noProposals,
  noProposalsStatus,
  noProjectDocuments,
  noFirstProcess,
  noUsers,
  suggestion,
  userHardHat
}
