




import { Component, Vue, Prop } from 'vue-property-decorator'
import CheckboxInput            from '@/components/inputs/Checkbox.vue'

@Component({
  components: {
    CheckboxInput
  }
})
export default class Checkbox extends Vue {
  @Prop({ required: true, default: null })
  public readonly value!: string
}
