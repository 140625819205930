import { WidgetType } from './FlexibleDocumentWidgetType'

import { FlexibleDocumentElementService }                 from '@/services/flexibleDocumentElement'
import { IProjectUserResource, ProjectUserResource }      from '@/models/projects/ProjectUserResource'
import { FlexibleDocumentUpdateItemAllocateUsersRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemAllocateUsersRequest'

export interface IFlexibleDocumentItemResource {
  uuid: string
  order: number
  anchor_uuid: string
  type: 'Widget' | 'Component' | 'Chapter' | 'TitlePage' | 'Section'
  component_type?: string
  widget_type?: WidgetType
  title: string
  // Not available for components
  is_locked?: boolean
  allocated_users?: IProjectUserResource[]
}

export const ELEMENT_EVENTS = {
  UPDATED: 'element updated',
  COMMENT_CREATED: 'comment created',
  COMMENT_DELETED: 'comment deleted',
  SUGGESTION_CREATED: 'suggestion created',
  SUGGESTION_UPDATED: 'suggestion updated'
}

export class FlexibleDocumentItemResource {
  public readonly uuid: string
  public order: number
  public anchor_uuid: string
  public type: 'Widget' | 'Component' | 'Chapter' | 'TitlePage' | 'Section'
  public component_type?: string
  public widget_type?: WidgetType
  public title: string
  public project_id: number
  public is_locked: boolean
  public allocated_users: ProjectUserResource[]

  protected service: FlexibleDocumentElementService

  constructor(item: IFlexibleDocumentItemResource, project_id: number) {
    this.uuid = item.uuid
    this.order = item.order
    this.anchor_uuid = item.anchor_uuid
    this.title = item.title
    this.type = item.type
    this.project_id = project_id

    this.is_locked = item.is_locked ?? false
    this.allocated_users = item.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []

    this.service = new FlexibleDocumentElementService({ project_id, element_uuid: item.uuid })
  }

  public get heading(): string {
    return this.title
  }

  public async delete(): Promise<FlexibleDocumentItemResource> {
    await this.service.delete()
    return this
  }

  public async allocateUsers(body: FlexibleDocumentUpdateItemAllocateUsersRequest): Promise<FlexibleDocumentItemResource> {
    const data = await this.service.allocateUsers({ body })
    this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
    return this
  }

  public async lock(): Promise<FlexibleDocumentItemResource> {
    const data = await this.service.lock()
    this.is_locked = data.is_locked ?? false
    return this
  }

}
