

































import orderBy from 'lodash/orderBy'
import {mixin as clickaway} from '@/plugins/vue-clickaway'
import {Component, Vue, Prop, Model} from 'vue-property-decorator'

import UserImage from '@/components/user/UserImage.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import {UserResource} from '@/models/users/UserResource'
import {ProjectUserResource} from '@/models/projects/ProjectUserResource'


@Component({
  components: {UserImage, SmallLoader},
  mixins: [clickaway]
})
export default class UserPicker extends Vue {

  @Model('input')
  private readonly value!: number[]

  @Prop()
  private readonly users!: Array<ProjectUserResource | UserResource>

  @Prop({default: false})
  private readonly multiple!: boolean

  @Prop({default: false})
  private readonly disabled!: boolean

  @Prop({default: false})
  private readonly canSearch!: boolean

  @Prop({ default: 'right' })
  private readonly horizontalPosition!: string

  @Prop({ default: false })
  private readonly loading!: boolean

  private searchString: string = ''

  private verticalPosition: 'top' | 'bottom' = 'top'
  private dropdownOpen: boolean = false

  private get transition(): 'slide-in' | 'slide-bottom' {
    return this.verticalPosition === 'top' ? 'slide-in' : 'slide-bottom'
  }

  private get positionClasses(): string[] {
    return [this.horizontalPosition, this.verticalPosition]
  }

  private get filteredUsers() {
    if (this.searchString) {
      return orderBy(this.users.filter((user) =>
          user.name.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase()) ||
          user.rolesString.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase())
      ), ['first_name'], ['asc'])
    }
    return orderBy(this.users, ['first_name'], ['asc'])
  }

  private mounted(): void {
    this.checkViewportPosition()
  }

  private formattedRole(role: string): string {
    return role.replaceAll('-', ' ')
  }

  private isActive(user: ProjectUserResource | UserResource) {
    return this.value.includes(user.id)
  }

  private toggleUser(user: ProjectUserResource | UserResource) {
    const value = [...this.value]
    if (this.multiple) {
      if (this.isActive(user)) {
        const index = this.value.findIndex((id) => user.id === id)
        value.splice(index, 1)
      } else {
        value.push(user.id)
      }
      this.$emit('input', value)
    } else {
      if (this.isActive(user)) {
        this.$emit('input', [])
      } else {
        this.$emit('input', [user.id])
      }
    }
  }

  private checkViewportPosition(): void {
    this.verticalPosition = (this.$refs.wrapper as HTMLElement).getClientRects()[0].top > window.innerHeight / 2 ? 'bottom' : 'top'
  }

  private toggleDropdown(): void {
    if (this.disabled) return
    this.$emit('toggle')
    if (!this.dropdownOpen) this.checkViewportPosition()
    this.dropdownOpen = !this.dropdownOpen
  }

}
