import { BaseService } from '@/services/base'

import { ProjectRoleResource, IProjectRoleResource }                       from '@/models/projects/ProjectRoleResource'
import { OrganisationThemeResource }                                       from '@/models/organisation/OrganisationThemeResource'
import { IProjectTypeResourceCollection, ProjectTypeResourceCollection }   from '@/models/projectType/ProjectTypeResourceCollection'
import { IOrganisationResourceCollection, OrganisationResourceCollection } from '@/models/organisation/OrganisationResourceCollection'

export class OrganisationService extends BaseService {

  constructor() {
    super(`organisation`)
  }

  /**
   * Get all roles of a specific organisation
   */
  public async getRoles(): Promise<IndexResponse<ProjectRoleResource>> {
    const { data } = await this.client.get<IndexResponse<IProjectRoleResource>>(`${this.namespace}/roles`)
    data.data = data.data.map((role: IProjectRoleResource) => new ProjectRoleResource(role))
    return data as IndexResponse<ProjectRoleResource>
  }

  /**
   * Gets theme colors of  a specific organisation
   */
  public async getTheme(): Promise<OrganisationThemeResource> {
    const { data } = await this.client.get(`${this.namespace}`)
    return new OrganisationThemeResource(data.data.theme)
  }

  /**
   * Get project types
   */
  public async getProjectTypes(): Promise<IndexResponse<ProjectTypeResourceCollection>> {
    const { data } = await this.client.get<IndexResponse<ProjectTypeResourceCollection>>(`${this.namespace}/project_types`)
    data.data = data.data.map((projectType: IProjectTypeResourceCollection) => new ProjectTypeResourceCollection(projectType))
    return data
  }

  public async getCountries(): Promise<Dictionary<string>> {
    const { data } = await this.client.get<{ data: { [key: string]: string } }>(`countries`)
    return data.data
  }

  public async getDepartments(): Promise<OrganisationResourceCollection[]> {
    const { data } = await this.client.get(`departments`)
    return data.data.map((item: IOrganisationResourceCollection) => new OrganisationResourceCollection(item))
  }

}
