import { WidgetType }    from '@/models/flexibleDocument/FlexibleDocumentWidgetType'

export interface IFlexibleDocumentDropdownOptionResource {
  label: string
  description: string
  icon: string,
  type: 'Widget' | 'Component' | 'Chapter' | 'TitlePage' | 'Section'
  component_type?: string
  widget_type?: WidgetType
  items?: FlexibleDocumentDropdownOptionResource[]
}

export class FlexibleDocumentDropdownOptionResource {
  public label: string
  public description: string
  public icon: string
  public type: 'Widget' | 'Component' | 'Chapter' | 'TitlePage' | 'Section'
  public component_type?: string
  public widget_type?: WidgetType
  public items?: FlexibleDocumentDropdownOptionResource[]

  constructor({ label, description, icon, type, component_type, widget_type, items }: IFlexibleDocumentDropdownOptionResource) {
    this.label = label
    this.description = description
    this.icon = icon
    this.type = type
    this.items = items || undefined
    this.component_type = component_type || undefined
    this.widget_type = widget_type || undefined
  }
}
