import qs from 'qs'

export const parseQuery = (query: string): object => {
  return qs.parse(query, { comma: true })
}

export const stringifyQuery = (query: object): string => {
  return qs.stringify(cleanObject(query), { arrayFormat: 'comma' })
}

export const cleanObject = (query: object) => {
  const newQuery = { ...query } as { [key: string]: any }
  for (const property in newQuery) {
    if (newQuery.hasOwnProperty((property))) {
      if (newQuery[property] === null || newQuery[property] === undefined || newQuery[property] === '' || newQuery[property].length === 0) {
        delete newQuery[property]
      }
    }
  }
  return newQuery
}
