














































































































































import isEqual from 'lodash/isEqual'
import {RawLocation, Route} from 'vue-router'
import {projectModule} from '@/store'
import truncate from 'lodash/truncate'
import {commentFilters} from '@/data/comments'
import {suggestionFilters} from '@/data/suggestions'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import Users from '@/components/list/Users.vue'
import Tabs from '@/components/widgets/Tabs.vue'
import Modal from '@/components/widgets/Modal.vue'
import Submit from '@/components/project/Submit.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import StatusPicker from '@/components/inputs/StatusPicker.vue'
import StatusButton from '@/components/buttons/StatusButton.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import DropdownFilter from '@/components/inputs/DropdownFilter.vue'

// Pages
import ProposalAudit from './Audit.vue'
import ProposalDetails from './Details.vue'
import ProposalComments from './Comments.vue'
import ProposalSuggestions from './Suggestions.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import ProposalDirectConnections from './DirectConnections.vue'
import StatusBadge from '@/components/statuses/StatusBadge.vue'

import {ProcessResource} from '@/models/process/ProcessResource'
import {ProposalResource} from '@/models/proposals/ProposalResource'
import {StatusResourceCollection} from '@/models/status/StatusResourceCollection'

import {ProposalStatusPatchRequest} from '@/requests/proposals/ProposalStatusPatchRequest'
import {ProposalAllocateUsersRequest} from '@/requests/proposals/ProposalAllocateUsersRequest'

type AvailablePages = 'Comments' | 'DirectConnections' | 'Suggestions' | 'Audit'

type ClonedRoute = Route

@Component({
  components: {
    StatusBadge,
    UserPicker,
    StatusPicker,
    Users,
    Tabs,
    Modal,
    Submit,
    SmallLoader,
    StatusButton,
    ResponseModal,
    DropdownFilter,
    // Pages
    ProposalAudit,
    ProposalDetails,
    ProposalComments,
    ProposalSuggestions,
    ProposalDirectConnections
  }
})
export default class ProposalIndex extends Vue {
  @Prop()
  private process!: ProcessResource

  @Prop()
  private proposal!: ProposalResource

  @Prop({default: () => ['Comments', 'DirectConnections', 'Suggestions', 'Audit']})
  private availablePages!: AvailablePages[]

  @Prop({default: true})
  private readonly!: boolean

  @Prop()
  private backRoute!: RawLocation | null

  private currentPage: AvailablePages = 'Comments'

  private loading: boolean = false
  private showCreateModal: boolean = false
  private selectedCommentFilter: string | null = null
  private selectedSuggestionFilter: string | null = null
  private commentFilters: SelectItem[] = commentFilters
  private suggestionFilters: SelectItem[] = suggestionFilters

  private get previousProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder(this.process.order - 1)
  }
  private get nextProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder(this.process.order + 1)
  }

  private responseModal: { open: boolean, status: StatusResourceCollection | null } = {
    open: false,
    status: null
  }
  private submitModal: { loading: boolean, open: boolean, status: StatusResourceCollection | null } = {
    loading: false,
    open: false,
    status: null
  }
  private allocatedUsersForm: { form: ProposalAllocateUsersRequest, loading: boolean } = {
    loading: false,
    form: new ProposalAllocateUsersRequest(this.proposal)
  }

  @Watch('$route')
  private onRouteChange(route: ClonedRoute, oldRoute: ClonedRoute): void {
    this.goToRoute(route, oldRoute)
  }

  private get allocationChanged() {
    const initialForm = new ProposalAllocateUsersRequest(this.proposal)
    return !isEqual(initialForm.notify_user, this.allocatedUsersForm.form.notify_user)
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get allocatedUserIds() {
    return this.proposal.allocated_users.map(({id}) => id)
  }

  private get isMarkingProcess(): boolean {
    return this.process?.process_type === 'marking' || false
  }

  private get truncatedTitle(): string {
    return this.proposal
        ? truncate(this.proposal.proposal_name, {length: 24})
        : ''
  }

  private get componentPage(): string {
    return `Proposal${this.currentPage}`
  }

  private get isOnDocument(): boolean {
    return this.$route && this.$route.name
        ? this.$route.name === 'projects-detail-document' ||
        this.$route.name === 'projects-detail-document-detail'
        : false
  }

  private created(): void {
    this.goToRoute(this.$route)
  }

  private goToRoute(route: Route, oldRoute?: Route): void {
    if (route.query) {
      if (route.query && route.query.connections) {
        this.currentPage = 'DirectConnections'
        if (route.query.connections === 'create-child') {
          this.openCreateProposalModal()
        }
      } else if (route.query.page) {
        if (route.query.page === 'comments') {
          this.currentPage = 'Comments'
        } else if (route.query.page === 'suggestions') {
          this.currentPage = 'Suggestions'
        }
        this.$nextTick(() => {
          this.$router.replace({path: route.path, query: undefined})
        })
      }
    } else if (route.params.proposal_id !== oldRoute?.params.proposal_id) {
      this.currentPage = 'Comments'
    }
  }

  private beforeDestroy(): void {
    this.proposal.destroy()
  }

  // CREATE
  private openCreateProposalModal(): void {
    this.showCreateModal = true
  }

  private closeCreateProposalModal(): void {
    this.showCreateModal = false
  }

  // SUBMIT
  private closeSubmitModal(): void {
    this.submitModal.status = null
    this.submitModal.open = false
  }

  private openResponseModal(): void {
    this.responseModal.status = this.proposal.status
    this.responseModal.open = true
  }

  private closeResponseModal(): void {
    this.responseModal.open = false
    this.responseModal.status = null
  }

  private goToPage(page: AvailablePages): void {
    this.currentPage = page
  }

  private startUpdateStatusProcess(statusValue: string) {
    this.submitModal.status = this.proposal?.statuses.find((status) => statusValue === status.value) ?? null
    if (this.submitModal.status) {
      this.submitModal.open = true
    }
  }

  private async patchStatus(form: {
    message?: string
    userIds: number[] | undefined
  }): Promise<void> {
    if (!this.submitModal.status || !this.submitModal.status.value) {
      return
    }

    this.submitModal.loading = true

    const body: ProposalStatusPatchRequest = {
      status_message: form.message,
      notify_user: form.userIds,
      status: this.submitModal.status.value
    }
    const proposalId = this.proposal.id

    try {
      this.closeSubmitModal()
      await this.proposal.patchStatus(body)
      this.proposal.audits.get()
      this.openResponseModal()
      this.allocatedUsersForm.form = new ProposalAllocateUsersRequest(this.proposal)
      if (this.isOnDocument) {
        this.modifyDomProposalStatus(proposalId, this.proposal.status.value)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.submitModal.loading = false
    }
  }

  private modifyDomProposalStatus(proposalId: number, status?: string): void {
    const proposalSpans = document.querySelectorAll(
        `span[data-proposal-id="${proposalId}"]`
    )

    let proposalClasses = [
      'defined',
      'submitted',
      'approved',
      'declined',
      'released'
    ]

    // Add classes to remove if this is a marking process
    if (this.isMarkingProcess) {
      proposalClasses = []
      for (const tagStatus of this.process?.statuses) {
        proposalClasses.push(tagStatus.value)
      }
    }

    for (const span of proposalSpans) {
      span.classList.remove(...proposalClasses)

      if (span.classList.contains('inherited') && !status) {
        span.setAttribute('data-inherited', 'true')
      } else {
        span.removeAttribute('data-proposal-id')
        span.removeAttribute('data-inherited')
      }
      if (status) {
        span.classList.add(status.toLowerCase())
        span.setAttribute('data-proposal-id', proposalId.toString())
      } else {
        span.classList.remove('proposal')
      }
    }
  }

  // Everytime we open the dropdown we set the form since the data could be changes due to websocket updates
  private setAllocationForm() {
    this.allocatedUsersForm.form = new ProposalAllocateUsersRequest(this.proposal)
  }

  private cancelAllocation(toggle: () => void) {
    this.setAllocationForm()
    toggle()
  }

  private async allocateUsers(toggle: () => void) {
    this.allocatedUsersForm.loading = true
    try {
      await this.proposal.allocateUsers(this.allocatedUsersForm.form)
      await this.proposal.audits.get()
      toggle()
    } catch (e) {
      console.error(e)
    } finally {
      this.allocatedUsersForm.loading = false
    }
  }
}
