export default {
  team_picker: {
    title: 'Members',
    search: {
      placeholder: 'Search members',
    },
    add: 'Add',
  },
  roles: {
    admin: {
      plural: 'Project admins',
      singular: 'Project admin',
      select: 'Select project admin',
      empty: 'No Project admins assigned',
    },
    owner: {
      plural: 'Domain owners',
      singular: 'Domain owner',
      select: 'Select domain owner',
      empty: 'No Domain owners assigned',
    },
    definer: {
      plural: 'Definers',
      singular: 'Definer',
      select: 'Select definer',
      empty: 'No Definers assigned',
    },
    sounder: {
      plural: 'Sounding board',
      singular: 'Sounding officer',
      select: 'Select sounding officers',
      empty: 'No Sounding officers assigned',
    },
    Definer: {
      plural: 'Definers',
      singular: 'Definer',
      select: 'Select definer',
      empty: 'No Definers assigned'
    },
    Approver: {
      plural: 'Approvers',
      singular: 'Approver',
      select: 'Select approver',
      empty: 'No Approvers assigned'
    },
    SeniorApprover: {
      plural: 'Senior Approvers',
      singular: 'Senior Approver',
      select: 'Select senior approver',
      empty: 'No Senior Approvers assigned'
    },
    SoundingBoardMember: {
      plural: 'Sounding Board Member',
      singular: 'Sounding Board Members',
      select: 'Select sounding board member',
      empty: 'No Sounding Board Members assigned'
    },
    SoundingOfficer: {
      plural: 'Sounding Board Member',
      singular: 'Sounding Board Members',
      select: 'Select sounding board member',
      empty: 'No Sounding Board Member assigned'
    },
  },
}
