
























import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { WidgetMixin } from '@/plugins/widget-mixin'

import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import TextInput   from '@/components/inputs/Text.vue'
import SmallLoader   from '@/components/loaders/SmallLoader.vue'

@Component({
    name: 'Footer',
    components: {
      TextInput,
      SmallLoader
    }
  })
export default class DataObjectWidget extends Mixins(WidgetMixin) {
  private get footerItem(): FlexibleDocumentComponentCollectionResource | null {
    return this.item.items.find((item) => item.component_key === 'footer') || null
  }

}
