import { Component, Vue } from 'vue-property-decorator'

import { BASE_COMPONENTS } from '@/components/proposal/create/baseComponents/index.ts'
import { COMPONENTS } from '@/components/icons/index.ts'
import { SPINNERS } from '@/components/spinners/index.ts'
import * as Widgets from '@/views/dashboard/projects/detail/flexible-document/widgets/index'

const widgetNames = Object.getOwnPropertyNames(Widgets)
const baseComponentNames = Object.getOwnPropertyNames(BASE_COMPONENTS)
const componentNames = Object.getOwnPropertyNames(COMPONENTS).map((component) => component + 'Icon')
const spinnerNames = Object.getOwnPropertyNames(SPINNERS)
const allComponentNames = [...widgetNames, ...baseComponentNames, ...componentNames, ...spinnerNames]


export interface IComponentExists {
  componentExists: boolean
}

@Component
export class ComponentExists extends Vue {
  private componentExists(name: string): boolean {
   return allComponentNames.includes(`${name}`)
  }
}
