









import { Vue, Component, Prop } from 'vue-property-decorator'
import TableContentsWidgetItem from './TableContentsWidgetItem.vue'
import {
  includedInTocSubItems,
  getTocIndex,
  isFlexibleDocumentChapterCollectionResource,
  isFlexibleDocumentTitlePageCollectionResource,
  isFlexibleDocumentSectionCollectionResource,
  isFlexibleDocumentWidgetCollectionResource
} from '@/helpers/FlexibleDocument'

import { FlexibleDocumentItemCollection } from '@/models/flexibleDocument/FlexibleDocumentItemCollection'
import { FlexibleDocumentWidgetCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'
import { FlexibleDocumentChapterCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentChapterCollectionResource'
import { FlexibleDocumentSectionCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentSectionCollectionResource'

@Component({
  name: 'TableContentsItem',
  components: {
    TableContentsWidgetItem
  }
})
export default class TableContentsItem extends Vue {
  @Prop()
  private index!: string | null

  @Prop()
  private item!: FlexibleDocumentChapterCollectionResource | FlexibleDocumentSectionCollectionResource | FlexibleDocumentWidgetCollectionResource

  private get headingText(): string {
    return this.index ? `${this.index} ${this.item.heading}` : `${this.item.heading}`
  }

  private get includedInTocSubItems(): FlexibleDocumentChapterCollectionResource[] {
    return this.item.items ? includedInTocSubItems(this.item.items) : []
  }

  private get isChapter(): boolean {
    return isFlexibleDocumentChapterCollectionResource(this.item) || isFlexibleDocumentTitlePageCollectionResource(this.item)
  }

  private get isSection(): boolean {
    return isFlexibleDocumentSectionCollectionResource(this.item)
  }

  private isWidget(item: FlexibleDocumentChapterCollectionResource | FlexibleDocumentSectionCollectionResource | FlexibleDocumentWidgetCollectionResource): boolean {
    return isFlexibleDocumentWidgetCollectionResource(item)
  }

  private component(item: FlexibleDocumentChapterCollectionResource | FlexibleDocumentSectionCollectionResource | FlexibleDocumentWidgetCollectionResource): 'TableContentsWidgetItem' | 'TableContentsItem' {
    if (isFlexibleDocumentWidgetCollectionResource(item)) {
      return 'TableContentsWidgetItem'
    } else {
      return 'TableContentsItem'
    }
  }

  private getTocIndex(item: FlexibleDocumentItemCollection): string | null {
    const childIndex = getTocIndex(item, this.item.items)
    return this.index && childIndex > -1 ? `${this.index}${childIndex + 1}.` : ''
  }
}
