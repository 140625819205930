export default {
  login: {
    title: 'Welcome, Log in to SparQ',
    username: {
      label: 'Username',
      placeholder: 'Type your username here...',
    },
    password: {
      label: 'Password',
      placeholder: 'Type your password here...',
    },
    new_password: {
      label: '',
      placeholder: 'New password',
    },
    confirm_password: {
      label: '',
      placeholder: 'Confirm new password',
    },
    remember_me: 'Remember me',
    login_button: 'Login',
    forgot: 'Forgot password?',
  },
  expired: {
    title: 'Please renew your password',
    password_old: {
      label: 'Current password',
      placeholder: 'Your current password'
    },
    password: {
      label: 'New password',
      placeholder: 'Your new password',
    },
    password_confirmation: {
      label: 'New password confirmation',
      placeholder: 'Repeat new password',
    },
    submit_button: 'Renew',
  },
  notices: {
    pre_maintenance: {
      title: 'Planned maintenance',
      message: 'Between May 30th and June 3rd you will not be able to access SparQ due to maintenance. <br /><ul><li>No projects and content included will be deleted</li><li>Nothing is required from your end</li></ul><small>Do not hesitate to reach out to the SparQ team if you have any questions: <a href="mailto:sparq@ing.com">SparQ@ing.com</a></small>'
    },
    in_maintenance: {
      title: 'We are currently in planned maintenance mode (extended)',
      message: 'Dear Users,<br />Unfortunately, due to unforeseen circumstances, the SparQ team is forced to extend the maintenance period until June 15 at the latest. Logging in will not be possible until that date.<br />The extension of the maintenance period is required to guarantee the data integrity and quality of the system itself. Once maintenance is completed, the SparQ team will release more information about the new features.<br />As stated in previous communication, no projects and content included will be deleted and no further action is required from your end.<br />Apologies for the inconvenience this may cause you. Do not hesitate to reach out to the SparQ team if you have any questions about this extension of the maintenance period: <a href="mailto:sparq@ing.com">SparQ@ing.com</a><br/>Best regards,<br/>The SparQ Team'
    }
  }
}
