export interface IOrganisationResourceCollection {
  id: number
  organisation_id: number
  organisation_name: string
  name: string
}

export class OrganisationResourceCollection {
  public id: number
  public organisation_id: number
  public organisation_name: string
  public name: string

  constructor({ id, organisation_id, organisation_name, name  }: IOrganisationResourceCollection) {
    this.id = id
    this.organisation_id = organisation_id
    this.organisation_name = organisation_name
    this.name = name
  }
}
