






















import { WidgetMixin } from '@/plugins/widget-mixin'
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'

import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

import TableContentsItem from './tableContentsComponents/TableContentsItem.vue'

@Component({
  components: {
    TableContentsItem,
  },
})
export default class TableContentsWidget extends Mixins(WidgetMixin) {
  @Prop({ required: true })
  private readonly document!: FlexibleDocumentResource
}
