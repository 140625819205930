
























































































































import { Route, RawLocation } from 'vue-router'
import { flexibleDocumentModule, projectModule } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { isFlexibleDocumentItemWithItems, isFlexibleDocumentSectionCollectionResource } from '@/helpers/FlexibleDocument'

import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentDropdownOptionResource } from '@/models/flexibleDocument/FlexibleDocumentDropdownOptionResource'
import { FlexibleDocumentItemCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'
import { FlexibleDocumentItemCollection } from '@/models/flexibleDocument/FlexibleDocumentItemCollection'

import { FlexibleDocumentUpdateSectionRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateSectionRequest'
import { FlexibleDocumentUpdateItemAllocateUsersRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemAllocateUsersRequest'

import Widget from '../widgets/Widget.vue'
import Modal from '@/components/modals/Default.vue'
import newItemDropdown from './NewItemDropdown.vue'
import TextInput from '@/components/inputs/Text.vue'
import Checkbox from '@/components/inputs/Checkbox.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

type watchRoute = Route

@Component({
  name: 'Section',
  components: {
    Modal,
    Widget,
    Checkbox,
    TextInput,
    UserPicker,
    SmallLoader,
    newItemDropdown
  }
})
export default class Section extends Vue {

  @Prop({ required: true, default: null })
  public item!: FlexibleDocumentItemCollection

  @Prop({ required: false, default: 0 })
  private depth!: number

  @Prop({ default: false })
  private canCreate!: boolean

  @Prop({ required: true })
  private readonly dropdownOptions!: FlexibleDocumentDropdownOptionResource[]

  @Prop()
  private readonly index!: string | null

  @Prop()
  private readonly readonly!: boolean

  @Prop({ default: false })
  private canEdit!: boolean

  @Prop({ default: false })
  private canSuggest!: boolean

  @Prop()
  private readonly document!: FlexibleDocumentResource

  private loading: boolean = false
  private errors: ErrorResponse = {}

  private deleteWarningModalShown: boolean = false
  private deleting: boolean = false

  private allocatedUsersForm: { form: FlexibleDocumentUpdateItemAllocateUsersRequest, loading: boolean } = {
    loading: false, form: new FlexibleDocumentUpdateItemAllocateUsersRequest(this.item)
  }

  private form: FlexibleDocumentUpdateSectionRequest = {
    title: '',
    include_in_toc: false
  }

  private get edit(): boolean {
    return this.$route.query.editing === this.item.uuid
  }

  private get active(): boolean {
    return this.$route.params.element_id === this.item.uuid || this.edit
  }

  // When another element is open or in editing mode make this item inactive
  private get isInActive(): boolean {
    const EDITING_OR_OPEN_ELEMENT = this.$route.query.editing || this.$route.params.element_id
    return typeof EDITING_OR_OPEN_ELEMENT === 'string' && EDITING_OR_OPEN_ELEMENT !== this.item.uuid
  }

  private get showActionbar(): boolean {
    // only show items when its not readonly, is not editing
    return !this.readonly && !this.edit
  }

  private get canStartEdit(): boolean {
    return this.canEdit && !this.item.is_locked && this.item.canPerformAction('can_edit_element')
  }

  private get canOpenEdit(): boolean {
    return this.$route.path.includes(this.item.uuid) && this.canEdit
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get commentRoute(): RawLocation {
    return {
      name: 'projects-flexible-document-element', params: { element_id: this.item?.uuid ?? '', project_id: `${this.$route.params?.project_id ?? ''}` }, query: { page: 'comments', side: 'true' }
    }
  }

  private get headingText(): string {
    return this.index ? `${this.index} ${this.item.heading}` : `${this.item.heading}`
  }

  private get items(): FlexibleDocumentItemCollectionResource[] {
    return isFlexibleDocumentItemWithItems(this.item) ? this.item.items ?? [] : []
  }

  private cancelEdit(): void {
    if (isFlexibleDocumentSectionCollectionResource(this.item)) {
      this.form.title = this.item.title
      this.form.include_in_toc = this.item.include_in_toc ?? false
      this.$router.replace({ name: this.$route.name ?? '', params: this.$route.params })
    }
  }

  private beforeDestroy() {
    if (isFlexibleDocumentSectionCollectionResource(this.item)) {
      this.item.destroy()
    }
  }

  private startEditing(): void {
    this.$router.replace({ name: this.$route.name ?? '', params: this.$route.params, query: { editing: this.item.uuid }  })
  }

  private async goToPage(): Promise<void> {
    // Dont redirect if the element is already open or is in readonly state
    if (!this.readonly && !this.canOpenEdit) {
      try {
        await this.$router.push({ name: 'projects-flexible-document-element', params: { element_id: this.item.uuid } })
      } catch (e) {
        console.error(e)
      }
    }
  }

  private async patch(): Promise<void> {
    if (isFlexibleDocumentSectionCollectionResource(this.item)) {
      this.loading = true
      try {
        await this.item.patch(this.form)
        // Disable editing
        await this.$router.replace({ name: this.$route.name ?? '', params: this.$route.params })
      } catch ({ errors }) {
        this.errors = errors ?? {}
      } finally {
        this.loading = false
      }
    }
  }

  private editing(val: boolean): void {
    this.$emit('editing', val)
  }

  private async toggleLock(): Promise<void> {
    this.loading = true
    try {
      await this.item.lock()
    } finally {
      this.loading = false
    }
  }

  private get isOnElement(): boolean {
    return this.$route.params.element_id === this.item.uuid
  }

  // Everytime we open the dropdown we set the form since the data could be changes due to websocket updates
  private setAllocationForm() {
    this.allocatedUsersForm.form = new FlexibleDocumentUpdateItemAllocateUsersRequest(this.item)
  }

  private async cancelAllocation(toggleDropdown: () => void): Promise<void> {
    this.setAllocationForm()
    toggleDropdown()
  }

  private async allocateUsers(toggleDropdown: () => void): Promise<void> {
    this.allocatedUsersForm.loading = true
    try {
      await this.item.allocateUsers(this.allocatedUsersForm.form)
      toggleDropdown()
    } catch (e) {
      console.error(e)
    } finally {
      this.allocatedUsersForm.loading = false
    }
  }

  private confirmDelete(): void {
    this.deleteWarningModalShown = true
  }

  private cancelDelete(): void {
    this.deleteWarningModalShown = false
  }

  private async deleteItem(): Promise<void> {
    this.deleting = true
    try {
      await this.item.delete()
      this.deleteWarningModalShown = false
      // Refresh flex doc to fetch all items again
      this.refreshDocument()
      if (this.isOnElement) {
        this.$router.push({ name: 'projects-flexible-document', params: { project_id: this.$route.params.project_id ?? '' } })
      }
    } finally {
      this.deleting = false
    }
  }

  private refreshDocument(): void {
    console.log('refresh section')
    flexibleDocumentModule.flexibleDocument?.refresh()
    this.document?.refresh()
  }
}
