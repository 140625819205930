import { canPerformAction } from '@/helpers/canPerformAction'
import { convertToDate, formatDate } from '@/helpers/formatDate'
import { OrganisationDocumentsService } from '@/services/organisationDocuments'
import { IProcessResource, ProcessResource } from '../process/ProcessResource'
import { ProjectCollectionResource, IProjectCollectionResource } from '../projects/ProjectCollectionResource'

export interface IOrganisationDocumentCollectionResource {
  id: number
  document_name: string
  published_date: string
  version: string
  project_info: IProjectCollectionResource
  pdf_link: string
  permissions: string[]
  processes: IProcessResource[]
  [key: string]: string | string[] | number | IProjectCollectionResource | OrganisationDocumentsService | IProcessResource[] | ((key: string) => boolean)
}

export class OrganisationDocumentCollectionResource {
  public readonly id: number
  public document_name: string
  public published_date: string
  public version: string
  public project_info: IProjectCollectionResource
  public pdf_link: string
  public processes: ProcessResource[]

  public permissions: string[]

  public service: OrganisationDocumentsService = new OrganisationDocumentsService()

  constructor({id, document_name, published_date, version, project_info, pdf_link, permissions, processes, ...rest}: IOrganisationDocumentCollectionResource) {
    Object.assign(this, rest)

    this.id = id
    this.document_name = document_name
    this.published_date = published_date
    this.version = version
    this.project_info = new ProjectCollectionResource(project_info)
    this.pdf_link = pdf_link
    this.permissions = permissions
    this.processes = processes.map((process) => new ProcessResource(process))
  }

  public get formatted_published_date(): string {
    return formatDate(convertToDate(this.published_date))
  }

  public canPerformAction(key: string): boolean {
    return canPerformAction<string>(this.permissions, key)
  }


  [key: string]: string | string[] | number | IProjectCollectionResource | OrganisationDocumentsService | ProcessResource[] | ((key: string) => boolean)
  // public async patch(body: DocumentPatchRequest): Promise<DocumentCollectionResource> {
  //   const { data } = await API.patch(`documents/${this.id}`, body)
  //   this.bookmarked = data.data.bookmarked
  //   this.relevance = data.data.relevance
  //   return this
  // }

}
