






import { Component, Vue, Prop } from 'vue-property-decorator'
import Date from '@/components/inputs/Date.vue'

@Component({
  components: {
    Date
  }})
export default class DateInput extends Vue {
  @Prop({required: true, default: null})
  public readonly value!: any

  @Prop({ default: false })
  private noToolbar!: boolean
}
