import { ProposalSummaryCollectionResource } from './ProposalSummaryCollectionResource'

export interface IProposalConnectionsResource {
  previous_linked_proposals: ProposalSummaryCollectionResource[]
  next_linked_proposals: ProposalSummaryCollectionResource[]
}

export class ProposalConnectionsResource {
  public previous_linked_proposals: ProposalSummaryCollectionResource[]
  public next_linked_proposals: ProposalSummaryCollectionResource[]

  constructor({ previous_linked_proposals, next_linked_proposals }: IProposalConnectionsResource) {
    this.previous_linked_proposals = previous_linked_proposals.map((item: any) => new ProposalSummaryCollectionResource(item))
    this.next_linked_proposals = next_linked_proposals.map((item: any) => new ProposalSummaryCollectionResource(item))
  }
}

