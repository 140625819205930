




























































































































































































































import cloneDeep from 'lodash/cloneDeep'
import { WidgetMixin } from '@/plugins/widget-mixin'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { getProposalOrder, orderProposals, ProposalOrder } from '@/helpers/proposals'
import { projectModule } from '@/store'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'
import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import {LinkedProposalIndexRequest} from '@/requests/proposals/LinkedProposalIndexRequest'

import draggable from 'vuedraggable'
import RadioInput from '@/components/inputs/Radio.vue'
import DefaultModal from '@/components/modals/Default.vue'
import CheckboxInput from '@/components/inputs/Checkbox.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProposalSimple from '@/components/tiles/ProposalSimple.vue'
import DataObjectModalSelectProcess from './DataObjectComponents/DataObjectModalSelectProcess.vue'

@Component({
  name: 'DataObjectWidget', components: {
    draggable, SmallLoader, CheckboxInput, RadioInput, DefaultModal, DropdownInput, DataObjectModalSelectProcess, ProposalSimple
  }
})
export default class DataObjectWidget extends Mixins(WidgetMixin) {
  // data
  private selectProcessModalOpen: boolean = false
  private selectConnectedProcessModalOpen: boolean = false
  private savingProcessModal: boolean = false

  private reorderModal: { open: boolean, moved: boolean, loading: boolean, proposals: ProposalCollectionResource[] } = {
    open: false, moved: false, loading: false, proposals: []
  }

  private settingsModal: { open: boolean, loading: boolean } = {
    open: false, loading: false
  }

  private get processes(): ProcessResource[] {
    return projectModule.detail?.processes ?? []
  }

  private get selectedProcess(): ProcessResource | null {
    return this.process_id ? projectModule.getProcessById(+this.process_id.component_value) : null
  }

  private get selectedConnectedProcess(): ProcessResource | null {
    return this.connected_process_id ? projectModule.getProcessById(+this.connected_process_id.component_value) : null
  }

  private get process_id(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.items.find((item) => item.component_key === 'process_id')
  }

  private get process_filters(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'process_filters') ?? null
  }

  private get status(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.items.find((item) => item.component_key === 'status')
  }

  private get proposal_ids(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.items.find((item) => item.component_key === 'proposal_ids')
  }

  private get connected_process_id(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.items.find((item) => item.component_key === 'connected_process_id')
  }

  private get connected_process_filters(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'connected_process_filters') ?? null
  }

  private get connected_status(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.items.find((item) => item.component_key === 'connected_status')
  }

  private get connected_proposal_ids(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.items.find((item) => item.component_key === 'connected_proposal_ids')
  }

  private get proposal_order_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'proposal_order') ?? null
  }

  private get proposal_show_title_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'show_title') ?? null
  }

  private get proposal_show_numbering_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'show_numbering') ?? null
  }

  private get proposal_include_toc_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'include_widget_in_toc') ?? null
  }

  private get proposal_orientation_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'orientation') ?? null
  }

  private get process_component_selection_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'process_component_selection') ?? null
  }

  private get connected_process_component_selection_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'connected_process_component_selection') ?? null
  }

  private get proposal_order(): ProposalOrder[] {
    return this.proposal_order_component?.component_value ? this.proposal_order_component.component_value.map((order: ProposalOrder) => new ProposalOrder(order)) : []
  }

  private get componentValues(): Map<string, any> {
    return new Map(this.items.map((item) => [item.component_key, item.component_value]))
  }

  private get proposals(): ProposalCollectionResource[] {
    return this.item.proposals
  }

  private get linkedProposals(): ProposalCollectionResource[] {
    return this.item.proposals.flatMap((proposal) => proposal.linked_proposals || [])
  }

  private get selectableProcesses(): ProcessResource[] {
    if (this.selectedConnectedProcess) {
      return this.processes.filter(({ order }) => {
        return this.selectedConnectedProcess ? this.selectedConnectedProcess.order > order : false
      })
    }
    return this.processes
  }

  private get selectableConnectedProcesses(): ProcessResource[] {
    if (this.selectedProcess) {
      return this.processes.filter(({ order }) => {
        return this.selectedProcess ? this.selectedProcess.order < order : false
      })
    }
    return this.processes
  }

  private get blacklistedProcessComponentIds(): number[] {
    if (this.process_component_selection_component) {
      return this.process_component_selection_component.component_value
    }

    return []
  }

  private get blacklistedConnectedProcessComponentIds(): number[] {
    if (this.connected_process_component_selection_component) {
      return this.connected_process_component_selection_component.component_value
    }

    return []
  }

  // hooks
  private async created(): Promise<void> {
    this.item.loading = true
    await this.getProposals()
    this.item.loading = false
  }

  private async openReoderModal() {
    this.reorderModal.proposals = cloneDeep(this.item.proposals)
    this.reorderModal.open = true
  }

  private goToProposal(proposal: ProposalCollectionResource): void {
    if (this.item && this.$route.params) {
      this.$router.push({ name: 'projects-flexible-document-proposal', params: { process_id: `${proposal.process_id}`, element_id: `${this.item.uuid}`, proposal_id: `${proposal.id}` } })
    }
  }

  private getViewableComponents(components: number[], blacklistedComponents: number[]): number[] {
    return components.filter((component) => !blacklistedComponents.includes(component))
  }

  private get processComponentIds(): number[] {
    if (this.selectedProcess) {
      return this.selectedProcess.widgets
        .map((widget) => widget.components
          .map(({label, id}) => id)
        )
        .flat()
    }
    return []
  }

  private get connectedProcessComponentIds(): number[] {
    if (this.selectedConnectedProcess) {
      return this.selectedConnectedProcess.widgets
        .map((widget) => widget.components
          .map(({id}) => id)
        )
        .flat()
    }
    return []
  }

  private async getProposals(): Promise<void> {
    this.item.loading = true
    try {
      if (this.selectedProcess && this.selectedConnectedProcess) {
        let body: LinkedProposalIndexRequest = {
          component_ids: this.getViewableComponents(this.processComponentIds, this.blacklistedProcessComponentIds),
          connected_component_ids: this.getViewableComponents(this.connectedProcessComponentIds, this.blacklistedConnectedProcessComponentIds),
        }
        if (this.process_filters?.component_value === null || this.process_filters?.component_value === '') {
          body.proposal_ids = this.componentValues.get('proposal_ids')
        } else {
          body = {...body, ...this.process_filters?.component_value}
        }

        if (this.connected_process_filters?.component_value === null || this.connected_process_filters?.component_value === '') {
          body.connected_proposal_ids = this.componentValues.get('connected_proposal_ids')
        } else {
          const connected_filters: { [key: string]: any } = {}
          for (const key in {...this.connected_process_filters?.component_value}) {
            if (this.connected_process_filters?.component_value.hasOwnProperty(key)) {
              connected_filters[`connected_${key}`] = this.connected_process_filters?.component_value[key]
            }
          }
          body = {...body, ...connected_filters}
        }

        const {data} = await this.selectedProcess.getLinkedIncludedProposals(this.selectedConnectedProcess.id, body)
        this.setProposals(data)
      } else if (this.selectedProcess) {
        let body: LinkedProposalIndexRequest = {
          component_ids: this.getViewableComponents(this.processComponentIds, this.blacklistedProcessComponentIds)
        }
        // If the process filters is empty the proposals are not autofilled based on filter criteria
        // So we fetch proposals based on the selected proposal ids
        if (this.process_filters?.component_value === null || this.process_filters?.component_value === '') {
          body.proposal_ids = this.componentValues.get('proposal_ids')
          // If the process filters is filled we use that data to filter the proposals
        } else {
          body = {...body, ...this.process_filters?.component_value}
        }

        const {data: proposals} = await this.selectedProcess.getProposals(body)
        this.setProposals(proposals)
      } else {
        this.setProposals([])
      }
    } catch (err) {
      console.error(err)
    }
    this.item.loading = false
  }

  private setProposals(data: ProposalCollectionResource[]): void {
    if (this.canOpenEdit && this.proposal_order_component) {
      this.item.proposals = orderProposals({ proposals: data, order: this.proposal_order })
    } else {
      this.item.proposals = data
    }
  }

  private async handleCancel(): Promise<void> {
    await this.cancel()
    await this.getProposals()
  }

  private sortProposals(): void {
    this.reorderModal.moved = true
  }

  private async saveReorder(): Promise<void> {
    if (this.proposal_order_component) {
      this.reorderModal.loading = true
      this.proposal_order_component.updateComponentValue(getProposalOrder({ proposals: this.reorderModal.proposals }))
      await this.proposal_order_component.patchItem()
      this.getProposals()
      this.reorderModal.loading = false
      this.reorderModal.open = false
    }
  }

  private toggleSettingsModal(): void {
    this.settingsModal.open = !this.settingsModal.open
  }

  private closeSettingsModal(): void {
    this.settingsModal.open = false
    this.proposal_show_title_component?.revertComponentValue()
    this.proposal_show_numbering_component?.revertComponentValue()
    this.proposal_include_toc_component?.revertComponentValue()
    this.proposal_orientation_component?.revertComponentValue()
  }

  private updateSettingsValue(value: boolean, element: FlexibleDocumentComponentCollectionResource): void {
    element.updateComponentValue(value)
  }

  private async saveSettingsModal(): Promise<void> {
    this.settingsModal.loading = true
    this.item.enableLoading()
    try {
      await Promise.all([
        this.proposal_show_title_component?.patchItem(),
        this.proposal_show_numbering_component?.patchItem(),
        this.proposal_include_toc_component?.patchItem(), this.proposal_orientation_component?.patchItem()
      ])
      this.settingsModal.open = false
    } catch (e) {
      console.error(e)
    } finally {
      this.item.disableLoading()
      this.settingsModal.loading = false
    }
  }

  private closeSelectProcessModal(): void {
    this.selectProcessModalOpen = false
    this.process_id?.revertComponentValue()
    this.status?.revertComponentValue()
    this.proposal_ids?.revertComponentValue()
    this.process_component_selection_component?.revertComponentValue()
  }

  private async saveSelectProcessModal(): Promise<void> {
    this.savingProcessModal = true
    try {
      await Promise.all([
        this.process_id?.patchItem(),
        this.status?.patchItem(),
        this.proposal_ids?.patchItem(),
        this.process_filters?.patchItem(),
        this.process_component_selection_component?.patchItem()
      ])
      this.selectProcessModalOpen = false
      this.getProposals()
    } catch (e) {
      console.error(e)
    } finally {
      this.savingProcessModal = false
    }
  }

  private closeSelectConnectedProcessModal(): void {
    this.selectConnectedProcessModalOpen = false
    this.connected_process_id?.revertComponentValue()
    this.connected_status?.revertComponentValue()
    this.proposal_ids?.revertComponentValue()
    this.connected_process_component_selection_component?.revertComponentValue()
  }

  private async saveSelectConnectedProcessModal(): Promise<void> {
    this.savingProcessModal = true
    try {
      await Promise.all([
        this.connected_process_id?.patchItem(),
        this.connected_status?.patchItem(),
        this.connected_proposal_ids?.patchItem(),
        this.connected_process_filters?.patchItem(),
        this.connected_process_component_selection_component?.patchItem()
      ])
      this.selectConnectedProcessModalOpen = false
      this.getProposals()
    } catch (e) {
      console.error(e)
    } finally {
      this.savingProcessModal = false
    }
  }
}
