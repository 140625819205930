import { render, staticRenderFns } from "./DropdownSort.vue?vue&type=template&id=0969e623&scoped=true&"
import script from "./DropdownSort.vue?vue&type=script&lang=ts&"
export * from "./DropdownSort.vue?vue&type=script&lang=ts&"
import style0 from "./DropdownSort.vue?vue&type=style&index=0&id=0969e623&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0969e623",
  null
  
)

export default component.exports