export type IComponentResource = {
  id: number
  base_component: string
  label?: string
  hide_label?: boolean
  information?: string
  hide_information?: boolean
  multiple?: boolean
  options: SelectItem[]
  component_predefined_value: any
  visible: number
  current_date: boolean
  mandatory?: boolean
}

export class ComponentResource {
  public id: number
  public base_component: string
  public label?: string
  public hide_label?: boolean
  public information?: string
  public hide_information?: boolean
  public multiple?: boolean
  public visible: boolean
  public options: SelectItem[]
  public component_predefined_value: any
  public current_date: boolean
  public mandatory?: boolean = false

  constructor (component: IComponentResource) {
    this.id = component.id
    this.base_component = component.base_component
    this.label = component.label ?? ''
    this.hide_label = !!component.hide_label
    this.information = component.information ?? ''
    this.hide_information = !!component.hide_information
    this.multiple = !!component.multiple
    this.visible = !!component.visible
    this.options = component.options
    this.component_predefined_value = component.component_predefined_value
    this.current_date = component.current_date
    this.mandatory = component.mandatory ?? false
  }
}
