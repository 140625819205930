


































import { Vue, Component, Prop } from 'vue-property-decorator'

import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

@Component
export default class ProposalPreview extends Vue {

  @Prop()
  private data!: ProposalCollectionResource

  @Prop()
  private title!: string | number

}
