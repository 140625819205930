export const kebabToCamel = (s: string): string =>
  s.replace(/(\-\w)/g, (m: string) => m[1].toUpperCase())
export const snakeToCamel = (s: string): string =>
  s.replace(/(\_\w)/g, (m: string) => m[1].toUpperCase())
export const kebabToSnake = (s: string): string => s.replace(/-/g, '_')
export const snakeToKebab = (s: string): string => s.replace(/_/g, '-')
export const camelToKebab = (s: string): string =>
  s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
export const nl2br = (str: string, isXhtml?: boolean): string => {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  const BREAK_TAG =
    isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>'
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + BREAK_TAG + '$2',
  )
}
