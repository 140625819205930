import { createSocket } from '@/api'
import { CommentsService }             from '@/services/comments'
import { isIFlexibleDocumentWidgetResource } from '@/helpers/FlexibleDocument'
import { canPerformAction }                  from '@/helpers/canPerformAction'
import { WidgetType }                        from './FlexibleDocumentWidgetType'
import { FlexibleDocumentElementService }    from '@/services/flexibleDocumentElement'
import { CommentsController }                from '@/controllers/comments/CommentsController'

import { AuditsResource }                                                              from '@/models/audits/AuditsResource'
import { SuggestionsResource }                                                         from '@/models/suggestions/SuggestionsResource'
import { IProjectUserResource, ProjectUserResource }                                   from '@/models/projects/ProjectUserResource'
import { ELEMENT_EVENTS, FlexibleDocumentItemResource, IFlexibleDocumentItemResource } from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import {
  FlexibleDocumentComponentCollectionResource,
  IFlexibleDocumentComponentCollectionResource
}                                                                                      from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'
import { Socket }                                                                      from 'socket.io-client'

type FlexibleDocumentWidgetPermissions = 'can_comment' | 'can_edit' | 'can_delete'

export interface IFlexibleDocumentWidgetResource extends FlexibleDocumentItemResource {
  label: string
  widget_type: WidgetType
  permissions: FlexibleDocumentWidgetPermissions[]
  items: IFlexibleDocumentComponentCollectionResource[]
  actor?: IProjectUserResource
  defined_by: ProjectUserResource
  comment_count: number
  suggestion_count: number
  channel: string
}

export class FlexibleDocumentWidgetResource extends FlexibleDocumentItemResource {
  public label: string
  public widget_type: WidgetType

  public permissions: FlexibleDocumentWidgetPermissions[]
  public actor?: ProjectUserResource
  public defined_by: ProjectUserResource
  public comment_count: number
  public suggestion_count: number
  public items: FlexibleDocumentComponentCollectionResource[]

  public elementService: FlexibleDocumentElementService
  public commentsService: CommentsService

  public comments: CommentsController
  public audits: AuditsResource
  public suggestions: SuggestionsResource

  public channel: string
  public socket?: Socket

  constructor({ label, widget_type, permissions, actor, defined_by, comment_count, suggestion_count, channel, items, ...chapter }: IFlexibleDocumentWidgetResource, project_id: number) {
    super(chapter, project_id)
    this.label = label
    this.widget_type = widget_type
    this.permissions = permissions
    this.defined_by = defined_by ? new ProjectUserResource(defined_by) : defined_by
    this.actor = actor ? new ProjectUserResource(actor) : actor
    this.comment_count = comment_count
    this.suggestion_count = suggestion_count
    this.items = items?.map((item) => new FlexibleDocumentComponentCollectionResource(item, project_id))

    this.elementService = new FlexibleDocumentElementService({ project_id, element_uuid: chapter.uuid })
    this.commentsService = new CommentsService()

    this.comments = new CommentsController({ id: chapter.uuid, service: this.elementService, commentableType: 'document_widget', project_id })
    this.audits = new AuditsResource({ service: this.elementService })
    this.suggestions = new SuggestionsResource({
      id: chapter.uuid,
      suggestableType: 'document_widget',
      service: this.elementService,
      project_id
    })
    this.suggestions.get()

    this.channel = channel

    this.openSocket()
  }

  public destroy() {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public get heading(): string {
    return this.title || this.label
  }

  public canPerformAction(key: FlexibleDocumentWidgetPermissions): boolean {
    return canPerformAction<FlexibleDocumentWidgetPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<FlexibleDocumentWidgetResource> {
    this.updateData(await this.elementService.refresh())
    return this
  }

  private updateData(data: IFlexibleDocumentItemResource): void {
    if (!isIFlexibleDocumentWidgetResource(data)) return
    this.label = data.label
    this.order = data.order
    this.title = data.title
    this.permissions = data.permissions
    this.actor = data.actor ? new ProjectUserResource(data.actor) : data.actor
    this.comment_count = data.comment_count
    this.suggestion_count = data.suggestion_count
    this.is_locked = data.is_locked ?? false
    this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
  }

  private handleEvent(res: EventResponse): void {

    switch (res.data.event) {
      case ELEMENT_EVENTS.UPDATED:
        this.refresh().then()
        // ALso fetch the suggestions again since when an element has been updated this affects the suggestions
        this.suggestions.get().then()
        break
      case ELEMENT_EVENTS.COMMENT_CREATED:
      case ELEMENT_EVENTS.COMMENT_DELETED:
        this.comments.get().then()
        break
      case ELEMENT_EVENTS.SUGGESTION_CREATED:
        this.suggestions.get().then()
        this.refresh().then()
        break
    }
    // always fetch audits
    this.audits.get().then()
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
