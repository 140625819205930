










import { Component, Vue, Watch } from 'vue-property-decorator'

import Notifications               from '@/components/messages/Notifications.vue'
import { AuthUserResource }        from '@/models/users/AuthUserResource'
import { userModule } from '@/store'

@Component({
  components: {
    Notifications
  }
})
export default class App extends Vue {

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  @Watch('user.id')
  private onUserAvailable(val: number | undefined, oldVal: number | undefined): void {
    // @ts-ignore
    const userLane = window.Userlane || null

    if (oldVal !== val && userLane && this.user) {
      userLane('identify', this.user.id, {
        can_create_project: this.user.canPerformAction('can_start_project'),
        can_edit_team: this.user.canPerformAction('can_start_project'),
        is_library_owner: this.user.canPerformAction('can_upload_document'),
        roles: this.user.organisation_roles,
        environment: this.user.environment
      })
    }
  }
}
