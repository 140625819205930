import { formatFullDate, getHumanReadableDate } from '@/helpers/formatDate'
import { TaskPatchRequest } from '@/requests/tasks/TaskPatchRequest'
import { TasksService } from '@/services/tasks'
import { IUserResource, UserResource } from '../users/UserResource'
import { canPerformAction } from '@/helpers/canPerformAction'

export type TaskPermissions = 'can_edit_task' | 'can_delete_task'

export interface ITaskCollectionResource {
  id: number
  user: IUserResource
  assigned_by: IUserResource
  message: string
  active: 1 | 0
  archived: 1 | 0
  created_at: string
  updated_at: string
  link?: string
  permissions?: TaskPermissions[]
}

export class TaskCollectionResource {

  public readonly id: number
  public user: UserResource
  public assigned_by: IUserResource
  public message: string
  public active: boolean
  public archived: boolean
  public created_at: Date
  public updated_at: Date
  public link?: string
  public permissions: TaskPermissions[]

  public updateForm: TaskPatchRequest

  private service: TasksService = new TasksService()

  constructor(task: ITaskCollectionResource) {
    this.id = task.id
    this.user = new UserResource(task.user)
    this.assigned_by = new UserResource(task.assigned_by)
    this.message = task.message
    this.active = !!task.active
    this.archived = !!task.archived
    this.created_at = new Date(task.created_at)
    this.updated_at = new Date(task.updated_at)
    this.link = task.link
    this.permissions = task.permissions ?? []

    this.updateForm = new TaskPatchRequest(this)
  }

  public get formattedCreatedAt(): string {
    return getHumanReadableDate(this.created_at)
  }

  public async patchTask(): Promise<TaskCollectionResource> {
    const { data } = await this.service.patch(this.id, this.updateForm)
    this.active = data.active
    this.message = data.message
    this.archived = data.archived
    return this
  }

  public async delete(): Promise<TaskCollectionResource> {
    await this.service.delete(this.id)
    return this
  }

  public canPerformAction(permission: TaskPermissions): boolean {
    return canPerformAction<TaskPermissions>(this.permissions, permission)
  }

}
