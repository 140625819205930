import { ProposalService }                from '@/services/proposal'
import { AuditResource }                  from '@/models/audits/AuditResource'
import { FlexibleDocumentElementService } from '@/services/flexibleDocumentElement'
import { FlexibleDocumentService } from '@/services/flexibleDocument'

type acceptedServices = ProposalService | FlexibleDocumentElementService | FlexibleDocumentService

export class AuditsResource {
  public loading: boolean = false
  public initialLoading: boolean = true
  public data: AuditResource[] = []

  private params?: Dictionary<any>
  private service: acceptedServices

  constructor({ service, params }: { service: acceptedServices, params?: Dictionary<any> }) {
    if (params) this.params = params
    this.service = service
    this.get()
  }

  public get items(): AuditResource[] {
    return this.data
  }

  public get hasAudits(): boolean {
    return this.items.length > 0
  }

  public async get(): Promise<void> {
    this.loading = true
    try {
      const { data: audits } = await this.service.getAudit()
      this.data = audits
    } finally {
      this.loading = false
      this.initialLoading = false
    }
  }

}
