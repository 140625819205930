export class DocumentCreateRequest {
  public file: Blob | string
  public subject: string[]
  public topic: string[]
  public country: string[]
  public issuer: string[]

  constructor({ file, subject, topic, country, issuer }: { file: Blob | string, subject: string[], topic: string[], country: string[], issuer: string[] }) {
    this.file = file
    this.subject = subject
    this.topic = topic
    this.country = country
    this.issuer = issuer
  }
}
