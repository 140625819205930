import { ComponentResource, IComponentResource } from '@/models/widgets/ComponentResource'

export type IWidgetResource = {
  id: number,
  name: string,
  components: IComponentResource[]
}

export class WidgetResource {
  public id: number
  public name: string
  public components: ComponentResource[]

  constructor({ id, name, components }: IWidgetResource) {
    this.id = id
    this.name = name
    this.components = components ? components.map((item) => new ComponentResource(item)) : []
  }
}
