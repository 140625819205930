export const checkSelection = (onStart: () => void, onStop: () => void, onUpdate: (payload: any) => void): void => {
    const S = window.getSelection()
    // Check if selection has valid counts
    if ((!S || !S.anchorNode || !S.anchorNode.parentElement ||
      !S.anchorNode.parentElement.hasAttribute('data-count') || !S.focusNode ||
      !S.focusNode.parentElement || !S.focusNode.parentElement.hasAttribute('data-count')
    ) || (S.anchorNode.parentElement.classList.contains('proposal') && !S.anchorNode.parentElement.classList.contains('nlp-proposal')) ||
    (S.focusNode.parentElement.classList.contains('proposal') && !S.focusNode.parentElement.classList.contains('nlp-proposal'))) {
      onStop()
      return
      // Check if selection does not already have a requirement
    } else {
      // Use the count attributes as an index for the marking
      let start_id = parseInt(S.anchorNode.parentElement.getAttribute('data-count') as string, 10)
      let end_id = parseInt(S.focusNode.parentElement.getAttribute('data-count') as string, 10)

      // Check for overlapping requirements
      for (let i = start_id; i <= end_id; i++) {
        const span = document.querySelector(`span[data-count="${i}"]`)
        if (span && span.classList.contains('proposal') && !span.classList.contains('nlp-proposal')) {
          onStop()
          return
        }
      }

      const data = getSelectionContent(S)

      // Numbers in correct order
      if (backwardsSelection(S)) {
        [start_id, end_id] = [end_id, start_id]
      }

      const payload = {
        data,
        start_id,
        end_id
      }
      onUpdate(payload)
    }
}

const backwardsSelection = (selection: Selection): boolean => {
    if (emptySelection(selection) || !selection.anchorNode || !selection.focusNode) {
      return false
    }

    const position = selection.anchorNode.compareDocumentPosition(selection.focusNode)

    if (!position && selection.anchorOffset > selection.focusOffset || position === Node.DOCUMENT_POSITION_PRECEDING) {
      return true
    }

    return false
}

const emptySelection = (selection: Selection): boolean => {
  if (!selection.anchorNode || !selection.focusNode) {
    return false
  }

  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode)

  return position === 0 && selection.focusOffset === selection.anchorOffset
}

export const getSelectionContent = (selection: Selection): string => {
  const SELECTION = selection.getRangeAt(0)
  const CLONED_SELECTION = SELECTION.cloneContents()
  // Get only the text from the selection
  const TEMP_DIV = document.createElement('div')
  TEMP_DIV.appendChild(CLONED_SELECTION)
  const MODIFIED_TEMP_DIV = TEMP_DIV.innerHTML.replace(/<\/span>/g, ' </span>')
  TEMP_DIV.innerHTML = MODIFIED_TEMP_DIV

  return TEMP_DIV.textContent || TEMP_DIV.innerText || ''
}
