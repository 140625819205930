import { ProposalService } from '@/services/proposal'

import { ProposalSuggestionResource } from '@/models/proposals/ProposalSuggestionResource'

import { SuggestionRequest }                   from '@/requests/suggestions/SuggestionRequest'
import { SuggestableType, SuggestionResource } from '@/models/suggestions/SuggestionResource'
import { SuggestionService }                   from '@/services/suggestion'
import { CommentableType }                from '@/models/comments/CommentResource'
import { FlexibleDocumentElementService } from '@/services/flexibleDocumentElement'

export class SuggestionsResource {
  public loading: boolean = false
  public initialLoading: boolean = false
  public data: IndexResponse<SuggestionResource> | null = null

  private id: number | string
  private suggestableType: CommentableType
  private project_id?: number

  private service: ProposalService | FlexibleDocumentElementService
  private suggestionService: SuggestionService

  constructor({ id, suggestableType, service, project_id }: { id: number | string, service: ProposalService | FlexibleDocumentElementService, suggestableType: SuggestableType, params?: Dictionary<any>, project_id?: number }) {
    this.id = id
    this.service = service
    this.project_id = project_id
    this.suggestableType = suggestableType
    this.suggestionService = new SuggestionService()

  }

  public get items(): SuggestionResource[] {
    return this.data ? this.data.data : []
  }

  public get hasSuggestions(): boolean {
    return this.items.length > 0
  }

  public async get(): Promise<void> {
    this.loading = true
    try {
      this.data = await this.service.getSuggestions()
    } finally {
      this.loading = false
      this.initialLoading = false
    }
  }

  public async post(body: SuggestionRequest): Promise<void> {
    const { data } = await this.suggestionService.post(body)
    if (this.data) {
      this.data.data.push(data)
    } else {
      this.data = {
        data: [data]
      }
    }
  }

  public deleteSuggestion(id: number): void {
    if (this.data && this.data.data) {
      const index = this.data.data.findIndex((suggestion) => suggestion.id === id)
      if (index > -1) {
        this.data.data.splice(index, 1)
      }
    }
  }
}
