export const SET_DOCUMENT = 'SET_DOCUMENT'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const SELECT_DOCUMENT = 'SELECT_DOCUMENT'
export const RESET_SELECTED_DOCUMENTS = 'RESET_SELECTED_DOCUMENTS'

export const SET_ORDER = 'SET_ORDER'

export const SET_PARAM = 'SET_PARAM'
export const SET_FILTER = 'SET_FILTER'
export const SET_FILTERS = 'SET_FILTERS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_BOOKMARKED = 'SET_BOOKMARKED'

export const SET_PRESET = 'SET_PRESET'
export const SET_PRESETS = 'SET_PRESETS'
export const ADD_PRESET = 'ADD_PRESET'
export const DELETE_PRESET = 'DELETE_PRESET'

export const SET_INIT_PARAMS = 'SET_INIT_PARAMS'

export const SET_MARKING_ID = 'SET_MARKING_ID'
export const RESET_MARKING_ID = 'RESET_MARKING_ID'

