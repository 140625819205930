
















import { Component, Vue, Prop }     from 'vue-property-decorator'
import { StatusResourceCollection } from '@/models/status/StatusResourceCollection'

const SECONDS_OPEN = 3

@Component
export default class ResponseModal extends Vue {

  @Prop()
  private readonly title!: string

  @Prop()
  private readonly status!: StatusResourceCollection

  private seconds: number = SECONDS_OPEN
  private timer: number = 0

  private created(): void {
    this.timer = setInterval(() => {

      // Close window and clear interval on 0 seconds
      if (this.seconds === 0) {
        clearInterval(this.timer)
        this.close()
      }
      // Substract 1 every 1 seconds but stop after 0 to prevent -1, -2 etc
      if (this.seconds > 0) {
        this.seconds--
      }

    }, 1000)
  }

  private beforeDestroy(): void {
    clearInterval(this.timer)
  }

  private close(): void {
    this.$emit('close')
  }

}
