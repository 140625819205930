export interface IProjectTeamResource {
  user_id: number
  first_name: string
  last_name: string
  role: string
}

export class ProjectTeamResource {
  public user_id: number
  public first_name: string
  public last_name: string
  public role: string

  constructor ({ user_id, first_name, last_name, role }: IProjectTeamResource) {
    this.user_id = user_id
    this.first_name = first_name
    this.last_name = last_name
    this.role = role
  }

  public get id(): number {
    return this.user_id
  }

  public get name(): string {
    return `${this.first_name} ${this.last_name}`
  }

  public get initials(): string {
    return this.name.split(' ').map((name: string) => name.charAt(0)).join('')
  }
}
