export interface IFlexibleDocumentMetaResource {
  [key: string]: string | number | Array<number | string>
}

export class FlexibleDocumentMetaResource {
  [key: string]: string | number | Array<number | string>
  constructor(meta: IFlexibleDocumentMetaResource) {
    Object.assign(this, meta)
  }
}
