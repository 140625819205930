



























import * as Helpers                           from '@/helpers'
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'

@Component
export default class DefaultModal extends Vue {

  @Model('toggle', { type: Boolean })
  private open!: boolean

  @Prop()
  private title!: string

  @Prop({ required: false })
  private subtitle!: string

  @Prop({ default: 30 })
  private padding!: number

  @Prop({ default: true })
  private canClose!: boolean

  @Prop({ default: true })
  private canScrollContent!: boolean

  @Prop({
    type: String,
    default: 'large',
    validator: (val) => {
      return ['small', 'medium', 'large'].indexOf(val) !== -1
    }
  })
  private size!: string

  @Watch('open')
  private onOpenChange(val: boolean): void {
    Helpers.toggleModal(val)
    if (val) {
      window.addEventListener('keyup', this.checkKey)
    } else {
      window.removeEventListener('keyup', this.checkKey)
    }
  }

  private checkKey(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      this.close()
    }
  }

  private close(): void {
    this.$emit('toggle', false)
  }
}
