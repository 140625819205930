export interface IUserResource {
  id: number
  organisation_id: number
  organisation_role: string
  first_name: string
  last_name: string
  image: string
  created_at: string
  updated_at: string
  available_roles?: string[]
}

export class UserResource implements IUserResource {
  public readonly id: number
  public readonly organisation_id: number
  public organisation_role: string
  public first_name: string
  public last_name: string
  public image: string
  public available_roles: string[]

  public created_at: string
  public updated_at: string

  constructor(user: IUserResource) {
    this.id = user.id
    this.organisation_id = user.organisation_id
    this.organisation_role = user.organisation_role
    this.first_name = user.first_name
    this.last_name = user.last_name
    this.image = user.image

    this.available_roles = user.available_roles || []

    this.created_at = user.created_at
    this.updated_at = user.updated_at
  }

  public get name(): string {
    return `${this.first_name} ${this.last_name}`
  }

  public get rolesString(): string {
    return this.available_roles?.join(', ') ?? ''
  }

  // First letter and last letter of last word
  public get initials(): string {
    const NAME = this.name.split(' ')
    const LETTERS = NAME.map((name: string) => name.charAt(0))

    if (LETTERS.length > 1) {
      return LETTERS[0] + LETTERS[LETTERS.length - 1]
    }

    return LETTERS[0]
  }
}
