import { render, staticRenderFns } from "./noComments.vue?vue&type=template&id=762621de&scoped=true&"
var script = {}
import style0 from "./noComments.vue?vue&type=style&index=0&id=762621de&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "762621de",
  null
  
)

export default component.exports