



























import { Component, Vue, Prop, Model } from 'vue-property-decorator'

@Component
export default class TextInput extends Vue {

  @Model('input', { type: [String, Number] })
  private readonly value!: string | number

  // Props
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop()
  private readonly name!: string

  @Prop()
  private readonly label!: string

  @Prop(String)
  private readonly description!: string

  @Prop()
  private readonly errors!: ErrorResponse[]

  @Prop()
  private readonly placeholder!: string

  @Prop()
  private readonly pattern!: string

  @Prop()
  private readonly autocomplete!: string

  @Prop({ default: '' })
  private readonly inputType!: string

  @Prop({ default: 'text' })
  private readonly type!: string

  @Prop({ default: false })
  private readonly focus!: boolean

  @Prop({default: 150})
  private readonly maxCharacters!: number

  @Prop({default: false})
  private readonly limitCharacters!: boolean

  // Computed properties
  private get hasErrors(): boolean {
    return typeof this.errors === 'object' ? this.errors.length > 0 : false
  }

  private get errorString(): string | undefined {
    return this.hasErrors ? this.errors.join(', ') : undefined
  }

  private get labelString(): string {
    return this.label
  }

  private mounted(): void {
    if (this.focus) {
      (this.$refs.input as HTMLInputElement).focus()
    }
  }
}
