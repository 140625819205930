







































import {projectModule} from '@/store'
import {canPerformAction} from '@/helpers/canPerformAction'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import Modal from '@/components/widgets/Modal.vue'
import NoItems from '@/components/partials/NoItems.vue'
import ProcessConnection from './ProcessConnection.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ConnectionsModal from '@/components/proposal/create/Connections.vue'

import {ProcessResource} from '@/models/process/ProcessResource'
import {ProjectResource} from '@/models/projects/ProjectResource'
import {ProposalResource} from '@/models/proposals/ProposalResource'
import {ProposalConnectionsResource} from '@/models/proposals/ProposalConnectionsResource'
import {ProposalPatchConnectionsRequest} from '@/requests/proposals/ProposalPatchConnectionsRequest'
import {ProposalSummaryCollectionResource} from '@/models/proposals/ProposalSummaryCollectionResource'

// component
@Component({
  components: {
    Modal,
    NoItems,
    SmallLoader,
    ConnectionsModal,
    ProcessConnection
  }
})
export default class ProposalDirectConnections extends Vue {
  @Prop()
  private readonly commentFilter!: string | null

  @Prop()
  private readonly proposal!: ProposalResource

  @Prop()
  private readonly process!: ProcessResource

  @Prop({default: true})
  private readonly readonly!: boolean

  private linkedProposals: ProposalConnectionsResource | null = null

  private loading: boolean = false
  private openConnectionsModal: boolean = false
  private connectionsModalProcess: ProcessResource | null = null
  private connectionProposals: ProposalSummaryCollectionResource[] = []

  private canEditConnections(process: ProcessResource): boolean {
    return (
        canPerformAction(this.proposal.permissions, 'can_change_connections') &&
        !this.readonly && (process.process_type !== 'document' || this.process.process_type !== 'marking')
    )
  }

  private get previousProcess(): ProcessResource | null {
    return this.proposal
        ? projectModule.getProcessById(this.proposal.previous_process_id)
        : null
  }

  private get nextProcess(): ProcessResource | null {
    return this.proposal
        ? projectModule.getProcessById(this.proposal.next_process_id)
        : null
  }

  private get project(): ProjectResource | null {
    return projectModule.detail
  }

  private get previousProposals(): ProposalSummaryCollectionResource[] {
    return this.linkedProposals
        ? this.linkedProposals.previous_linked_proposals
        : []
  }

  private get nextProposals(): ProposalSummaryCollectionResource[] {
    return this.linkedProposals
        ? this.linkedProposals.next_linked_proposals
        : []
  }

  @Watch('$route.query')
  private onQueryChange(val: Dictionary<string | Array<string | null> | null | undefined>): void {
    this.checkRoute(val)
  }

  private created(): void {
    this.checkRoute(this.$route.query)
  }

  private checkRoute(query: Dictionary<string | Array<string | null> | null | undefined>): void {
    if (query.connections) {
      if (query.connections === 'parent') {
        this.toggleParentConnectionsModal(true)
      } else if (query.connections === 'child') {
        this.toggleChildConnectionsModal(true)
      }
      this.$nextTick(() => {
        this.$router.replace({path: this.$route.path, query: undefined})
      })
    }
  }

  private refreshProcess(): void {
    this.$emit('refresh')
  }

  private async addConnections(ids: number[]): Promise<void> {

    this.openConnectionsModal = false
    this.loading = true

    const body: ProposalPatchConnectionsRequest = {}
    if (this.connectionsModalProcess?.id === this.previousProcess?.id) {
      body.parent_ids = ids
    } else {
      body.child_ids = ids
    }

    try {
      await this.proposal.patchConnections(body)
      this.getData()
      this.refreshProcess()
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  @Watch('proposal.id')
  private onProposalUpdate(): void {
    this.getData()
  }

  private mounted(): void {
    this.getData()
  }

  private async getData(): Promise<void> {
    this.loading = true
    try {
      this.linkedProposals = await this.proposal.getLinkedProposals()
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  private async toggleParentConnectionsModal(open: boolean): Promise<void> {
    if (open && this.previousProcess) {
      await this.getData()

      this.connectionsModalProcess = this.previousProcess
      this.connectionProposals = this.previousProposals
    }
    this.openConnectionsModal = open
  }

  private async toggleChildConnectionsModal(open: boolean): Promise<void> {
    if (open && this.nextProcess) {
      await this.getData()

      this.connectionsModalProcess = this.nextProcess
      this.connectionProposals = this.nextProposals
      this.loading = true
      try {
        const data = await this.nextProcess.getAvailableProposals({ pagination: 'false' })
        this.$set(this, 'proposalsToLink', data.data)
      } finally {
        this.loading = false
      }
    }
    this.openConnectionsModal = open
  }
}
