import { render, staticRenderFns } from "./StatusButton.vue?vue&type=template&id=1a52b386&scoped=true&functional=true&"
import script from "./StatusButton.vue?vue&type=script&lang=ts&"
export * from "./StatusButton.vue?vue&type=script&lang=ts&"
import style0 from "./StatusButton.vue?vue&type=style&index=0&id=1a52b386&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1a52b386",
  null
  
)

export default component.exports