import { createSocket } from '@/api'
import orderBy                         from 'lodash/orderBy'
import { ProposalService }   from '@/services/proposal'
import { canPerformAction }  from '@/helpers/canPerformAction'
import { SuggestionService } from '@/services/suggestion'

import { IStatusResource, StatusResource }                         from '@/models/status/StatusResource'
import { IProposalResource, PROPOSAL_EVENTS, ProposalPermissions } from '@/models/proposals/ProposalResource'
import { IProjectUserResource, ProjectUserResource }               from '@/models/projects/ProjectUserResource'
import { ComponentValueResource, IComponentValueResource }         from '@/models/widgets/ComponentValueResource'
import { SuggestionsResource }                                     from '@/models/suggestions/SuggestionsResource'
import { IStatusResourceCollection, StatusResourceCollection }     from '@/models/status/StatusResourceCollection'

import { ProposalUpdateRequest }        from '@/requests/proposals/ProposalUpdateRequest'
import { ProposalStatusPatchRequest }   from '@/requests/proposals/ProposalStatusPatchRequest'
import { ProposalAllocateUsersRequest } from '@/requests/proposals/ProposalAllocateUsersRequest'
import { Socket }                       from 'socket.io-client'

export type linkedProposal = {
  proposal_name: string
  status: StatusResource
  process_id: number
  proposal_id: number
}

export interface IProposalCollectionResource {
  readonly id: number
  document_id: number
  document_title?: string | null
  document_link?: string | null
  proposal_name: string
  description: string
  process_name: string
  process_id: number
  project_id: number
  status: IStatusResource
  statuses?: IStatusResourceCollection[]
  permissions: ProposalPermissions[]
  previous_linked_proposals: linkedProposal[]
  next_linked_proposals: linkedProposal[]
  linked_proposals?: IProposalCollectionResource[]
  components: IComponentValueResource[]
  comment_count: number
  suggestion_count: number
  channel: string
  proposal_value_overview?: string
  inherited?: boolean
  created_at: string
  is_locked?: boolean
  allocated_users?: IProjectUserResource[]

  similarity_score?: number
}

export class ProposalCollectionResource {
  public readonly id: number
  public document_id: number
  public document_title?: string | null
  public document_link?: string | null
  public proposal_name: string
  public description: string
  public process_name: string
  public process_id: number
  public project_id: number
  public status: StatusResource
  public statuses?: StatusResourceCollection[]
  public permissions: ProposalPermissions[]
  public previous_linked_proposals: linkedProposal[]
  public next_linked_proposals: linkedProposal[]
  public linked_proposals?: ProposalCollectionResource[]
  public comment_count: number
  public suggestion_count: number
  public components: ComponentValueResource[]
  public channel: string
  public proposal_value_overview?: string
  public inherited?: boolean
  public created_at: string
  public is_locked: boolean

  public allocated_users: ProjectUserResource[]

  public proposalService: ProposalService
  public suggestionService: SuggestionService

  public similarity_id?: number
  public similarity_score?: number

  // public comments: CommentsController
  // public audits: AuditsResource
  public suggestions: SuggestionsResource

  public socket?: Socket

  private _deleted: boolean = false

  public get deleted(): boolean {
    return this._deleted
  }

  constructor(proposal: IProposalCollectionResource, similarity_id?: number) {
    this.id = proposal.id
    this.document_id = proposal.document_id
    if (proposal.document_title) this.document_title = proposal.document_title
    if (proposal.document_link) this.document_link = proposal.document_link
    this.proposal_name = proposal.proposal_name
    this.description = proposal.description
    this.process_id = proposal.process_id
    this.project_id = proposal.project_id
    this.process_name = proposal.process_name
    this.status = new StatusResource(proposal.status)
    if (proposal.statuses) this.statuses = proposal.statuses.map((s) => new StatusResourceCollection(s))
    this.previous_linked_proposals = proposal.previous_linked_proposals
    this.next_linked_proposals = proposal.next_linked_proposals
    if (proposal.linked_proposals) this.linked_proposals = proposal.linked_proposals.map((p) => new ProposalCollectionResource(p))
    this.permissions = proposal.permissions
    this.comment_count = proposal.comment_count
    this.suggestion_count = proposal.suggestion_count
    this.channel = proposal.channel
    if (proposal.proposal_value_overview) this.proposal_value_overview = proposal.proposal_value_overview
    if (proposal.inherited) this.inherited = proposal.inherited
    this.created_at = proposal.created_at

    this.is_locked = proposal.is_locked ?? false
    this.allocated_users = proposal.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []

    this.components = proposal.components ? proposal.components.map((item) => new ComponentValueResource(item)).sort((a, b) => a.order - b.order) : []

    this.proposalService = new ProposalService({ project_id: proposal.project_id, process_id: proposal.process_id, proposal_id: proposal.id })

    this.suggestionService = new SuggestionService()

    if (similarity_id) this.similarity_id = similarity_id
    if (proposal.similarity_score) this.similarity_score = proposal.similarity_score

    this.suggestions = new SuggestionsResource({
      id: proposal.id,
      suggestableType: 'proposal',
      service: this.proposalService,
      project_id: proposal.project_id
    })

    this.openSocket()
  }

  public destroy(): void {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public async patch(body: ProposalUpdateRequest): Promise<ProposalCollectionResource> {
    const { data } = await this.proposalService.patchCollection(body)
    this.updateCollectionData(data)
    return this
  }

  public async refresh(): Promise<ProposalCollectionResource> {
    const { data } = await this.proposalService.refresh()
    this.updateData(data)
    return this
  }

  public canPerformAction(key: ProposalPermissions): boolean {
    return canPerformAction<ProposalPermissions>(this.permissions, key)
  }

  public async delete(): Promise<void> {
    await this.proposalService.delete()
  }

  public async patchStatus(body: ProposalStatusPatchRequest): Promise<ProposalCollectionResource> {
    const { data } = await this.proposalService.patchStatus(body)
    this.updateData(data)
    return this
  }

  public async allocateUsers(body: ProposalAllocateUsersRequest) {
    const { data } = await this.proposalService.allocateUsers(body)
    this.allocated_users = data.allocated_users.map((user) => new ProjectUserResource(user))
    return this
  }

  public async lock() {
    const data = await this.proposalService.lock()
    this.is_locked = data.data.is_locked
    return this
  }

  private updateCollectionData(proposal: IProposalCollectionResource): void {
    this.document_id = proposal.document_id
    if (proposal.document_title) this.document_title = proposal.document_title
    this.proposal_name = proposal.proposal_name
    this.description = proposal.description

    this.status = new StatusResource(proposal.status)
    if (proposal.statuses) this.statuses = proposal.statuses.map((s) => new StatusResourceCollection(s))
    this.previous_linked_proposals = proposal.previous_linked_proposals
    this.next_linked_proposals = proposal.next_linked_proposals
    if (proposal.linked_proposals) this.linked_proposals = proposal.linked_proposals.map((p) => new ProposalCollectionResource(p))
    this.permissions = proposal.permissions
    this.comment_count = proposal.comment_count
    if (proposal.similarity_score) this.similarity_score = proposal.similarity_score
    this.allocated_users = proposal.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []

    this.components = proposal.components ? orderBy(proposal.components.map((item) => new ComponentValueResource(item)), 'order') : []
  }

  private updateData(proposal: IProposalResource): void {
    this.proposal_name = proposal.proposal_name
    this.status = new StatusResource(proposal.status)
    if (proposal.statuses) this.statuses = proposal.statuses.map((s) => new StatusResourceCollection(s))
    this.permissions = proposal.permissions
    this.comment_count = proposal.comment_count
    this.components = proposal.components ? proposal.components.map((item) => new ComponentValueResource(item)).sort((a, b) => a.order - b.order) : []
    this.allocated_users = proposal.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
    this.is_locked = proposal.is_locked
    this.previous_linked_proposals = proposal.previous_linked_proposals.map((linked_proposal) => linked_proposal as linkedProposal)
    this.next_linked_proposals = proposal.next_linked_proposals.map((linked_proposal) => linked_proposal as linkedProposal)
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case PROPOSAL_EVENTS.UPDATED:
      case PROPOSAL_EVENTS.COMMENT_CREATED:
      case PROPOSAL_EVENTS.COMMENT_DELETED:
        this.refresh()
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
