import store from '@/store/index'
import * as Mutations from './mutation-types'
import { setColors } from '@/helpers/setTheme'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { AuthService } from '@/services/auth'
import { UserService } from '@/services/user'
import { OrganisationService } from '@/services/organisation'

import { AuthUserResource, AuthUserPermissions } from '@/models/users/AuthUserResource'

import { LoginRequest } from '@/requests/auth/LoginRequest'
import { ChangeExpiredPasswordRequest } from '@/requests/auth/ChangeExpiredPasswordRequest'
import { OrganisationThemeResource } from '@/models/organisation/OrganisationThemeResource'
import { SET_THEME } from './mutation-types'

const authService: AuthService = new AuthService()
const userService: UserService = new UserService()
const organisationService: OrganisationService = new OrganisationService()

@Module({
  // dynamic: true,
  namespaced: true,
  name: 'user',
  store,
})
class UserModule extends VuexModule {
  // State
  private _user: AuthUserResource | null = null

  private _theme: OrganisationThemeResource | null = null

  // Getters
  public get user(): AuthUserResource | undefined {
    return this._user || undefined
  }

  public get theme(): OrganisationThemeResource | null {
    return this._theme
  }

  public get isLoggedIn(): boolean {
    return !!(this._user && this._user.id)
  }

  public get permissions(): AuthUserPermissions[] {
    return this.user ? this.user.permissions : []
  }

  public get canPerformAction(): (key: AuthUserPermissions) => boolean {
    return (key: AuthUserPermissions): boolean => (this.user ? this.user.canPerformAction(key) : false)
  }

  @Action({ rawError: true, commit: Mutations.LOAD_PROFILE })
  public setUser(user: AuthUserResource): AuthUserResource {
    return user
  }

  @Action({ rawError: true, commit: Mutations.LOAD_PROFILE })
  public async login({ user:  body }: { user: LoginRequest }): Promise<{ user: AuthUserResource, theme: OrganisationThemeResource }> {
    try {
      const user = await authService.login(body)
      const theme = await organisationService.getTheme()
      return { user, theme }
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async renew({ form }: { form: ChangeExpiredPasswordRequest }): Promise<void> {
    try {
      await authService.renew(form)
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.LOAD_PROFILE })
  public async getMe(): Promise<{ user: AuthUserResource, theme: OrganisationThemeResource }> {
    try {
      const { data: user } = await userService.getMe()
      const theme = await organisationService.getTheme()
      return { user, theme }
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async logout(): Promise<void> {
    try {
      await userService.logout()
      return
    } catch (e) {
      throw e
    }
  }

  @Mutation
  private [Mutations.LOAD_PROFILE]({ user, theme }: { user: AuthUserResource | null, theme: OrganisationThemeResource }): void {
    // Remove channel event listeners
    this._user?.destroy()
    this._user = user
    this._theme = theme
    setColors(theme)
  }
}

export default UserModule
