import { WidgetResource } from '@/models/widgets/WidgetResource'
import { ComponentValue }                   from '@/models/widgets/ComponentValue'
import { formatDateForBackend } from '@/helpers/formatDate'


export class ProposalCreateRequest {
  public values: ComponentValue[] = []
  public proposal_name: string = ''
  public document_ids?: number[]
  public parent_ids?: number[] = []
  public document_id?: number
  public similarity_id?: number
  public notify_user: number[] = []

  constructor({ widgets, document_id, parent_ids, similarity_id, document_ids }: { widgets: WidgetResource[], document_id?: number, parent_ids?: number[], similarity_id?: number, document_ids?: number[] }) {
    this.document_id = document_id
    this.parent_ids = parent_ids || []
    this.similarity_id = similarity_id
    this.document_ids = document_ids
    for (const widget of widgets) {
      this.values = [...this.values, ...widget.components.map((c) => new ComponentValue({ component: c, data: c.base_component === 'DatePicker' && c.current_date ? formatDateForBackend(new Date()) : c.component_predefined_value }))]
    }
  }
}
