





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ActionBar extends Vue {

  @Prop()
  private title!: string

  @Prop({ default: true })
  private canCancel!: boolean

  @Prop({ default: '' })
  private selectedItemNames!: string

  private cancel(): void {
    this.$emit('cancel')
  }
}
