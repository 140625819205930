import { IFlexibleDocumentMetaResource }       from '@/models/flexibleDocument/flexibleDocumentMetaResource'
import { FlexibleDocumentMetaSectionResource } from '@/models/flexibleDocument/FlexibleDocumentMetaSectionResource'

export interface IFlexibleDocumentMetaPatchRequest {
  [key: string]: string | number | Date | Array<string | number | Date>
}

export class FlexibleDocumentMetaPatchRequest {
  [key: string]: string | number | Date | Array<string | number | Date>

  constructor({ sections, values }: { sections: FlexibleDocumentMetaSectionResource[], values?: IFlexibleDocumentMetaResource | null }) {

    for (const section of sections) {
      for (const { multiple, key, type } of section.fields) {
        // If type is date we need to transform it to a date object
        const value = values ? values[key] : undefined

        if (type === 'date') {
          this[key] = typeof value === 'string' ? value : new Date()
        } else if (type === 'dropdown') {
          // If set to multiple the value is always an array
          // otherwise set it as empty string
          if (multiple) {
            this[key] = Array.isArray(value) ? value : []
          } else {
            this[key] = typeof value === 'string' ? value : ''
          }
        } else {
          this[key] = typeof value === 'string' ? value : ''
        }
      }
    }
  }
}
