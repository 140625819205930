import { WidgetResource }        from '@/models/widgets/WidgetResource'
import { ComponentValue }        from '@/models/widgets/ComponentValue'
import { ProposalCreateRequest } from '@/requests/proposals/ProposalCreateRequest'

export class ProposalSimilarityRequest {
  public values: ComponentValue[] = []
  public proposal_name: string = ''
  public parent_ids?: number[] = []
  public document_id?: number

  constructor({ proposal }: { proposal: ProposalCreateRequest }) {
    this.proposal_name = proposal.proposal_name
    this.document_id = proposal.document_id
    this.parent_ids = proposal.parent_ids
    this.values = proposal.values

  }
}
