









import {Component, Vue, Prop } from 'vue-property-decorator'

import { StatusResource } from '@/models/status/StatusResource'
import { StatusResourceCollection } from '@/models/status/StatusResourceCollection'

@Component
export default class StatusIcon extends Vue {

  @Prop()
  private status!: StatusResourceCollection | StatusResource | undefined

  @Prop()
  private inherited!: boolean

  private get style(): Dictionary<string> {
    return {
      backgroundColor: this.status?.color ?? ''
    }
  }

  private get title(): string {
    return this.status?.label  ?? ''
  }

}

