












import { userModule }           from '@/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

// Vue components
import CommentBox from '@/components/inputs/CommentBox.vue'
import UserImage  from '@/components/user/UserImage.vue'

import { AuthUserResource }    from '@/models/users/AuthUserResource'
import { CommentResource} from '@/models/comments/CommentResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'


@Component({
  components: {
    CommentBox,
    UserImage
  }
})
export default class AddComment extends Vue {

  @Prop()
  private type!: string

  @Prop()
  private comment!: CommentResource | null

  @Prop()
  private users!: ProjectUserResource[]

  @Prop({ default: false })
  private showUserImage!: boolean

  @Prop({ default: false })
  private submitOnEnter!: boolean

  @Prop()
  private resetMessage!: boolean

  private message: string = ''

  @Watch('resetMessage')
  private onResetChange(val: boolean) {
    this.message = ''
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get messageIsEmpty(): boolean {
    return this.message === '' || this.message === '<p><br></p>' || this.message === '<p><br /></p>' || this.message === '<div><br></div>' || this.message === '<div><br /></div>'
  }

  private get isReply(): boolean {
    return this.type === 'reply'
  }

  private submitHandler(): void {
    this.$emit('submit', this.message)
    this.message = ''
  }

  private created(): void {
    if (this.comment && this.comment.message) {
      this.message = this.comment.message
    }
  }

  private get postNewText(): string {
    return this.isReply ? 'Write a new reply' : 'Write a new comment'
  }
}

