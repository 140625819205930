






import { Vue, Component, Prop } from 'vue-property-decorator'
import { StatusResource }       from '@/models/status/StatusResource'

@Component
export default class StatusButton extends Vue {

  @Prop()
  private readonly status!: StatusResource

  @Prop({ default: true })
  private readonly small!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: 0 })
  private readonly counter!: number

}
