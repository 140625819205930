var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{attrs:{"id":"notificationWrapper","name":"notifications","tag":"div"}},_vm._l((_vm.notifications),function(ref){
var message = ref.message;
var type = ref.type;
var id = ref.id;
var show = ref.show;
var action = ref.action;
var errors = ref.errors;
return (show)?_c('div',{key:id,staticClass:"notification-wrapper"},[_c('div',{staticClass:"notification",class:type},[_c('p',{staticClass:"message",domProps:{"textContent":_vm._s(message)}}),_vm._l((errors),function(error){return _vm._l((error),function(err){return (typeof error === 'object')?_c('p',{staticClass:"error",domProps:{"textContent":_vm._s(err)}}):_c('p',{staticClass:"error",domProps:{"textContent":_vm._s(error)}})})}),(action && action.action)?_c('button',{staticClass:"action",domProps:{"textContent":_vm._s(action.name)},on:{"click":function () { action.action() }}}):_vm._e()],2)]):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }