









import UserImage from '@/components/user/UserImage.vue'
import { UserResource } from '@/models/users/UserResource'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthUserResource } from '@/models/users/AuthUserResource'
import { userModule } from '@/store'

@Component({
  components: {UserImage},
})
export default class UsersList extends Vue {

  @Prop()
  private readonly users!: UserResource[]

  @Prop({ default: 'ltr' })
  private readonly direction!: 'rtl' | 'lrt'

  @Prop({ default: 3 })
  private readonly amount!: number

  private get authUser(): AuthUserResource | undefined {
    return userModule.user
  }

  private get sortedUsers() {
    let users = [...this.users]
    users = users.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

    // Sort own user to top
    return users.sort((a, b) => this.authUser?.id === b.id ? 1 : 0)
  }

  private get maxUsers() {
    const users = [...this.sortedUsers]
    return users.slice(0, this.amount)
  }

  private get usersLeft() {
    return this.sortedUsers.length - this.amount
  }

  private get showEllipsis() {
    return this.sortedUsers.length > this.amount
  }

  private get usersLeftNames() {
    const users = [...this.sortedUsers]
    return users.slice(this.amount).map((user) => user.name).join(' <br />')
  }

}
