

export interface IOrganisationThemeResource {
    primary: string
    secondary: string
    tertiary: string
    quaternary: string
    [key: string]: string
}

export class OrganisationThemeResource {
    public primary: string
    public secondary: string
    public tertiary: string
    public quaternary: string

    [key: string]: string

    constructor({ primary, secondary, tertiary, quaternary }: IOrganisationThemeResource) {
        this.primary = primary
        this.secondary = secondary
        this.tertiary = tertiary
        this.quaternary = quaternary
    }
}
