






















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { WidgetMixin }                  from '@/plugins/widget-mixin'

import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import WysiwygEditor from '@/components/editors/Wysiwyg.vue'
import SmallLoader   from '@/components/loaders/SmallLoader.vue'

@Component({
  name: 'ParagraphWidget',
  components: {
    SmallLoader,
    WysiwygEditor
  }
})
export default class ParagraphWidget extends Mixins(WidgetMixin) {
  private get paragraphItem(): FlexibleDocumentComponentCollectionResource | null {
    return this.item.items.find((item) => item.component_key === 'content') || null
  }
}
