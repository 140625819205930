export const proposalSortOptions: SelectItem[] = [
  { value: 'created_at-asc', label: 'Creation date (old - new)' },
  { value: 'created_at-desc', label: 'Creation date (new - old)' },
  { value: 'name-asc', label: 'Name (a - z)' },
  { value: 'name-desc', label: 'Name (z - a)' }
]

export const proposalCoverageOptions: SelectItem[] = [
  { value: undefined, label: 'All' },
  { value: 'Covered', label: 'Covered' },
  { value: 'Uncovered', label: 'Uncovered' }
]
