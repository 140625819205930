import { AxiosPromise }                            from 'axios'
import { BaseService }                             from '@/services/base'
import { convertToFlexibleDocumentCollectionItem } from '@/helpers/FlexibleDocument'

import { AuditResource, IAuditResource }           from '@/models/audits/AuditResource'
import { CommentResource, ICommentResource }       from '@/models/comments/CommentResource'
import { ISuggestionResource, SuggestionResource } from '@/models/suggestions/SuggestionResource'
import { IFlexibleDocumentItemResource }           from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollectionResource }  from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'

import { FlexibleDocumentCreateItemRequest }              from '@/requests/flexibleDocument/FlexibleDocumentCreateItemRequest'
import { FlexibleDocumentUpdateChapterRequest }           from '@/requests/flexibleDocument/FlexibleDocumentUpdateChapterRequest'
import { FlexibleDocumentUpdateItemOrderRequest }         from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { FlexibleDocumentUpdateItemAllocateUsersRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemAllocateUsersRequest'

export class FlexibleDocumentElementService extends BaseService {

  private readonly project_id: number
  private readonly element_uuid: string

  constructor({ project_id, element_uuid }: { project_id: number, element_uuid: string }) {
    super(`projects/${project_id}/flexible_document/${element_uuid}`)
    this.project_id = project_id
    this.element_uuid = element_uuid
  }

  public async refresh(): Promise<IFlexibleDocumentItemResource> {
    const { data } = await this.client.get<DetailResponse<IFlexibleDocumentItemResource>>(`${this.namespace}`)
    return data.data
  }

  public async patch({ body }: { body: FlexibleDocumentUpdateChapterRequest }): Promise<DetailResponse<IFlexibleDocumentItemResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentItemResource>>(`${this.namespace}`, body)
    return data
  }

  public delete(): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}`)
  }

  public async addItem({ body }: { body: FlexibleDocumentCreateItemRequest }): Promise<DetailResponse<FlexibleDocumentItemCollectionResource>> {
    const { data } = await this.client.post<DetailResponse<FlexibleDocumentItemCollectionResource>>(`projects/${this.project_id}/flexible_document`, body)
    return {
      ...data,
      data: convertToFlexibleDocumentCollectionItem(data.data, this.project_id)
    }
  }

  public async patchOrder({ body }: { body: FlexibleDocumentUpdateItemOrderRequest }): Promise<FlexibleDocumentItemCollectionResource[]> {
    const { data } = await this.client.patch<FlexibleDocumentItemCollectionResource[]>(`projects/${this.project_id}/flexible_document/chapter`, body)
    return data.map((item) => convertToFlexibleDocumentCollectionItem(item, this.project_id))
  }

  public async getComments(): Promise<IndexResponse<CommentResource>> {
    const { data } = await this.client.get<IndexResponse<ICommentResource>>(`${this.namespace}/comments`)
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async getSuggestions(params?: Dictionary<any>): Promise<IndexResponse<SuggestionResource>> {
    const { data } = await this.client.get<IndexResponse<ISuggestionResource>>(`${this.namespace}/suggestions`, { params })
    return {
      ...data,
      data: data.data.map((suggestion: ISuggestionResource) => new SuggestionResource(suggestion))
    }
  }

  public async getAudit(): Promise<IndexResponse<AuditResource>> {
    const { data } = await this.client.get<IndexResponse<IAuditResource>>(`${this.namespace}/audit`)
    return {
      ...data,
      data: data.data.map((audit: IAuditResource) => new AuditResource(audit))
    }
  }

  public async uploadFile(form: FormData): Promise<DetailResponse<{ image_url: string }>> {
    const { data } = await this.client.post(`projects/${this.project_id}/flexible_document`, form)
    return data
  }

  public async allocateUsers({ body }: { body: FlexibleDocumentUpdateItemAllocateUsersRequest }): Promise<IFlexibleDocumentItemResource> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentItemResource>>(`${this.namespace}/users`, body)
    return data.data
  }

  public async lock(): Promise<IFlexibleDocumentItemResource> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentItemResource>>(`${this.namespace}/locked`)
    return data.data
  }

}
