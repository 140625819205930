import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { getModule } from 'vuex-module-decorators'

Vue.use(Vuex)

import user from './user'
import state from './state'
import project from './project'
import projects from './projects'
import documents from './documents'
import flexibleDocument from './flexibleDocument'
import organisationDocuments from './organisationDocuments'

const STORE_STATE: StoreOptions<any> = {
  modules: {
    user,
    state,
    project,
    projects,
    documents,
    flexibleDocument,
    organisationDocuments
  }
}

const STORE = new Vuex.Store<{}>(STORE_STATE)

export default STORE

export const userModule = getModule(user, STORE)
export const stateModule = getModule(state, STORE)
export const projectModule = getModule(project, STORE)
export const projectsModule = getModule(projects, STORE)
export const documentsModule = getModule(documents, STORE)
export const flexibleDocumentModule = getModule(flexibleDocument, STORE)
export const organisationDocumentsModule = getModule(organisationDocuments, STORE)


