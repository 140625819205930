







import { hexToRgb } from '@/helpers/colors'
import {Vue, Component, Prop} from 'vue-property-decorator'
import {StatusResource} from '@/models/status/StatusResource'
import {FlexibleDocumentStatusResource} from '@/models/status/FlexibleDocumentStatusResource'

@Component
export default class StatusBadge extends Vue {

  @Prop()
  private status!: StatusResource | FlexibleDocumentStatusResource | undefined

  @Prop()
  private deadline!: string

  @Prop({ default: false })
  private showIndicator!: boolean

  @Prop({ default: false })
  private isOpen!: boolean

  @Prop({ default: 'label' })
  private label!: string

  private get statusLabel(): string {
    const label = this.status?.[this.label]
    return typeof label === 'string' ? this.deadline ? `${label}: ${this.deadline}` : label : ''
  }

  private get styles(): { [key: string]: string } {
    return {
      color: this.status?.color ?? '#DCDCDC',
      backgroundColor: `rgba(${hexToRgb(this.status?.color ?? '#DCDCDC')}, .2)`,
    }
  }
}
