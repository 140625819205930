



















import { Component, Prop, Vue} from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway.ts'

@Component({
  mixins : [clickaway]
})
export default class DotDotDot extends Vue {

  // set the vertical position (top or bottom)
  @Prop({ default: 'top' })
  private yPos?: string

  // set the horizontal position (left or right)
  @Prop({ default: 'left' })
  private xPos?: string


  // is floater visible?
  private isVisible?: boolean = false

  // close the tooltip
  private closeFloater(): void {
    if (this.isVisible === false) {
      return
    }
    this.isVisible = false
  }

  // toggle between visible and hidden
  private toggleFloater(): void {
    this.isVisible = !this.isVisible
  }

  /**
   *  Will close the `floater` in the next DOM udpate
   */
  private closeHandler (): void {
    if (this.isVisible) {
      this.$nextTick(() => {
        this.isVisible = false
      })
    }
  }
}
