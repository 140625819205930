















import { stateModule } from '@/store'
import { SystemNotification } from '@/store/state/types'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Notifications extends Vue {
  private get notifications(): SystemNotification[] { return stateModule.notifications }
}
