
















import { Component, Vue, Prop } from 'vue-property-decorator'

import { UserResource } from '@/models/users/UserResource'
import { StatusResource } from '@/models/status/StatusResource'
import { AuthUserResource } from '@/models/users/AuthUserResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { capitalize } from '@/helpers'
import { userModule } from '@/store'

@Component
export default class UserImage extends Vue {
  @Prop()
  private status?: StatusResource

  @Prop()
  private user?: AuthUserResource | ProjectUserResource | UserResource

  private get authUser(): AuthUserResource | undefined {
    return userModule.user
  }

  private get isOwnUser() {
    return this.authUser?.id === this.user?.id
  }

  // computed imageurl
  private get userImageURL(): string {
    return this.user && this.user.image ? this.user.image : ''
  }

  private get initials(): string {
    return this.user ? this.user.initials : ''
  }

  private get userTooltipContent(): string {
    if (this.user && 'project_role' in this.user) {
      return capitalize(`${ this.user.name }`) + ' - ' + capitalize(`${ this.user.project_role }`)
    } else if (this.user) {
      return capitalize(this.user.name)
    }
    return ''
  }

}
