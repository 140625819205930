import { BaseService }  from '@/services/base'

import { TaskPatchRequest } from '@/requests/tasks/TaskPatchRequest'
import { TaskCreateRequest } from '@/requests/tasks/TaskCreateRequest'

import { ITaskCollectionResource, TaskCollectionResource } from '@/models/tasks/TaskCollectionResource'

export class TasksService extends BaseService {

  constructor() {
    super(`tasks`)
  }

  public async get(params: { project_id?: number, archived: boolean } & IndexParameters): Promise<IndexResponse<TaskCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<ITaskCollectionResource>>(`${this.namespace}`, { params })
    return {
      ...data,
      data: data.data.map((task) => new TaskCollectionResource(task))
    }
  }

  public async create(task: TaskCreateRequest): Promise<DetailResponse<TaskCollectionResource>> {
    const { data } = await this.client.post<DetailResponse<ITaskCollectionResource>>(`${this.namespace}`, task)
    return {
      ...data,
      data: new TaskCollectionResource(data.data)
    }
  }

  public async patch(id: number, task: TaskPatchRequest): Promise<DetailResponse<TaskCollectionResource>> {
    const { data } = await this.client.patch<DetailResponse<ITaskCollectionResource>>(`${this.namespace}/${id}`, task)
    return {
      ...data,
      data: new TaskCollectionResource(data.data)
    }
  }

  public async delete(id: number): Promise<void> {
    await this.client.delete<void>(`${this.namespace}/${id}`)
    return
  }

}
