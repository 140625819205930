import store                                    from '@/store/index'
import { ProjectsService }                      from '@/services/projects'
import * as Mutations                           from './mutation-types'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'


import { ProjectCollectionResource } from '@/models/projects/ProjectCollectionResource'
import { AxiosResponse }             from 'axios'

@Module({
  namespaced: true,
  name: 'projects',
  store
})
class ProjectsModule extends VuexModule {

  // State
  private _index: IndexResponse<ProjectCollectionResource> | null = null
  private _service: ProjectsService = new ProjectsService()

  // Getters
  public get index(): IndexResponse<ProjectCollectionResource> | null {
    return this._index
  }
  // Actions
  @Action({ rawError: true })
  public resetState(): void {
    this.context.commit(Mutations.SET_PROJECTS)
  }

  @Action({ rawError: true, commit: Mutations.SET_PROJECTS })
  public async get(params: IndexParameters): Promise<IndexResponse<ProjectCollectionResource>> {
    try {
      return await this._service.get(params)
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SET_PROJECTS })
  public removeIndex(): void {
    return
  }

  @Action({ rawError: true })
  public async exportProjectContents(projectIds: number[]): Promise<AxiosResponse> {
    return await this._service.exportProjectContents(projectIds)
  }

  @Mutation
  private [Mutations.SET_PROJECTS](payload?: IndexResponse<ProjectCollectionResource>): void {
    this._index = payload || null
  }

}

export default ProjectsModule
