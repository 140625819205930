import Vue                             from 'vue'
import { createSocket } from '@/api'
import { userModule }                  from '@/store'
import { convertToDate, formatDate } from '@/helpers/formatDate'
import { canPerformAction }          from '@/helpers/canPerformAction'

import { UserResource }                                                from '@/models/users/UserResource'
import { AuthUserResource }                                            from '@/models/users/AuthUserResource'
import { IProcessResource, ProcessResource }                           from '@/models/process/ProcessResource'
import { DocumentResource }                                            from '@/models/documents/DocumentResource'
import { IProjectUserResource, ProjectUserResource }                   from '@/models/projects/ProjectUserResource'
import { IProjectTeamResource, ProjectTeamResource }                   from '@/models/projects/ProjectTeamResource'
import { ProjectRoleResource }                                         from '@/models/projects/ProjectRoleResource'
import { DocumentCollectionResource }                                  from '@/models/documents/DocumentCollectionResource'
import { FlexibleDocumentMetaResource, IFlexibleDocumentMetaResource } from '@/models/flexibleDocument/flexibleDocumentMetaResource'
import { FlexibleDocumentMetaSectionResource }                         from '@/models/flexibleDocument/FlexibleDocumentMetaSectionResource'

import { ProjectPatchUserRequest }          from '@/requests/projects/ProjectPatchUserRequest'
import { ProjectUserIndexRequest }          from '@/requests/projects/ProjectUserIndexRequest'
import { ProjectUpdateNameRequest }         from '@/requests/projects/ProjectUpdateNameRequest'
import { ProjectUsersCreateRequest }        from '@/requests/projects/ProjectUsersCreateRequest'
import { ProjectUpdateEndDateRequest }      from '@/requests/projects/ProjectUpdateEndDateRequest'
import { FlexibleDocumentMetaPatchRequest } from '@/requests/flexibleDocument/FlexibleDocumentMetaPatchRequest'

import { ProjectService }          from '@/services/project'
import { ProjectsService }         from '@/services/projects'
import { DocumentsService }        from '@/services/documents'
import { OrganisationService }     from '@/services/organisation'
import { FlexibleDocumentService } from '@/services/flexibleDocument'

import { TasksController }         from '@/controllers/tasks/TasksController'
import { ChatController }          from '@/controllers/chat/ChatController'
import { NotificationsController } from '@/controllers/notifications/NotificationsController'

import { ProjectParentResource }                                           from './ProjectParentResource'
import { CommentResource, ICommentResource, isCommentResource }            from '../comments/CommentResource'
import { PROPOSAL_EVENTS }                                                 from '../proposals/ProposalResource'
import { FlexibleDocumentStatusResource, IFlexibleDocumentStatusResource } from '@/models/status/FlexibleDocumentStatusResource'
import { Socket }                                                          from 'socket.io-client'

export type ProjectPermissions = 'can_add_documents' | 'can_edit_team' | 'can_edit_project' | 'can_delete_documents' | 'can_archive'
export type ProjectTypes = 'clone' | 'reference'

// export const PROJECT_EVENTS = {
//   UPDATED: 'proposal updated',
//   COMMENT_CREATED: 'comment created',
//   COMMENT_DELETED: 'comment deleted'
// }

export interface IProjectResource {
  readonly id: number
  project_name: string
  owner: IProjectUserResource | string
  team: IProjectTeamResource[]
  processes: IProcessResource[]
  permissions: ProjectPermissions[]
  documents?: any
  end_date: Date | string | null
  last_update: string
  has_template: boolean
  type: ProjectTypes
  document_title: string | undefined
  document_status: IFlexibleDocumentStatusResource | undefined
  channel: string
  chat_channel: string
  parent_project?: IProjectResource | null
  meta?: IFlexibleDocumentMetaResource | null
  meta_values?: IFlexibleDocumentMetaResource | null
  pdf_link?: string | null
  documents_count: number
  documents_index?: Array<{ id: number, title: string }>
  flexible_document_title?: string
  flexible_document_status?: string
}

export class ProjectResource {
  public readonly id: number
  public project_name: string
  public owner: ProjectUserResource | string
  public team: ProjectTeamResource[]
  public processes: ProcessResource[]
  public permissions: ProjectPermissions[]
  public end_date: Date | string | null
  public last_update: string
  public has_template: boolean
  public type: ProjectTypes
  public document_title: string | undefined
  public document_status: FlexibleDocumentStatusResource | undefined
  public channel: string
  public chat_channel: string
  public parent_project?: ProjectParentResource | null
  public pdf_link?: string | null
  public documents_count: number
  public documents_index?: Array<{ id: number, title: string }>

  // DOCUMENTS
  public document: DetailResponse<DocumentResource> | null = null
  public documents: IndexResponse<DocumentCollectionResource> | null = null

  // NOTIFICATIONS
  public notifications: NotificationsController
  public activities: NotificationsController

  // TASKS
  public archivedTasks: TasksController
  public tasks: TasksController

  // Chat
  public chat: ChatController

  // Socket
  public socket?: Socket
  public chatSocket?: Socket

  public meta?: FlexibleDocumentMetaResource | null
  public meta_values?: FlexibleDocumentMetaResource | null
  public metaFields: FlexibleDocumentMetaSectionResource[] = []
  public metaForm?: FlexibleDocumentMetaPatchRequest | null
  // SERVICES

  private readonly projectService: ProjectService
  private readonly documentsService: DocumentsService
  private readonly projectsService: ProjectsService
  private readonly organisationService: OrganisationService
  private readonly flexibleDocumentService: FlexibleDocumentService

  constructor(project: IProjectResource) {
    this.id = project.id
    this.project_name = project.project_name
    this.owner = project.owner && typeof project.owner !== 'string' ? new ProjectUserResource(project.owner) : project.owner

    this.team = project.team ? project.team.map((u: IProjectTeamResource) => new ProjectTeamResource(u)) : []

    this.processes = project.processes ? project.processes.map((process: IProcessResource) => new ProcessResource(process)) : []
    this.permissions = project.permissions
    this.end_date = project.end_date ? convertToDate(project.end_date as string) : null
    this.last_update = project.last_update
    this.has_template = project.has_template
    this.type = project.type
    this.document_title = project.document_title
    this.meta = project.meta
    this.meta_values = project.meta_values
    this.pdf_link = project.pdf_link
    this.documents_count = project.documents_count ?? 0
    this.documents_index = project.documents

    if (project.document_status) {
      this.document_status = new FlexibleDocumentStatusResource(project.document_status)
    }

    this.channel = project.channel
    this.chat_channel = project.chat_channel

    this.documentsService = new DocumentsService()
    this.projectsService = new ProjectsService()
    this.organisationService = new OrganisationService()
    this.projectService = new ProjectService({ project_id: project.id })
    this.flexibleDocumentService = new FlexibleDocumentService({ project_id: project.id })

    this.tasks = new TasksController({ project_id: this.id, archived: false })
    this.archivedTasks = new TasksController({ project_id: this.id, archived: true })
    this.activities = new NotificationsController({ service: this.projectService, namespace: 'activities' })
    this.notifications = new NotificationsController({ service: this.projectService, namespace: 'notifications' })
    this.chat = new ChatController({ id: this.id, commentableType: 'project_chat', service: this.projectService })

    this.openSocket()

    if (project.parent_project) {
      this.parent_project = new ProjectParentResource(project.parent_project)
    }
    this.getMetaOptions()
  }

  public destroy(): void {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
    this.chatSocket?.off('event', (e: EventResponse<{ event: string, message: ICommentResource }>) => this.handleChatEvent(e))
  }

  public get endDateFormatted(): string {
    if (this.end_date) {
      return formatDate(this.end_date as Date)
    }

    return '-'
  }

  public get documentProcess(): ProcessResource | undefined {
    return this.processes?.find((process: ProcessResource) => process.process_type === 'document')
  }

  public get taggingProcess(): ProcessResource | undefined {
    return this.processes?.find((process: ProcessResource) => process.process_type === 'marking')
  }

  public get user(): AuthUserResource | undefined {
    return userModule.user
  }

  public get canSubmitMeta(): boolean {
    if (!this.metaForm) return false
    for (const field in this.metaForm) {
      // Check if key exists
      if (field in this.metaForm) {
        // Set value as const for typescript
        const value = this.metaForm[field]
        if (typeof value === 'string' || typeof value === 'object' && !(value instanceof Date)) {
          if (value.length === 0) {
            return false
          }
        }
      }
    }
    return true
  }

  public get projectType(): string | undefined {
    switch (this.type) {
      case 'clone':
        return 'Update'
      case 'reference':
        return 'Derivative'
      default:
        return undefined
    }
  }

  public getProcessByOrder(order: number): ProcessResource | undefined {
    return this.processes?.find((process) => process.order === order)
  }

  public async refreshData(): Promise<ProjectResource> {
    const { data } = await this.projectService.get()
    this.setData(data)
    return this
  }

  public async patch(body: ProjectUpdateNameRequest | ProjectUpdateEndDateRequest): Promise<ProjectResource> {
    const { data } = await this.projectService.patch(body)
    this.setData(data)
    return this
  }

  // META
  public async getMetaOptions() {
    const { data } = await this.flexibleDocumentService.getMeta()
    this.metaFields.push(...data)
    Vue.set(this, 'metaForm', new FlexibleDocumentMetaPatchRequest({ sections: data, values: this.meta_values }))
    return data
  }

  public resetMetaValues() {
    if (this.metaFields) {
      Vue.set(this, 'metaForm', new FlexibleDocumentMetaPatchRequest({ sections: this.metaFields, values: this.meta_values }))
    }
  }

  public async patchMeta() {
    if (!this.metaForm) return
    await this.flexibleDocumentService.patchMeta({ form: this.metaForm })
    this.refreshData()
    return this
  }

  // PROCESS
  public async getProcess(process_id: number): Promise<DetailResponse<ProcessResource>> {
    return await this.projectService.getProcess(process_id)
  }

  // USERS
  public async getUsers(params?: ProjectUserIndexRequest): Promise<IndexResponse<ProjectUserResource>> {
    return await this.projectService.getUsers(params)
  }

  public async getAvailableUsers(params?: ProjectUserIndexRequest): Promise<IndexResponse<UserResource>> {
    return await this.projectService.getAvailableUsers(params)
  }

  public async attachUsers(body: ProjectUsersCreateRequest): Promise<IndexResponse<ProjectUserResource>> {
    return await this.projectService.attachUsers(body)
  }

  public async getRoles(): Promise<IndexResponse<ProjectRoleResource>> {
    return await this.organisationService.getRoles()
  }

  public async deleteUser(userId: number): Promise<void> {
    await this.projectService.deleteUser(userId)
    return
  }

  public async patchUser(userId: number, body: ProjectPatchUserRequest): Promise<DetailResponse<ProjectUserResource>> {
    return await this.projectService.patchUser(userId, body)
  }

  // DOCUMENTS
  public async getDocuments(params: IndexParameters): Promise<IndexResponse<DocumentCollectionResource>> {
    const data = await this.projectService.getDocuments(params)
    Vue.set(this, 'documents', data)
    return data
  }

  public async getDocument(id: number): Promise<DetailResponse<DocumentResource>> {
    const data = await this.projectService.getDocument(id)
    Vue.set(this, 'document', data)
    return data
  }

  public resetDocument(): void {
    Vue.set(this, 'document', null)
  }

  public async deleteDocument(id: number): Promise<void> {
    await this.projectService.deleteDocument(id)
    return
  }

  public async getDocumentDiff(id: number): Promise<DetailResponse<DocumentResource>> {
    return await this.projectService.getDocumentDiff(id)
  }

  public async getProcesses(): Promise<IndexResponse<ProcessResource>> {
    return await this.projectService.getProcesses()
  }

  public canPerformAction(key: ProjectPermissions): boolean {
    return canPerformAction<ProjectPermissions>(this.permissions, key)
  }

  public async archive(): Promise<void> {
    return this.projectService.archive()
  }

  private setData(data: ProjectResource): void {
    this.project_name = data.project_name
    this.owner = data.owner

    this.team = data.team

    this.processes = data.processes ? data.processes.map((process: IProcessResource) => new ProcessResource(process)) : []
    this.documents_count = data.documents_count ?? 0
    this.permissions = data.permissions
    this.end_date = data.end_date
    this.has_template = data.has_template
    this.document_title = data.document_title
    this.meta = data.meta
    this.meta_values = data.meta_values
    this.resetMetaValues()

    if (data.document_status) {
      this.document_status = data.document_status
    }
  }

  private handleEvent(res: EventResponse): void {
    if (res.event === 'App\\Events\\ProjectsEvent' || res.event === 'App\\Events\\ProjectUpdateEvent') {
      switch (res.data.event) {
        case 'project notification':
          this.notifications.refresh()
          this.activities.refresh()
          break
        case 'project updated':
          this.refreshData()
          break
      }
    }
  }

  private handleChatEvent(res: EventResponse<{ event: string, message: ICommentResource }>): void {
    if (res.event === 'App\\Events\\ProjectChatEvent') {
      if (res.data.event === PROPOSAL_EVENTS.COMMENT_CREATED) {
        if (res.data.message && isCommentResource(res.data.message) && this.chat) {
          this.chat.addMessage(new CommentResource(res.data.message))
        }
      }
    }
  }

  private async openSocket(): Promise<void> {
    const [socket, chatSocket] = await Promise.all([createSocket(this.channel), createSocket(this.chat_channel)])
    this.socket = socket
    this.chatSocket = chatSocket
    this.socket?.on('event', (e: EventResponse) => this.handleEvent(e))
    this.chatSocket?.on('event', (e: EventResponse<{ event: string, message: ICommentResource }>) => this.handleChatEvent(e))
  }
}
