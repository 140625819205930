









import { Vue, Component, Prop } from 'vue-property-decorator'

const minColumnCount: number = 2
const maxColumnCount: number = 5
const minRowCount: number = 1
const maxRowCount: number = Infinity

const createEmptyArray = (count: number): string[] => {
  const array = []
  for (let i = 0; i < count; i++) {
    array.push('')
  }
  return array
}

import CommentBox from '@/components/inputs/CommentBox.vue'

@Component({
  components: {
    CommentBox
  }
})
export default class TableInput extends Vue {

  @Prop()
  private value!: [string[]]

  private get columnCount(): number {
    return this.value[0]?.length || 0
  }

  private get rowStyle(): Dictionary<string> {
    return { gridTemplateColumns: `repeat(${this.columnCount}, 1fr)` }
  }

}
