import { formatDate } from '@/helpers/formatDate'

export interface ReviewResource {
  accepted: number
  declined: number
  pending: number
  total: number
  needs_review: boolean
}

export interface IFlexibleDocumentStatusResource {
  id: number
  label: string
  value: string
  verb: string
  color: string
  order?: number
  description: string
  need_substantiation: boolean
  need_allocation: string[]
  need_deadline: boolean
  review_meta: boolean
  add_to_library: boolean
  review?: ReviewResource
  deadline?: string
}

export class FlexibleDocumentStatusResource {
  public id: number
  public label: string
  public value: string
  public verb: string
  public color: string
  public order: number
  public description: string
  public need_substantiation: boolean
  public need_allocation: string[]
  public need_deadline: boolean
  public review_meta: boolean
  public add_to_library: boolean
  public review?: ReviewResource
  public deadline?: Date
  [key: string]: number | string | boolean | string[] | Date | ReviewResource |undefined

  constructor(status: IFlexibleDocumentStatusResource) {
    this.id = status.id
    this.label = status.label
    this.value = status.value
    this.verb = status.verb
    this.color = status.color
    this.order = status.order ?? 0
    this.description = status.description
    this.need_substantiation = status.need_substantiation
    this.need_allocation = status.need_allocation
    this.need_deadline = status.need_deadline
    this.review_meta = status.review_meta
    this.add_to_library = status.add_to_library
    this.review = status.review
    this.deadline = status.deadline ? new Date(status.deadline) : undefined
  }

  public get formattedDeadline(): string | undefined {
    return this.deadline ? formatDate(this.deadline) : undefined
  }

}
