






import Tribute from 'tributejs'
import FroalaEditor from 'froala-editor'

import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/char_counter.min.css'

import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// import { PlainClipboard } from '@/modules/PlainClipboard'
// import { indentClass } from '@/modules/LimitedIndent'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { userModule } from '@/store'
import { AuthUserResource } from '@/models/users/AuthUserResource'
import { OrganisationThemeResource } from '@/models/organisation/OrganisationThemeResource'

@Component
export default class WysiwygEditor extends Vue {
  @Prop()
  private placeholder!: string

  @Prop({ required: true })
  private value!: string

  @Prop({ default: false })
  private simple!: boolean

  @Prop({ default: false })
  private noToolbar!: boolean

  @Prop({ required: false })
  private users!: ProjectUserResource[] | null

  @Prop({ default: false })
  private focus!: boolean

  @Prop({ default: 150 })
  private readonly maxCharacters!: number

  @Prop({ default: false })
  private readonly limitCharacters!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: 'Save' })
  private saveText!: string

  @Prop({ default: false })
  private canSave!: boolean

  @Prop({ default: false })
  private canCancel!: boolean

  private froala: any | null = null

  @Watch('value')
  private onValueChange(val: string): void {
    // Change froala editor value with value if changes from outside editor.
    // But not when focused on editor.
    // This will trigger an endless loop
    if (this.froala && val !== this.froala.html.get()) {
      this.setValueIntoEditor(val)
    }
  }

  private get theme(): OrganisationThemeResource | null {
    return userModule.theme
  }

  private mounted(): void {
    this.setEditor()
  }

  private setEditor(): void {

    FroalaEditor.RegisterCommand('cancel', {
      title: 'Cancel',
      icon: 'Cancel',
      focus: true,
      refreshAfterCallback: false,
      callback: () => {
        this.$emit('cancel')
      }
    })

    FroalaEditor.RegisterCommand('save', {
      title: 'Save',
      icon: this.saveText,
      focus: true,
      refreshAfterCallback: false,
      callback: () => {
        this.$emit('save')
      }
    })

    const TOOLBAR_BUTTONS: string[][] = []
    if (!this.noToolbar) TOOLBAR_BUTTONS.push(...[['bold', 'italic', 'underline', 'subscript', 'superscript', 'textColor', 'formatOL', 'formatUL', 'insertTable', 'clearFormatting']])

    // Should we add an extra toolbar?
    if (this.canSave || this.canCancel) {
      const EXTRA_TOOLBAR: string[] = []
      if (this.canCancel) EXTRA_TOOLBAR.push('cancel')
      if (this.canSave) EXTRA_TOOLBAR.push('save')
      TOOLBAR_BUTTONS.push(EXTRA_TOOLBAR)
    }

    // Adds organisation theme colors
    const COLORS = ['REMOVE']
    if (this.theme) {
      COLORS.unshift(...Object.values(this.theme))
      COLORS.unshift('#0f1010')
    } else {
      COLORS.unshift(...['#0f1010', '#60A6DA', '#FF6200'])
    }


    const options: { [key: string]: any} = {
      attribution: false,
      inlineMode: false,
      listAdvancedTypes: false,
      toolbarButtons: TOOLBAR_BUTTONS,
      placeholderText: this.placeholder ?? '',
      autoFocus: this.focus,
      colorsText: COLORS,
      typingTimer: 100,
      key: 'fIE3A-9C1F2F2B2C1C1td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3D2D4F2D2C3A2C1=='
    }

    options.charCounterCount = this.limitCharacters
    options.charCounterMax = this.maxCharacters

    const CONTAINER = this.$refs.container as Element

    this.froala = new FroalaEditor(CONTAINER, options, () => {
      this.setValueIntoEditor(this.value)
      this.handleUpdatedEditor()
      this.setTribute()
      this.setCustomButtonClasses()
    })

  }

  private setCustomButtonClasses(): void {
    if (this.canSave || this.canCancel) {
      // We add no-transition otherwise the buttons will transition between froala buttons to custom buttons
      // When animation is completed we remove it again
      this.froala.$tb.find(`[data-cmd="save"]`).addClass(`no-transition button small secondary`)
      this.froala.$tb.find(`[data-cmd="cancel"]`).addClass(`no-transition button small grey`)
      setTimeout(() => {
        this.froala.$tb.find(`[data-cmd="save"]`).removeClass(`no-transition`)
        this.froala.$tb.find(`[data-cmd="cancel"]`).removeClass(`no-transition`)
      }, 10)
    }
  }

  private setValueIntoEditor(value: string): void {
    if (!this.froala) return
    this.froala.html.set(value)
  }

  private handleUpdatedEditor(): void {
    if (!this.froala) return

    this.froala.events.on('contentChanged', (e: KeyboardEvent) => {
      this.$emit('input', this.froala.html.get())
    }, true)
  }

  private setTribute(): void {
    if (!this.froala && !this.users) return

    const tribute = new Tribute({
      values: this.users ? this.users.map(({ id, name }) => ({ key: name, value: id })) : [],
      selectTemplate: (item) => {
        return `<span class="mention fr-deletable fr-tribute" data-id="${item.original.value}" data-value="${item.original.key}">@${item.original.key}</a></span>`
      }
    })
    tribute.attach(this.froala.el)

    this.froala.events.on('keydown', (e: KeyboardEvent) => {
      if (e.which === FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
        return false
      }
    }, true)
  }

  private replaceRootElementInText(text: string): string {
    return text && typeof text === 'string'
        ? text.replace('<root>', '').replace('</root>', '')
        : text
  }
}
