export interface IProjectRoleResource {
  id: number
  slug?: string
  name: string
  count: number | null
}

export class ProjectRoleResource {
  public id: number
  public slug?: string
  public name: string
  public count: number | null

  constructor({ id, slug, name, count }: IProjectRoleResource) {
    this.id = id
    this.slug = slug
    this.name = name
    this.count = count
  }
}
