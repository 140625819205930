
























import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProposalResource } from '@/models/proposals/ProposalResource'
import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'

import ProposalSimple from '@/components/tiles/ProposalSimple.vue'

@Component({
  components: {
    ProposalSimple,
  }
})
export default class ProcessConnection extends Vue {

  @Prop({ required: true })
  private readonly process!: ProcessResource

  @Prop({ required: true, default: [] })
  private readonly proposals!: ProposalSummaryCollectionResource[]

  @Prop({ default: false })
  private readonly parent!: boolean

  @Prop({ default: false })
  private readonly canEditConnections!: boolean

  @Prop({ default: false })
  private readonly!: boolean

  private open: boolean = false

  private toggle(): void {
    this.open = !this.open
  }

  private mounted(): void {
    this.open = false
  }

  private goToProposal(proposal: ProposalResource): void {
    this.$router.push({
      name: 'projects-detail-proposal-detail',
      params: {
        project_id: proposal.project_id.toString(),
        process_id: proposal.process_id.toString(),
        proposal_id: proposal.id.toString()
      }
      })
  }
}
