import { render, staticRenderFns } from "./subscript.vue?vue&type=template&id=310b2ebe&scoped=true&"
var script = {}
import style0 from "./subscript.vue?vue&type=style&index=0&id=310b2ebe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310b2ebe",
  null
  
)

export default component.exports