


















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { WidgetMixin }  from '@/plugins/widget-mixin'

import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import TextInput   from '@/components/inputs/Text.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

@Component({
  name: 'TitleSubtitleWidget',
  components: {
    TextInput,
    SmallLoader
  }
})
export default class TitleSubtitleWidget extends Mixins(WidgetMixin) {

  private get titleItem(): | FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((item) => item.component_key === 'title')
  }

  private get subTitleItem(): | FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((item) => item.component_key === 'subtitle')
  }

}
