










































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop()
  private pagination!: PaginationInterface

  private toPage(int: number): void {
    if (int <= this.pagination.last_page) {
      this.$emit('goTo', int.toString())
      this.scrollTop()
    }
  }

  private nextPage(): void {
    this.$emit('goTo', (this.pagination.current_page + 1).toString())
    this.scrollTop()
  }

  private prevPage(): void {
    this.$emit('goTo', (this.pagination.current_page - 1).toString())
    this.scrollTop()
  }

  private scrollTop(): void {
    this.$emit('scroll')
  }
}
