import { StatusResource } from '@/models/status/StatusResource'

export interface IProjectTypeResourceCollection {
  id: number
  name: string
  description: string
  template_count: number
  process_count: number
  statuses: StatusResource[]
}

export class ProjectTypeResourceCollection {
  public id: number
  public name: string
  public description: string
  public template_count: number
  public process_count: number
  public statuses: StatusResource[]

  constructor(projectType: IProjectTypeResourceCollection) {
    this.id = projectType.id
    this.name = projectType.name
    this.description = projectType.description
    this.template_count = projectType.template_count
    this.process_count = projectType.process_count
    this.statuses = projectType.statuses
  }
}
