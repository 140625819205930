import { FlexibleDocumentItemResource }           from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'

export class FlexibleDocumentUpdateItemAllocateUsersRequest {
  public notify_user: number[]

  constructor(item: FlexibleDocumentItemResource | FlexibleDocumentItemCollectionResource) {
    this.notify_user = item.allocated_users?.map((user) => user.id) ?? []
  }
}
