import { API }                           from '@/api'
import { DocumentPresetPatchRequest }    from '@/requests/documents/DocumentPresetPatchRequest'
import { DocumentPresetRestoreRequest }                                  from '@/requests/documents/DocumentPresetRestoreRequest'
import { DocumentPresetVersionResource, IDocumentPresetVersionResource } from '@/models/documents/DocumentPresetVersionResource'

export interface IDocumentPresetResource {
  id: number
  key: string
  name: string
  count: number
  predefined: boolean
  newDocCount: number
  values: Array<{ key: string, values: string[] | string }>
  department_ids: number[]
  country_ids: string[]
  departments: string[]
  countries: string[]
}

export class DocumentPresetResource {
  public id: number
  public key: string
  public name: string
  public count: number
  public predefined: boolean
  public newDocCount: number
  public values: Array<{ key: string, values: string[] | string }>
  public department_ids: number[] = []
  public country_ids: string[] = []
  public departments: string[] = []
  public countries: string[] = []

  constructor(preset: IDocumentPresetResource) {
    this.id = preset.id
    this.key = preset.key
    this.name = preset.name
    this.count = preset.count
    this.predefined = preset.predefined
    this.newDocCount = preset.newDocCount
    this.values = preset.values
    this.department_ids = preset.department_ids
    this.country_ids = preset.country_ids
    this.departments = preset.departments
    this.countries = preset.countries
  }

  public get hasNewDoc() {
    return this.newDocCount > 0
  }

  public async patch(body: DocumentPresetPatchRequest): Promise<DocumentPresetResource> {
    const { data } = await API.patch(`documents/presets/${this.id}`, body)
    this.setData(data.data)
    return this
  }

  public async markAllAsRead(): Promise<DocumentPresetResource> {
    const { data } = await API.patch(`documents/presets/${this.id}/markAsRead`)
    this.setData(data.data)
    return this
  }

  public async delete(): Promise<DocumentPresetResource> {
    await API.delete(`documents/presets/${this.id}`)
    return this
  }

  public async getVersions(): Promise<DocumentPresetVersionResource[]> {
    const { data } = await API.get(`documents/presets/${this.id}/versions`)
    return data.data.map((version: IDocumentPresetVersionResource) => new DocumentPresetVersionResource(version))
                              .sort((a: DocumentPresetVersionResource, b: DocumentPresetVersionResource) => b.id - a.id)
  }

  public async restoreVersion({ versionId, body }: { versionId: number, body: DocumentPresetRestoreRequest }): Promise<DocumentPresetResource> {
    const { data } = await API.post(`documents/presets/${this.id}/versions/${versionId}`, body)
    this.setData(data.data)
    return this
  }

  protected setData(preset: DocumentPresetResource) {
    this.id = preset.id
    this.key = preset.key
    this.name = preset.name
    this.count = preset.count
    this.predefined = preset.predefined
    this.newDocCount = preset.newDocCount
    this.values = preset.values
    this.department_ids = preset.department_ids
    this.country_ids = preset.country_ids
    this.departments = preset.departments
    this.countries = preset.countries
  }

}
