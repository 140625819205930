import { IProjectUserResource, ProjectUserResource }   from '@/models/projects/ProjectUserResource'
import { IStatusResource, StatusResource }             from '@/models/status/StatusResource'
import { convertToDate, getHumanReadableDateWithTime } from '@/helpers/formatDate'

type AuditType = 'edit' | 'created' | 'status' | 'connected' | 'disconnected' | 'allocation' | 'lock'

export interface IAuditResource {
  id: number
  message: string
  title: string
  status?: IStatusResource
  created_at: string
  user: IProjectUserResource
  allocated_users?: IProjectUserResource[]
  connected_proposals?: string[]
  disconnected_proposals?: string[]
  audit_type: AuditType
}

export class AuditResource {
  public id: number
  public message: string
  public title: string
  public status?: StatusResource
  public created_at: string
  public user: ProjectUserResource
  public allocated_users?: ProjectUserResource[]
  public connected_proposals?: string[]
  public disconnected_proposals?: string[]
  public audit_type: AuditType

  constructor(audit: IAuditResource) {
    this.id = audit.id
    this.message = audit.message
    this.title = audit.title
    this.status = audit.status ? new StatusResource(audit.status) : undefined
    this.created_at = audit.created_at
    this.user = new ProjectUserResource(audit.user)
    this.allocated_users = audit.allocated_users?.map((user) => new ProjectUserResource(user))
    this.connected_proposals = audit.connected_proposals
    this.disconnected_proposals = audit.disconnected_proposals
    this.audit_type = audit.audit_type
  }

  public get formattedCreatedAt(): string {
    return getHumanReadableDateWithTime(convertToDate(this.created_at))
  }
}
