






import Tribute from 'tributejs'
import FroalaEditor from 'froala-editor'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

@Component
export default class CommentBox extends Vue {
  @Prop()
  private placeholder!: string

  @Prop({ required: true })
  private value!: string

  @Prop({ default: false })
  private canClose!: boolean

  @Prop({ required: false })
  private users!: ProjectUserResource[] | null

  @Prop({ default: false })
  private focus!: boolean

  private froala: any | null = null

  @Watch('value')
  private onValueChange(val: string): void {
    // Change editor editor value with value if changes from outside editor.
    // But not when focused on editor.
    // This will trigger an endless loop
    if (this.froala && val !== this.froala.html.get()) {
      this.setValueIntoEditor(val)
    }
  }

  private mounted(): void {
    this.setEditor()
  }

  private setEditor(): void {
    const options: { [key: string]: any} = {
      attribution: false,
      toolbarButtons: {},
      placeholderText: this.placeholder ?? '',
      autoFocus: this.focus,
      enter: FroalaEditor.ENTER_BR,
      typingTimer: 100,
      key: 'fIE3A-9C1F2F2B2C1C1td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3D2D4F2D2C3A2C1=='
    }

    const CONTAINER = this.$refs.container as Element

    this.froala = new FroalaEditor(CONTAINER, options, () => {
      this.setValueIntoEditor(this.value)
      this.handleUpdatedEditor()
      this.setTribute()
    })
  }

  private setValueIntoEditor(value: string): void {
    if (!this.froala) return

    this.froala.html.set(value)
  }

  private handleUpdatedEditor(): void {
    if (!this.froala) return

    this.froala.events.on('contentChanged', (e: KeyboardEvent) => {
      this.$emit('input', this.froala.html.get())
    }, true)
  }

  private setTribute(): void {
    if (!this.froala && !this.users) return

    const tribute = new Tribute({
      values: this.users ? this.users.map(({ id, name }) => ({ key: name, value: id })) : [],
      selectTemplate: (item) => {
        return `<span class="mention fr-deletable fr-tribute" data-id="${item.original.value}" data-value="${item.original.key}">@${item.original.key}</a></span>`
      }
    })
    tribute.attach(this.froala.el)

    this.froala.events.on('keydown', (e: KeyboardEvent) => {
      if (e.which === FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
        return false
      }
    }, true)
  }

  private replaceRootElementInText(text: string): string {
    return text && typeof text === 'string'
        ? text.replace('<root>', '').replace('</root>', '')
        : text
  }
}
