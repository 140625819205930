











import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { WidgetResource } from '@/models/widgets/WidgetResource'
import { ComponentResource } from '@/models/widgets/ComponentResource'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProjectService } from '@/services/project'
import { DocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'

import BaseComponent from '@/components/proposal/create/baseComponents/BaseComponent.vue'

@Component({ components: { BaseComponent } })
export default class Widget extends Vue {
  @Prop()
  private readonly widgetValues!: Array<{ component_id: number; data: string }>

  @Prop()
  private readonly process!: ProcessResource

  @Prop({ default: null })
  private readonly widget!: WidgetResource

  private get isDocumentMetaWidget(): boolean {
    return this.widget.name === 'document_meta'
  }

  private async updateValue(value: string, component_id: number) {
    this.$emit('updateComponentValue', value, component_id)
  }

  private getComponentValue(component: ComponentResource): string {
    return (
      this.widgetValues.find((w) => w.component_id === component.id)?.data || ''
    )
  }
}
