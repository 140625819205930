import { ProposalCollectionResource }        from '@/models/proposals/ProposalCollectionResource'

export class ProposalUpdateRequest {
  public proposal_name: string = ''
  public values: Array<{ component_id: number, data: string }>

  constructor(proposal: ProposalCollectionResource) {
    this.proposal_name = proposal.proposal_name
    this.values = proposal.components.map((c) => ({ component_id: c.component_id, data: c.value }))
  }

}
