





















import { Component, Mixins } from 'vue-property-decorator'
import { WidgetMixin } from '@/plugins/widget-mixin'

import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import TextInput from '@/components/inputs/Text.vue'

@Component({
  name: 'TitleSubtitleWidget',
  components: {
    TextInput
  }
})
export default class FrontPageTitle extends Mixins(WidgetMixin) {

  private get titleItem(): | FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((item) => item.component_key === 'title')
  }

  private get subTitleItem(): | FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((item) => item.component_key === 'subtitle')
  }

  private get dateItem(): | FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((item) => item.component_key === 'date')
  }
}
