import { DirectiveOptions } from 'vue'
import tippy, { Instance, Props }  from 'tippy.js'

type tippyElement = HTMLElement & { _tippy: Instance | undefined }

export const tooltip: DirectiveOptions = {
  bind(el, node): void {
    const element = convertElementToTippyElement(el)
    // Set tippy if directive has value
    if (node.value) {
      createTippy(element, createTippyObject(node.value))
    }
  },
  update (el, node): void {
    const element = convertElementToTippyElement(el)

    // Update tippy if directive has value
    if (node.value && element._tippy) {
      updateTippy(element._tippy, createTippyObject(node.value))
    }

    // Set tippy if directive has value and tippy is not created yet
    if (node.value && !element._tippy) {
      createTippy(element, createTippyObject(node.value))
    }

    // Destroy tippy if value is removed and tippy was already created
    if (!node.value && element._tippy) {
      destroyTippy(element._tippy)
    }
  },
  unbind (el): void {
    const element = convertElementToTippyElement(el)
    if (element._tippy) {
      // Destroy tippy on unbind
      destroyTippy(element._tippy)
    }
  }
}

const createTippy = (el: tippyElement, value: Partial<Props>): Instance => {
  return el._tippy = tippy(el, value)
}

const updateTippy = (instance: Instance, value: Partial<Props>): void => {
  return instance.setProps(value)
}

const destroyTippy = (instance: Instance): void => {
  return instance.destroy()
}

const createTippyObject = (value: object | string): Partial<Props> => {
  if (typeof value === 'string') {
    return {
      content: value,
    }
  } else {
    return value as Partial<Props>
  }
}

const convertElementToTippyElement = (el: HTMLElement): tippyElement => {
  return el as tippyElement
}
