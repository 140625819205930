




















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {calculateRemHeight} from '@/helpers/calculateRemHeight'

import {AuditResource} from '@/models/audits/AuditResource'

import UserImage from '@/components/user/UserImage.vue'
import SwitchInput from '@/components/inputs/Switch.vue'

@Component({
  components: {
    UserImage,
    SwitchInput
  }
})
export default class AuditComment extends Vue {

  @Prop()
  private audit!: AuditResource

  private readmore: boolean = false
  private showReadMoreButton: boolean = false
  private viewChanges: boolean = false

  private mounted(): void {
    this.checkMessageHeight()
  }

  private get allocatedUsersFormatted() {
    return this.audit.allocated_users?.map(({ name }) => name).join(', ')
  }

  private toggleViewChanges() {
    this.viewChanges = !this.viewChanges
    if (this.viewChanges) {
      this.readmore = true
    }
  }

  // This will toggle the message open to check if the message is higher than 2 lines,
  // If so we show the view more button
  // This is not visible in the FE though
  private checkMessageHeight(): void {
    const message = this.$refs.message as HTMLParagraphElement
    if (!message) return
    this.readmore = true
    this.$nextTick(() => {
      const truncatedHeight = calculateRemHeight(3.9)

      const messageHeight = message.offsetHeight
      this.showReadMoreButton = messageHeight > truncatedHeight

      this.readmore = false
    })

  }
}

