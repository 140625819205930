import Vue from 'vue'
import store from '@/store/index'
import * as Mutations                           from './mutation-types'
import { FlexibleDocumentService }              from '@/services/flexibleDocument'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

@Module({
  namespaced: true,
  name: 'flexibleDocument',
  store
})
class FlexibleDocumentModule extends VuexModule {


  private _flexibleDocument!: FlexibleDocumentResource | null

  public get flexibleDocument(): FlexibleDocumentResource | null {
    return this._flexibleDocument
  }

  public get flexibleDocumentService(): FlexibleDocumentService | null {
    return this._flexibleDocument?.flexibleDocumentService ?? null
  }

  // Actions
  @Action({ rawError: true, commit: Mutations.SET_FLEXIBLE_DOCUMENT })
  public setFlexibleDocument(payload: FlexibleDocumentResource): FlexibleDocumentResource {
    return payload
  }

  @Mutation
  private [Mutations.SET_FLEXIBLE_DOCUMENT](payload: FlexibleDocumentResource): void {
    Vue.set(this, '_flexibleDocument', payload)
  }

}

export default FlexibleDocumentModule
