












import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { WidgetResource } from '@/models/widgets/WidgetResource'

import Widget from '@/components/proposal/create/Widget.vue'
import { ProcessResource } from '@/models/process/ProcessResource'

@Component({ components: { Widget } })
export default class WidgetList extends Vue {
  @Prop()
  private readonly process!: ProcessResource

  @Prop({ required: true })
  private readonly widgets!: WidgetResource[]

  @Model('input')
  private readonly widgetValues!: Array<{ component_id: number; data: string }>

  @Prop({ default: null })
  private readonly title!: string

  @Prop({ default: true })
  private readonly showPlaceholder!: boolean

  private updateComponentValue(data: string, component_id: number): void {
    const component = this.widgetValues.find(
      (w) => w.component_id === component_id,
    )
    const componentIndex = this.widgetValues.findIndex(
      (w) => w.component_id === component_id,
    )
    if (component && componentIndex !== -1) {
      component.data = data
      this.$set(this.widgetValues, componentIndex, component)
    }
  }
}
