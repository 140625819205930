import { render, staticRenderFns } from "./refresh.vue?vue&type=template&id=71c730c8&scoped=true&"
var script = {}
import style0 from "./refresh.vue?vue&type=style&index=0&id=71c730c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c730c8",
  null
  
)

export default component.exports