export interface IDocumentSimpleResource {
  id: number
  title: string
  issuer: string
  type: string
  publish_date: string
}

export class DocumentSimpleResource {
  public id: number
  public title: string
  public issuer: string
  public type: string
  public publish_date: string

  constructor(document: IDocumentSimpleResource) {
    this.id = document.id
    this.title = document.title
    this.issuer = document.issuer
    this.type = document.type
    this.publish_date = document.publish_date
  }

}
