




import { Component, Vue, Prop } from 'vue-property-decorator'
import DropdownInput            from '@/components/inputs/Dropdown.vue'

@Component({
  components: {
    DropdownInput
  }
})
export default class Dropdown extends Vue {
  @Prop({ required: false, default: false })
  public readonly multiple!: boolean

  @Prop({ required: true, default: null })
  public readonly value!: string | number | null | Array<string | number | null>

  @Prop({ required: true, default: null })
  public readonly options!: SelectItem[]

  public get formattedValue(): string | number | null | undefined | Array<string | number | null> {
    if (this.multiple) {
      if (this.value && typeof this.value === 'string' && this.value.length > 0) {
        return JSON.parse(this.value)
      } else if (this.value && typeof this.value === 'object' && this.value.length > 0) {
        return this.value
      }

      return []
    }

    return this.value
  }
}
