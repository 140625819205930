import { createSocket } from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'
import { ProjectUserResource, IProjectUserResource } from '@/models/projects/ProjectUserResource'
import { CommentUpdateRequest }                      from '@/requests/comments/CommentUpdateRequest'

import { CommentsService }                                    from '@/services/comments'
import { getHumanReadableDate, getHumanReadableDateWithTime } from '@/helpers/formatDate'
import { Socket }                                             from 'socket.io-client'

export type CommentableType = 'document' | 'process' | 'component' | 'proposal' | 'widget' | 'suggestion' | 'template' | 'document_component' | 'document_widget' | 'document_chapter' | 'document_section' | 'organisation_document' | 'project_chat' | 'implementation_chat' | 'implementation_proposal'
export type CommentPermissions = 'can_delete_comment' | 'can_edit_comment' | 'can_reply' | 'can_like_comment' | 'can_resolve_comment'
export type CommentFilter = 'resolved' | 'unresolved' | null

export const COMMENT_EVENTS = {
  UPDATED: 'comment updated',
  LIKED: 'comment liked',
}


export const isCommentResource = (comment: unknown): comment is ICommentResource => {
  return !!comment && !!(comment as CommentResource).commentable_type
}

export interface ICommentResource {
  readonly id: number
  parentId?: number
  user_info: IProjectUserResource
  commentable_id: number
  commentable_type: CommentableType
  message: string
  status: 'unresolved' | 'resolved' | 'declined' | 'system'
  created_at: string
  updated_at: string
  replies: ICommentResource[]
  permissions: CommentPermissions[]
  likes_count: number
  liked: boolean
  project_id?: number
  entity_id?: number
  channel: string
}

export class CommentResource {
  public readonly id: number
  public parent_id?: number
  public user_info: ProjectUserResource

  public commentable_id: number
  public commentable_type: CommentableType

  public message: string
  public status: 'unresolved' | 'resolved' | 'declined' | 'system'

  public created_at: string
  public updated_at: string

  public replies: CommentResource[] = []
  public permissions: CommentPermissions[]

  public likes_count: number
  public liked: boolean

  public channel: string
  public socket?: Socket

  public project_id?: number
  public entity_id?: number

  private commentsService: CommentsService

  constructor(comment: ICommentResource) {
    this.id = comment.id
    this.parent_id = comment.parentId
    this.user_info = new ProjectUserResource(comment.user_info)

    this.commentable_id = comment.commentable_id
    this.commentable_type = comment.commentable_type

    this.message = comment.message
    this.status = comment.status

    this.created_at = comment.created_at
    this.updated_at = comment.updated_at

    this.replies = comment.replies ? comment.replies.map((reply: ICommentResource) => new CommentResource(reply)) : []
    this.permissions = comment.permissions

    this.likes_count = comment.likes_count
    this.liked = comment.liked

    this.channel = comment.channel

    this.project_id = comment.project_id
    this.entity_id = comment.entity_id

    this.commentsService = new CommentsService()

    this.openSocket()
  }

  public destroy(): void {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public get formattedCreatedAt(): string {
    return getHumanReadableDate(new Date(this.created_at))
  }

  public get formattedCreatedAtWithTime(): string {
    return getHumanReadableDateWithTime(new Date(this.created_at))
  }

  public get canReply(): boolean {
    return !this.parent_id
  }

  public async refresh(): Promise<CommentResource> {
    const { data } = await this.commentsService.get(this.id)
    this.setData(data)
    return this
  }

  public async patch(body: CommentUpdateRequest): Promise<CommentResource> {
    const { data } = await this.commentsService.patch(this.id, body)
    this.setData(data)
    return this
  }

  public async accept(): Promise<CommentResource> {
    return this.updateStatus('resolved')
  }

  public async decline(): Promise<CommentResource> {
    return this.updateStatus('declined')
  }

  public async updateStatus(status: 'declined' | 'resolved'): Promise<CommentResource> {
    const { data } = await this.commentsService.patch(this.id, { message: this.message, status })
    this.setData(data)
    return this
  }

  public async delete(): Promise<CommentResource> {
    await this.commentsService.delete(this.id)
    return this
  }

  public async addReply(message: string): Promise<CommentResource> {
    if (!this.canReply) {
      console.warn('Its not possible to add an reply to a comment which already is an reply')
      return this
    }

    await this.commentsService.post({
      message,
      commentable_id: this.commentable_id,
      commentable_type: this.commentable_type,
      parent_id: this.id,
      status: 'unresolved',
      project_id: this.project_id,
      entity_id: this.entity_id
    })
    await this.refresh()
    return this
  }

  public canPerformAction(key: CommentPermissions): boolean {
    return canPerformAction<CommentPermissions>(this.permissions, key)
  }

  public async like(): Promise<CommentResource> {
    const { data } = await this.commentsService.like(this.id)
    this.setData(data)
    return this
  }

  private setData(comment: CommentResource): void {
    this.message = comment.message
    this.status = comment.status
    this.replies = comment.replies
    this.permissions = comment.permissions
    this.updated_at = comment.updated_at
    this.likes_count = comment.likes_count
    this.liked = comment.liked
  }

  private handleEvent(res: EventResponse): void {
    if (res.event === 'App\\Events\\CommentsEvent' && res.data) {
      switch (res.data.event) {
        case COMMENT_EVENTS.UPDATED:
        case COMMENT_EVENTS.LIKED:
          this.refresh()
          break
      }
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
