









import { Component, Vue, Prop } from 'vue-property-decorator'
import { ProposalResource }     from '@/models/proposals/ProposalResource'

@Component
export default class ProposalDetails extends Vue {

  @Prop()
  private proposal!: ProposalResource

  @Prop({ default: true })
  private readonly readonly!: boolean

}
