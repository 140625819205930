import Vue                                 from 'vue'
import { userModule }                      from '@/store'
import { ROUTES }                          from './routes'
import VueRouter, { Route, RouterOptions } from 'vue-router'
import { parseQuery, stringifyQuery }      from '@/helpers/query'
import { globalRedirect }                  from '@/router/globalRedirect'

Vue.use(VueRouter)

const ROUTER_OPTIONS: RouterOptions = {
  mode: 'history',
  routes: ROUTES,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition): PositionResult {
    if (to.meta?.scrollPage === false) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  parseQuery: (query: string) => {
    return parseQuery(query)
  },
  stringifyQuery: (query: object) => {
    const result = stringifyQuery(query)

    return result ? `?${result}` : ''
  }
}

interface CustomRouteMeta extends Route {
  meta?: RouteMeta
}


const ROUTER: VueRouter = new VueRouter(ROUTER_OPTIONS)


ROUTER.beforeEach(async (to: CustomRouteMeta, from: CustomRouteMeta, next: any) => {
  // If route is authenticated
  if ((to.meta && to.meta.requiresAuth) && !userModule.isLoggedIn) {
    try {
      await userModule.getMe()
    } catch (e) {
      next({ name: 'login', query: { next: to.fullPath } })
    }
  }

  if (process.env.NODE_ENV !== 'development' && (to.meta && to.meta.requiresDev)) {
    next(from.path)
    return
  }

  if (globalRedirect(to)) {
    next(globalRedirect(to))
  } else {
    next()
  }
})

export default ROUTER
