import store, { projectModule } from '@/store/index'
import * as Mutations           from './mutation-types'
import { ProjectsService }                      from '@/services/projects'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProjectResource }           from '@/models/projects/ProjectResource'
import { ProjectUserResource }       from '@/models/projects/ProjectUserResource'

@Module({
  namespaced: true,
  name: 'project',
  store
})
class ProjectModule extends VuexModule {

  // State
  private _detail: ProjectResource | null = null
  private _projectService: ProjectsService = new ProjectsService()

  private _users: ProjectUserResource[] = []

  // Getters
  public get detail(): ProjectResource | null {
    return this._detail
  }

  public get processes(): ProcessResource[] {
    return this.detail?.processes ?? []
  }

  public get getProcessByOrder(): (order: number) => ProcessResource | null {
    return (order: number): ProcessResource | null => this._detail ? this._detail.processes.find((p: ProcessResource) => p.order === order) || null : null
  }

  public get getProcessById(): (id: number) => ProcessResource | null {
    return (id: number): ProcessResource | null => this._detail ? this._detail.processes.find((p: ProcessResource) => p.id === id) || null : null
  }

  public get users() {
    return this._users
  }

  // Actions
  @Action({ rawError: true, commit: Mutations.SET_PROJECT })
  public setProject(project?: ProjectResource): ProjectResource | undefined {
    return project
  }

  @Action({ rawError: true, commit: Mutations.SET_PROJECT })
  public async getById(id: number): Promise<ProjectResource> {
    try {
      const { data } = await this._projectService.getById(id)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SET_USERS })
  public async getUsers() {
    if (!this._detail) return
    try {
      const { data } = await this._detail.getUsers({ pagination: 'false' })
      return data
    } catch (e) {
      console.error(e)
    }
  }


  // Mutations
  @Mutation
  private [Mutations.SET_PROJECT](payload: ProjectResource | null): void {
    this._detail = payload
  }

  @Mutation
  private [Mutations.SET_USERS](users: ProjectUserResource[]): void {
    this._users = users
  }

}

export default ProjectModule
