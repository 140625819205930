


















import orderBy from 'lodash/orderBy'
import {mixin as clickaway} from '@/plugins/vue-clickaway'
import {Component, Vue, Prop, Model} from 'vue-property-decorator'

import {StatusResource} from '@/models/status/StatusResource'
import {FlexibleDocumentStatusResource} from '@/models/status/FlexibleDocumentStatusResource'

import StatusBadge from '@/components/statuses/StatusBadge.vue'

@Component({
  components: {StatusBadge},
  mixins: [clickaway]
})
export default class StatusPicker extends Vue {

  @Model('input')
  private readonly value!: string

  @Prop()
  private readonly statuses!: Array<StatusResource | FlexibleDocumentStatusResource>

  @Prop()
  private readonly deadline?: string

  @Prop({default: false})
  private readonly disabled!: boolean

  @Prop({ default: 'right' })
  private readonly horizontalPosition!: string

  private verticalPosition: 'top' | 'bottom' = 'top'
  private dropdownOpen: boolean = false

  private get transition(): 'slide-in' | 'slide-bottom' {
    return this.verticalPosition === 'top' ? 'slide-in' : 'slide-bottom'
  }

  private get selectedStatus() {
    return this.statuses?.find((status) => status.value === this.value)
  }

  private get orderedStatuses(): Array<StatusResource | FlexibleDocumentStatusResource> {
    return this.statuses ? orderBy(this.statuses, ['order'], ['asc']) : []
  }

  private mounted(): void {
    this.checkViewportPosition()
  }

  private isActive(status: StatusResource) {
    return this.value === status.value
  }

  private setStatus(status: StatusResource) {
    this.$emit('input', status.value)
    this.dropdownOpen = false
  }

  private checkViewportPosition(): void {
    this.verticalPosition = (this.$refs.wrapper as HTMLElement).getClientRects()[0].top > window.innerHeight / 2 ? 'bottom' : 'top'
  }

  private toggleDropdown(): void {
    if (this.disabled) return
    if (!this.dropdownOpen) this.checkViewportPosition()
    this.dropdownOpen = !this.dropdownOpen
  }

  private closeDropdown(): void {
    this.dropdownOpen = false
  }
}
