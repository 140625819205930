import Vue from 'vue'
import { OrganisationDocumentsService } from '@/services/organisationDocuments'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import * as Mutations from './mutation-types'

import { DocumentIndexRequest } from '@/requests/documents/DocumentIndexRequest'
import { DocumentPresetCreateRequest } from '@/requests/documents/DocumentPresetCreateRequest'

import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'
import { OrganisationDocumentPresetResource } from '@/models/organisationDocuments/OrganisationDocumentPresetResource'
import { OrganisationDocumentCollectionResource } from '@/models/organisationDocuments/OrganisationDocumentCollectionResource'
import { OrganisationDocumentFilterResource } from '@/models/organisationDocuments/OrganisationDocumentFilterResource'

const documentsService = new OrganisationDocumentsService()

@Module({
  namespaced: true,
  name: 'organisationDocuments'
})
class OrganisationDocumentsModule extends VuexModule {

  // State
  private _filters: OrganisationDocumentFilterResource[] | null = null
  private _presets: OrganisationDocumentPresetResource[] | null = null
  private _index: IndexResponse<OrganisationDocumentCollectionResource> | null = null
  private _detail: OrganisationDocumentResource | null = null
  private _bookmarked: boolean = false
  private _selectedFilters: DocumentIndexRequest = {}
  private _selectedDocument: OrganisationDocumentCollectionResource | null = null
  private _selectedPreset: number | null = null
  private _markingId: number | null = null
  private _params: IndexParameters = {
    column: 'updated_at',
    dir: 'desc',
    search: '',
    page: '1',
    bookmarked: 'false',
    per_page: '20',
  }

  // Getters
  public get index(): IndexResponse<OrganisationDocumentCollectionResource> | null {
    return this._index
  }

  public get params(): IndexParameters {
    return this._params
  }

  public get selectedFilters(): DocumentIndexRequest {
    return this._selectedFilters
  }

  public get query(): DocumentIndexRequest & IndexParameters {
    // Presets can be found by ID
    if (this._selectedPreset) {
      return { ...this._params, ...{ presets: this._selectedPreset.toString(), search: this._selectedFilters.search }, bookmarked: this.bookmarked.toString() }
    }

    return { ...this._params, ...this._selectedFilters, bookmarked: this.bookmarked.toString() }
  }

  public get filters(): OrganisationDocumentFilterResource[] | null {
    return this._filters
  }

  public get bookmarked(): boolean {
    return this._bookmarked
  }

  public get presets(): OrganisationDocumentPresetResource[] | null {
    return this._presets
  }

  public get detail(): OrganisationDocumentResource | null {
    return this._detail
  }

  public get selectedDocument(): OrganisationDocumentCollectionResource | null {
    return this._selectedDocument
  }

  public get selectedPreset(): number | null {
    return this._selectedPreset
  }

  public get markingId(): number | null {
    return this._markingId
  }

  public get isMarking(): boolean {
    return !!this._markingId
  }

  // Actions
  @Action({ rawError: true, commit: Mutations.SET_DOCUMENTS })
  public async get(query?: DocumentIndexRequest): Promise<IndexResponse<any>> {
    return await documentsService.get({ ...this.query, ...query })
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENTS })
  public removeIndex(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_FILTERS })
  public removeFilters(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENT })
  public async getById(id: number): Promise<any> {
    try {
      const { data } = await documentsService.getById(id)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SELECT_DOCUMENT })
  public selectDocument(document: OrganisationDocumentCollectionResource): OrganisationDocumentCollectionResource {
    return document
  }

  @Action({ rawError: true, commit: Mutations.RESET_SELECTED_DOCUMENTS })
  public resetSelectedDocument(): void {
    return
  }


  @Action({ rawError: true, commit: Mutations.SET_DOCUMENT })
  public removeSingle(): void {
    return
  }

  // PRESETS
  @Action({ rawError: true, commit: Mutations.SET_PRESETS })
  public async getPresets(): Promise<OrganisationDocumentPresetResource[]> {
    try {
      const { data } = await documentsService.presets()
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.ADD_PRESET })
  public async postPreset(preset: DocumentPresetCreateRequest): Promise<OrganisationDocumentPresetResource> {
    try {
      const { data } = await documentsService.postPreset(preset)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.DELETE_PRESET })
  public async deletePreset({ preset }: { preset: OrganisationDocumentPresetResource }): Promise<OrganisationDocumentPresetResource> {
    return preset
  }

  @Action({ rawError: true, commit: Mutations.SET_PRESET })
  public setPreset(key?: number): number | undefined {
    return key
  }

  // FILTERS
  @Action({ rawError: true })
  public async getFilters(query: DocumentIndexRequest): Promise<OrganisationDocumentFilterResource[]> {
    try {
      const { data } = await documentsService.filters()
      this.context.commit(Mutations.SET_FILTERS, data)
      this.context.commit(Mutations.SET_INIT_PARAMS, query)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async updateFilters(key?: string): Promise<void> {
    try {
      const { data } = await documentsService.searchedFilters({ ...this.query }, key)
      this.context.commit(Mutations.UPDATE_FILTERS, data)
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SET_FILTER })
  public setFilter({ key, value }: { key: string, value: string | number | Array<string | number> }): { key: string, value: string | number | Array<string | number> } {
    return { key, value }
  }

  @Action({ rawError: true, commit: Mutations.SET_BOOKMARKED })
  public setBookmarked(payload: boolean): boolean {
    return payload
  }

  @Action({ rawError: true })
  public setOrder({ col, dir }: { col: string, dir: string }): void {
    this.context.commit(Mutations.SET_PARAM, { key: 'column', value: col })
    this.context.commit(Mutations.SET_PARAM, { key: 'dir', value: dir })
  }

  @Action({ rawError: true, commit: Mutations.SET_PARAM })
  public setParam({ key, value }: { key: string, value: string | number | Array<string | number> }): { key: string, value: string | number | Array<string | number> } {
    return { key, value }
  }

  @Action({ rawError: true, commit: Mutations.RESET_FILTERS })
  public resetFilter(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_MARKING_ID })
  public async startMarking(id?: number): Promise<number | undefined> {
    return id
  }

  @Action({ rawError: true, commit: Mutations.RESET_MARKING_ID })
  public async resetMarking(): Promise<null> {
    return null
  }

  @Mutation
  private [Mutations.SET_ORDER]({ col, dir }: { col: string, dir: string }): void {
    this._params.column = col
    this._params.dir = dir
    this._params.page = '1'
  }

  @Mutation
  private [Mutations.SET_INIT_PARAMS](query: DocumentIndexRequest): void {
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        const VALUE = query[key]
        if (key === 'presets') {
          const preset = query[key]
          if (typeof preset === 'string') {
            Vue.set(this, '_selectedPreset', parseInt(preset, 10))
          }
        } else if (key === 'bookmarked') {
          this._bookmarked = query[key] === 'true'
        } else if (key in this._params) {
          Vue.set(this._params, key, typeof VALUE === 'object' && VALUE ? VALUE[0] : VALUE)
        } else {
          const FILTER = this._filters ? this._filters.find((filter: OrganisationDocumentFilterResource) => filter.key === key) : undefined
          if (FILTER) {
            if (FILTER.multiple) {
              if (typeof VALUE === 'object') {
                Vue.set(this._selectedFilters, key, [...VALUE])
              } else {
                if (VALUE && VALUE.indexOf(',') > -1) {
                  const VALUES = VALUE.split(',')
                  VALUES.forEach((value: string) => decodeURIComponent(value))
                  Vue.set(this._selectedFilters, key, [...VALUES])
                } else {
                  Vue.set(this._selectedFilters, key, [VALUE])
                }
              }
            } else {
              // Value can still be an array even if you can only select .
              // E.G. the user can change the url manually
              // So only pick the first value when this happens
              Vue.set(this._selectedFilters, key, typeof VALUE === 'object' ? VALUE[0] : VALUE)
            }
          } else {
            this._selectedFilters[key] = query[key]
          }
        }
      }
    }
  }

  // FILTERS
  @Mutation
  private [Mutations.SET_FILTER]({ key, value }: { key: string, value: string | number | Array<string | number> }): void {
    Vue.set(this._selectedFilters, key, value)
  }

  @Mutation
  private [Mutations.SET_BOOKMARKED](payload: boolean): void {
    this._bookmarked = payload
  }

  @Mutation
  private [Mutations.RESET_FILTERS](): void {
    if (this._filters) {
      for (const FILTER of this._filters) {
        Vue.set(this._selectedFilters, FILTER.key, FILTER.multiple ? [] : '')
      }
    }
  }

  @Mutation
  private [Mutations.SET_PARAM]({ key, value }: { key: string, value: string | number | Array<string | number> }): void {
    Vue.set(this._params, key, value)
  }

  // DOCUMENTS
  @Mutation
  private [Mutations.SET_DOCUMENTS](payload?: IndexResponse<any>): void {
    if (payload) {
      payload.data = payload.data.map((document: any) => new OrganisationDocumentCollectionResource(document))
      this._index = payload
    } else {
      this._index = null
    }
  }

  @Mutation
  private [Mutations.SET_DOCUMENT](payload?: any): void {
    this._detail = payload ? new OrganisationDocumentResource(payload) : null
  }

  @Mutation
  private [Mutations.SELECT_DOCUMENT](payload: OrganisationDocumentCollectionResource): void {
    if (this._selectedDocument?.id === payload.id ) {
      this._selectedDocument = null
    } else {
      this._selectedDocument = payload
    }
  }

  @Mutation
  private [Mutations.RESET_SELECTED_DOCUMENTS](): void {
    this._selectedDocument = null
  }

  // FILTERS
  @Mutation
  private [Mutations.SET_FILTERS](payload?: OrganisationDocumentFilterResource[]): void {
    this._filters = payload || null
    if (payload) {
      for (const FILTER of payload) {
        Vue.set(this._selectedFilters, FILTER.key, FILTER.multiple ? [] : '')
      }
    }
  }

  @Mutation
  private [Mutations.UPDATE_FILTERS](payload?: OrganisationDocumentFilterResource[]): void {
    this._filters = payload || null
  }

  // PRESETS
  @Mutation
  private [Mutations.SET_PRESETS](payload?: OrganisationDocumentPresetResource[]): void {
    this._presets = payload || null
  }

  @Mutation
  private [Mutations.SET_PRESET](payload?: number): void {
    if (payload) {
      this._selectedPreset = payload === this._selectedPreset ? null : payload

      // Set filters with preset
      const PRESET = this._presets ? this._presets.find((preset: OrganisationDocumentPresetResource) => preset.id === payload) : undefined
      if (PRESET && this._selectedPreset) {
        for (const FILTER of PRESET.values) {
          Vue.set(this._selectedFilters, FILTER.key, FILTER.values)
        }
      }
    } else {
      this._selectedPreset = null
    }
  }

  @Mutation
  private [Mutations.ADD_PRESET](payload: OrganisationDocumentPresetResource): void {
    if (this._presets) {
      this._presets.push(payload)
    } else {
      this._presets = [payload]
    }
  }

  @Mutation
  private [Mutations.DELETE_PRESET](payload: OrganisationDocumentPresetResource): void {
    if (this._presets) {
      const ARRAY = this._presets.filter((preset: OrganisationDocumentPresetResource) => preset.id !== payload.id)
      Vue.set(this, '_presets', ARRAY)
    }
  }

  @Mutation
  private [Mutations.SET_MARKING_ID](payload?: number): void {
    this._markingId = payload || null
  }

  @Mutation
  private [Mutations.RESET_MARKING_ID](): void {
    this._markingId = null
  }

}

export default OrganisationDocumentsModule
