import { AxiosPromise } from 'axios'
import { BaseService }  from '@/services/base'

import { CommentResource, ICommentResource } from '@/models/comments/CommentResource'

import { CommentCreateRequest } from '@/requests/comments/CommentCreateRequest'
import { CommentUpdateRequest } from '@/requests/comments/CommentUpdateRequest'

export class CommentsService extends BaseService {

  constructor() {
    super(`comments`)
  }

  /**
   * Create a single comment
   */
  public async post(comment: CommentCreateRequest): Promise<DetailResponse<CommentResource>> {
    const { data } = await this.client.post<DetailResponse<ICommentResource>>(`${this.namespace}`, comment)
    return {
      ...data,
      data: new CommentResource(data.data)
    }
  }

  /**
   * get a single comment
   */
  public async get(commentId: number): Promise<DetailResponse<CommentResource>> {
    const { data } = await this.client.get<DetailResponse<ICommentResource>>(`${this.namespace}/${commentId}`)
    return {
      ...data,
      data: new CommentResource(data.data)
    }
  }

  /**
   * patch a single comment
   */
  public async patch(commentId: number, comment: CommentUpdateRequest): Promise<DetailResponse<CommentResource>> {
    const { data } = await this.client.patch<DetailResponse<ICommentResource>>(`${this.namespace}/${commentId}`, comment)
    return {
      ...data,
      data: new CommentResource(data.data)
    }
  }

  /**
   * Delete a single comment
   */
  public delete(commentId: number): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/${commentId}`)
  }

  /**
   * Like a single comment
   */
  public async like(commentId: number): Promise<DetailResponse<CommentResource>> {
    const { data } = await this.client.post<DetailResponse<ICommentResource>>(`${this.namespace}/${commentId}/like`)
    return {
      ...data,
      data: new CommentResource(data.data)
    }
  }

}
