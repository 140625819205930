
















































import orderBy from 'lodash/orderBy'
import { projectModule } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway.ts'

import Users from '@/components/list/Users.vue'
import UserImage from '@/components/user/UserImage.vue'
import WysiwygInput from '@/components/editors/Wysiwyg.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import StatusButton from '@/components/buttons/StatusButton.vue'

import { StatusResourceCollection } from '@/models/status/StatusResourceCollection'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

@Component({
  mixins: [clickaway],
  components: {
    Users,
    UserPicker,
    UserImage,
    SmallLoader,
    WysiwygInput,
    StatusButton
  }
})
export default class SubmitProposal extends Vue {
  @Prop()
  private readonly title!: string | undefined

  @Prop()
  private readonly buttonText!: string

  @Prop()
  private readonly status!: StatusResourceCollection | undefined

  @Prop()
  private readonly preselectedUsers!: number[] | null

  @Prop()
  private readonly statusAction!: string

  @Prop({default: false})
  private readonly loading!: boolean

  // Data
  private form: { message?: string; userIds?: number[] } = {
    message: '',
    userIds: []
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get selectedUsers() {
    return this.form.userIds?.map((id) => this.projectUsers.find((user) => user.id === id)) ?? []
  }

  private get canSubmit(): boolean {
    if (this.needSubstantiation && !this.substantiationDone) {
      return false
    }
    if (this.needAllocation && !this.allocationDone) {
      return false
    }
    return true
  }

  private get needSubstantiation() {
    return this.status?.substantiation ?? false
  }

  private get substantiationDone(): boolean {
    return  !this.messageIsEmpty
  }

  private get needAllocation(): boolean {
    return this.status?.need_allocation ?? false
  }

  private get allocationDone(): boolean {
    return (this.form.userIds?.length ?? 0) > 0
  }

  private get messageIsEmpty(): boolean {
    return this.form.message === '' || this.form.message === '<p><br></p>' || this.form.message === '<div><br></div>' || this.form.message?.length === 0
  }

  private get alteredButtonText(): string {
    if (this.buttonText) {
      if (this.buttonText.toLowerCase() === 'redefine') {
        return 'Define'
      }

      return this.buttonText
    }

    return 'Submit'
  }

  private get alteredTitle(): string {
    if (this.title) {
      if (this.title.includes('Redefine')) {
        return this.title.replace('Redefine', 'Define')
      }
      return this.title
    }

    return 'Submit'
  }

  private get orderedUsers(): ProjectUserResource[] {
    if (this.projectUsers) {
      return orderBy(this.projectUsers, ['first_name'], ['asc'])
    }

    return []
  }

  private created() {
    if (this.needAllocation && this.preselectedUsers) {
      this.form.userIds?.push(...this.preselectedUsers)
    }
  }

  private close(): void {
    this.$emit('close')
  }

  private async submit(): Promise<void> {
    const form = {...this.form}
    if (!this.needAllocation) {
      delete this.form.userIds
    }
    if (!this.needSubstantiation || form.message?.length === 0) {
      delete form.message
    }
    this.$emit('submitted', form)
  }
}
