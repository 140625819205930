import { NotificationResource } from '@/models/notifications/NotificationResource'
import { ImplementationService } from '@/services/implementation'
import { ProjectService } from '@/services/project'
import { UserService } from '@/services/user'

export class NotificationsController {
  public namespace: 'activities' | 'notifications'
  public service: ProjectService | ImplementationService | UserService

  public loadingInitialData: boolean = false
  public data: IndexResponse<NotificationResource> | null = null

  constructor({ service, namespace }: { service: ProjectService | ImplementationService | UserService, namespace: 'activities' | 'notifications' }) {
    this.namespace = namespace
    this.service = service
    this.get()
  }

  public get items(): NotificationResource[] {
    return this.data ? this.data.data : []
  }

  public get hasData(): boolean {
    return this.items.length > 0
  }

  public get hasUnreadNotifications(): boolean {
    return this.data ? this.data.data.some((n) => n.new) : false
  }

  public get unreadNotificationCount(): number {
    return this.data ? this.data.data.filter((n) => n.new).length : 0
  }

  public get groupedNotifications(): Array<{ date: string, notifications: NotificationResource[] }> {
    let groups: { [key: string]: NotificationResource[] } = {}
    groups = this.items.reduce((groupsArray, notification) => {
      const date = notification.date
      if (!groupsArray[date]) {
        groupsArray[date] = []
      }
      groupsArray[date].push(notification)
      return groupsArray
    }, groups)
    return Object.keys(groups).map((date) => {
      return {
        date,
        notifications: groups[date]
      }
    })
  }

  public async get(): Promise<void> {
    this.loadingInitialData = true
    try {
      this.data = await this.service.getNotifications(this.namespace)
    } catch (e) {
      console.error('Fetch notifications error, probably incorrect namespace provided', e)
    } finally {
      this.loadingInitialData = false
    }
  }

  public async refresh(): Promise<void> {
    try {
      this.data = await this.service.getNotifications(this.namespace)
    } catch (e) {
      console.error('Fetch notifications error, probably incorrect namespace provided', e)
    }
  }

  public async readAllNotifications(): Promise<void> {
    // Nothing to read if we have no notifications
    if (!this.data) return
    try {
      await this.service.readNotifications(this.namespace)
      // Set all notifications to read
      for (const notification of this.data.data) {
        notification.new = false
      }
    } catch (e) {
      console.error('Read notifications error, probably incorrect namespace provided', e)
    }
  }
}
