



























import orderBy from 'lodash/orderBy'
import {Vue, Prop, Component, Model, Watch} from 'vue-property-decorator'

import CheckboxInput from '@/components/inputs/Checkbox.vue'

@Component({
  name: 'MultiLayerDropdown',
  components: {
    CheckboxInput
  }
})
export default class MultiLayerDropdown extends Vue {

  @Model('input')
  private readonly value!: string | number | undefined | null | Array<string | number | undefined | null>

  @Prop()
  private option!: SelectItem

  @Prop()
  private multiple!: boolean

  @Prop()
  private parent!: SelectItem | undefined

  @Prop()
  private parentSelected!: boolean

  // @Prop()
  // private search!: string

  private isFoldOpen: boolean = false

  @Watch('search')
  private onSearchChange(val: string): void {
    if (val.length > 0) {
      this.isFoldOpen = true
    } else {
      this.isFoldOpen = false
    }
  }

  private created(): void {
    if (this.hasSelectedOptions || this.isSelected) {
      this.isFoldOpen = true
    }
  }

  private get optionsCount(): number {
    return this.option.options?.length ?? 0
  }

  private get hasOptions(): boolean {
    return this.optionsCount > 0
  }

  private get label(): string {
    return this.option.count ? `${this.option.label} (${this.option.count})` : this.option.label
  }

  private get hasSelectedOptions(): boolean {
    if (!this.hasOptions) return false
    if (Array.isArray(this.value)) {
      return this.value.some((val) => this.option.options?.some((option) => option.value === val) ?? false)
    } else {
      return this.option.options?.some((option) => option.value === this.value) ?? false
    }
  }

  private get isSelected(): boolean {
    if (Array.isArray(this.value)) {
      return this.value.includes(this.option.value)
    } else {
      return this.value === this.option.value
    }
  }

  private get checkboxComponent(): string {
    if (this.isSelected || this.parentSelected) {
      return 'selectedBoxIcon'
    } else if (this.hasSelectedOptions) {
      return 'halfSelectedBoxIcon'
    } else {
      return 'selectBoxIcon'
    }
  }

  private get options(): SelectItem[] {
    return this.option.options ?? []
  }

  private toggleFold(): void {
    this.isFoldOpen = !this.isFoldOpen
  }

  private selectOption({ option, parent }: { option: SelectItem, parent?: SelectItem }): void {
    this.$emit('input', { option, parent })
  }

}
