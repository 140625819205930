













import { Component, Vue, Prop, Watch }            from 'vue-property-decorator'

import NoItems      from '@/components/partials/NoItems.vue'
import SmallLoader  from '@/components/loaders/SmallLoader.vue'
import AuditComment from '@/components/comments/AuditComment.vue'

import { ProposalResource }    from '@/models/proposals/ProposalResource'

// component
@Component({
  components: {
    NoItems,
    SmallLoader,
    AuditComment
  }
})
export default class ProposalAudit extends Vue {

  @Prop()
  private readonly proposal!: ProposalResource

  @Watch('proposal.audits.data.length')
  private onCommentsLengthChange(): void {
    this.$nextTick(() => {
      this.scrollBottom()
    })
  }

  private created(): void {
    this.scrollBottom()
  }

  private scrollBottom(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    wrapper.scrollTop = wrapper.scrollHeight
  }
}
