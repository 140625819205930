import { createSocket } from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'
import { isIFlexibleDocumentSectionResource } from '@/helpers/FlexibleDocument'
import { CommentsController }                 from '@/controllers/comments/CommentsController'

import { AuditsResource }                                                              from '@/models/audits/AuditsResource'
import { IProjectUserResource, ProjectUserResource }                                   from '@/models/projects/ProjectUserResource'
import { ELEMENT_EVENTS, FlexibleDocumentItemResource, IFlexibleDocumentItemResource } from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { Socket }                                                                      from 'socket.io-client'

type FlexibleDocumentSectionPermissions = 'can_comment' | 'can_edit' | 'can_delete'

export interface IFlexibleDocumentSectionResource extends IFlexibleDocumentItemResource {
  include_in_toc: boolean

  permissions: FlexibleDocumentSectionPermissions[]
  actor?: IProjectUserResource
  defined_by: IProjectUserResource
  comment_count: number
  channel: string
}

export class FlexibleDocumentSectionResource extends FlexibleDocumentItemResource {

  public include_in_toc: boolean

  public permissions: FlexibleDocumentSectionPermissions[]
  public actor?: ProjectUserResource
  public defined_by: ProjectUserResource
  public comment_count: number

  public comments: CommentsController
  public audits: AuditsResource

  public channel: string
  public socket?: Socket

  constructor({ include_in_toc, permissions, actor, defined_by, comment_count, channel, ...chapter }: IFlexibleDocumentSectionResource, project_id: number) {
    super(chapter, project_id)
    this.include_in_toc = include_in_toc

    this.permissions = permissions
    this.defined_by = defined_by ? new ProjectUserResource(defined_by) : defined_by
    this.actor = actor ? new ProjectUserResource(actor) : actor
    this.comment_count = comment_count
    this.channel = channel

    this.comments = new CommentsController({ id: chapter.uuid, service: this.service, commentableType: 'document_section', project_id })
    this.audits = new AuditsResource({ service: this.service })

    this.project_id = project_id

    this.openSocket()
  }

  public destroy() {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public get heading(): string {
    return this.title || 'Section'
  }

  public canPerformAction(key: FlexibleDocumentSectionPermissions): boolean {
    return canPerformAction<FlexibleDocumentSectionPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<FlexibleDocumentSectionResource> {
    this.updateData(await this.service.refresh())
    return this
  }

  private updateData(data: IFlexibleDocumentItemResource): void {
    if (!isIFlexibleDocumentSectionResource(data)) return
    this.order = data.order
    this.title = data.title
    this.permissions = data.permissions
    this.actor = data.actor ? new ProjectUserResource(data.actor) : data.actor
    this.comment_count = data.comment_count
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case ELEMENT_EVENTS.UPDATED:
        this.refresh().then()
        break
      case ELEMENT_EVENTS.COMMENT_CREATED:
      case ELEMENT_EVENTS.COMMENT_DELETED:
        this.comments.get().then()
        break
    }
    // always fetch audits
    this.audits.get().then()
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
