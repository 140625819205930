import { StatusResource, IStatusResource } from '@/models/status/StatusResource'
import { ProposalService }                 from '@/services/proposal'

export interface IProposalSummaryCollectionResource {
  id: number
  proposal_name: string
  description: string
  status: IStatusResource
  project_id: number
  process_id: number
  proposal_value_overview: string
  inherited?: boolean
  connected_ids: number[]
  created_at?: string
  channel?: string
}

export class ProposalSummaryCollectionResource implements IProposalSummaryCollectionResource {
  public id: number
  public proposal_name: string
  public description: string
  public status: StatusResource
  public project_id: number
  public process_id: number
  public proposal_value_overview: string
  public inherited?: boolean
  public connected_ids: number[]
  public created_at?: string

  public formatted_created_at: Date

  public proposalService: ProposalService

  constructor(proposal: IProposalSummaryCollectionResource) {
    this.id = proposal.id
    this.proposal_name = proposal.proposal_name
    this.description = proposal.description
    this.status = new StatusResource(proposal.status)
    this.project_id = proposal.project_id
    this.process_id = proposal.process_id
    this.proposal_value_overview = proposal.proposal_value_overview
    this.inherited = proposal.inherited
    this.connected_ids = proposal.connected_ids
    this.created_at = proposal.created_at
    this.formatted_created_at = new Date(proposal.created_at ?? '')

    this.proposalService = new ProposalService({ project_id: proposal.project_id, process_id: proposal.process_id, proposal_id: proposal.id })
  }

}
