export interface IOrganisationDocumentFilterResource {
  key: string
  label: string
  placeholder?: string
  type: string
  multiple?: boolean
  options?: SelectItem[]
}

export class OrganisationDocumentFilterResource {
  public key: string
  public label: string
  public placeholder?: string
  public type: string
  public multiple?: boolean
  public options?: SelectItem[]

  constructor({ key, label, placeholder, type, multiple, options }: IOrganisationDocumentFilterResource) {
    this.key = key
    this.label = label
    this.placeholder = placeholder
    this.type = type
    this.multiple = multiple
    this.options = options
  }
}
