import { render, staticRenderFns } from "./TableContentsItem.vue?vue&type=template&id=79d4c7a3&scoped=true&"
import script from "./TableContentsItem.vue?vue&type=script&lang=ts&"
export * from "./TableContentsItem.vue?vue&type=script&lang=ts&"
import style0 from "./TableContentsItem.vue?vue&type=style&index=0&id=79d4c7a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d4c7a3",
  null
  
)

export default component.exports