import { BaseService } from '@/services/base'

import { convertToFlexibleDocumentCollectionItem, convertToFlexibleDocumentItem } from '@/helpers/FlexibleDocument'

import { FlexibleDocumentResource, IFlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentItemResource }                        from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollectionResource }              from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'
import {
  FlexibleDocumentDropdownOptionResource,
  IFlexibleDocumentDropdownOptionResource
}                                                              from '@/models/flexibleDocument/FlexibleDocumentDropdownOptionResource'
import { FlexibleDocumentComponentCollectionResource }         from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'
import {
  FlexibleDocumentMetaSectionResource,
  IFlexibleDocumentMetaSectionResource
}                                                              from '@/models/flexibleDocument/FlexibleDocumentMetaSectionResource'
import {
  FlexibleDocumentMetaResource,
  IFlexibleDocumentMetaResource
}                                                              from '@/models/flexibleDocument/flexibleDocumentMetaResource'

import { FlexibleDocumentUpdateRequest }               from '@/requests/flexibleDocument/FlexibleDocumentUpdateRequest'
import { FlexibleDocumentCreateItemRequest }           from '@/requests/flexibleDocument/FlexibleDocumentCreateItemRequest'
import { FlexibleDocumentCreateTemplateRequest }       from '@/requests/flexibleDocument/FlexibleDocumentCreateTemplateRequest'
import { FlexibleDocumentUpdateComponentRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateComponentRequest'
import { FlexibleDocumentUpdateItemOrderRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { FlexibleDocumentMetaPatchRequest }            from '@/requests/flexibleDocument/FlexibleDocumentMetaPatchRequest'
import { FlexibleDocumentUpdateExportSettingsRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateExportSettingsRequest'
import { CommentResource, ICommentResource }           from '@/models/comments/CommentResource'
import { AuditResource, IAuditResource }               from '@/models/audits/AuditResource'
import { FlexibleDocumentUpdateStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentUpdateStatusRequest'
import { FlexibleDocumentReviewStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentReviewStatusRequest'

export class FlexibleDocumentService extends BaseService {

  private readonly project_id: number

  constructor({ project_id }: { project_id: number }) {
    super(`projects/${project_id}/flexible_document`)
    this.project_id = project_id
  }

  public async create({ body }: { body: FlexibleDocumentCreateTemplateRequest }): Promise<DetailResponse<FlexibleDocumentResource>> {
    const { data } = await this.client.post<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/create`, body)
    return {
      ...data,
      data: new FlexibleDocumentResource(data.data, this.project_id)
    }
  }

  public async get(): Promise<DetailResponse<FlexibleDocumentResource>> {
    const { data } = await this.client.get<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}`)
    return {
      ...data,
      data: new FlexibleDocumentResource(data.data, this.project_id)
    }
  }

  public async patch({ uuid, body }: { uuid: string, body: FlexibleDocumentUpdateRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/${uuid}`, body)
    return data
  }

  public async updateExportSettings({ blacklist: elements }: FlexibleDocumentUpdateExportSettingsRequest): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.post<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/black_list`, { elements })
    return data
  }

  public async patchStatus({ body }: { body: FlexibleDocumentUpdateStatusRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/status`, body.getRequestData())
    return data
  }

  public async reviewStatus({ body }: { body: FlexibleDocumentReviewStatusRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/status/feedback`, body)
    return data
  }

  public async getElement(uuid: string): Promise<DetailResponse<FlexibleDocumentItemResource>> {
    const { data } = await this.client.get<DetailResponse<FlexibleDocumentItemResource>>(`${this.namespace}/${uuid}`)
    data.data = convertToFlexibleDocumentItem(data.data, this.project_id)
    return data
  }

  public async patchComponent({
                                uuid,
                                body
                              }: { uuid: string, body: FlexibleDocumentUpdateComponentRequest }): Promise<DetailResponse<FlexibleDocumentComponentCollectionResource>> {
    const { data } = await this.client.patch<DetailResponse<FlexibleDocumentComponentCollectionResource>>(`${this.namespace}/${uuid}`, body)
    return data
  }

  public async patchElementOrder({ body }: { body: FlexibleDocumentUpdateItemOrderRequest }): Promise<FlexibleDocumentItemCollectionResource[]> {
    const { data } = await this.client.patch<FlexibleDocumentItemCollectionResource[]>(`${this.namespace}/chapter`, body)
    return data.map((item) => convertToFlexibleDocumentCollectionItem(item, this.project_id))
  }

  public async postElement({ body }: { body: FlexibleDocumentCreateItemRequest }): Promise<DetailResponse<FlexibleDocumentItemCollectionResource>> {
    const { data } = await this.client.post<DetailResponse<FlexibleDocumentItemCollectionResource>>(`${this.namespace}`, body)
    data.data = convertToFlexibleDocumentCollectionItem(data.data, this.project_id)
    return data
  }

  public async getDropdownOptions(): Promise<DetailResponse<FlexibleDocumentDropdownOptionResource[]>> {
    const { data: elements } = await this.client.get(`${this.namespace}/elements`)
    return { data: elements.data.map((item: IFlexibleDocumentDropdownOptionResource) => new FlexibleDocumentDropdownOptionResource(item)) }
  }

  public async uploadFile(form: FormData): Promise<DetailResponse<{ image_url: string }>> {
    const { data } = await this.client.post(`${this.namespace}/upload`, form)
    return data
  }

  public async getMeta(): Promise<IndexResponse<FlexibleDocumentMetaSectionResource>> {
    const { data } = await this.client.get<IndexResponse<IFlexibleDocumentMetaSectionResource>>(`${this.namespace}/meta`)
    return {
      ...data,
      data: data.data.map((s: IFlexibleDocumentMetaSectionResource) => new FlexibleDocumentMetaSectionResource(s))
    }
  }

  public async patchMeta({ form }: { form: FlexibleDocumentMetaPatchRequest }): Promise<DetailResponse<FlexibleDocumentMetaResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentMetaResource>>(`${this.namespace}/meta`, form)
    return {
      ...data,
      data: new FlexibleDocumentMetaResource(data.data)
    }
  }

  public async getComments(params?: Dictionary<any>): Promise<IndexResponse<CommentResource>> {
    const { data } = await this.client.get(`${this.namespace}/comments`, { params })
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async getAudit(): Promise<IndexResponse<AuditResource>> {
    const { data } = await this.client.get(`${this.namespace}/audit`)
    return {
      ...data,
      data: data.data.map((audit: IAuditResource) => new AuditResource(audit))
    }
  }

}
