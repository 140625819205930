import { TasksService } from '@/services/tasks'

import { TaskCreateRequest } from '@/requests/tasks/TaskCreateRequest'
import { TaskCollectionResource } from '@/models/tasks/TaskCollectionResource'

export class TasksController {
  public archived: boolean
  public project_id?: number

  public loadingInitialData: boolean = false
  public loadingNextData: boolean = false
  public data: IndexResponse<TaskCollectionResource> | null = null
  public indexParameters: IndexParameters = {
    page: '1',
    order_by: 'desc',
    per_page: '300'
  }

  public service: TasksService = new TasksService()

  constructor({ archived, project_id }: { archived: boolean, project_id?: number }) {
    this.archived = archived
    this.project_id = project_id

    this.get()
  }

  public get items(): TaskCollectionResource[] {
    return this.data ? this.data.data : []
  }

  public get hasData(): boolean {
    return this.items.length > 0
  }

  public get openTasksCount(): number {
    return this.data?.data.filter((task) => {
      return task.active
    }).length ?? 0
  }

  public get lastPageReached(): boolean {
    return this.data?.pagination?.current_page === this.data?.pagination?.last_page
  }

  public get hasMoreThenOnePage(): boolean {
    return (this.data?.pagination?.last_page ?? 0) > 1
  }

  public async get(): Promise<void> {
    this.loadingInitialData = true
    this.data = await this.getData()
    this.loadingInitialData = false
  }

  public async getNextTasks(): Promise<void> {
    // If end of list we wont fetch new items
    if (this.lastPageReached) return
    // If there is no data something probably went wrong already
    if (!this.data) return

    this.loadingNextData = true
    // convert string page to INT increase page with one and convert page to string
    this.indexParameters.page = (+(this.indexParameters?.page ?? '1') + 1).toString()
    const data = await this.getData()
    this.data.pagination = data.pagination
    this.data.data.push(...data.data)
    this.loadingNextData = false
  }

  public async createTask(task: TaskCreateRequest): Promise<TaskCollectionResource> {
    const { data } = await this.service.create(task)
    this.data?.data.unshift(data)
    return data
  }

  public deleteTask(taskId: number) {
    const TASK_INDEX = this.data?.data.findIndex((task) => task.id === taskId)
    if (TASK_INDEX !== undefined && TASK_INDEX > -1) {
      this.data?.data.splice(TASK_INDEX, 1)
    }
  }

  private async getData(): Promise<IndexResponse<TaskCollectionResource>> {
    return await this.service.get({ archived: this.archived, project_id: this.project_id, ...this.indexParameters })
  }

}
