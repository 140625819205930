import { API }                         from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'
import { ProjectDocumentStoreRequest } from '@/requests/projects/ProjectDocumentStoreRequest'
import { ProjectPermissions }          from '@/models/projects/ProjectResource'
import { ProjectService }              from '@/services/project'

export interface IProjectCollectionResource {
  id: number
  name: string
  updated_at: string
  created_at: string
  owner: string
  new: string
  end_date: string
  status: string
  project_type: string
  permissions: ProjectPermissions[]
}

export class ProjectCollectionResource {
  public id: number
  public name: string
  public updated_at: string
  public created_at: string
  public owner: string
  public new: string
  public status: string
  public end_date: string
  public project_type: string
  public permissions: ProjectPermissions[]

  private service: ProjectService

  constructor({ id, name, updated_at, created_at, owner, new: isNew, permissions, end_date, status, project_type }: IProjectCollectionResource) {
    this.id = id
    this.name = name
    this.updated_at = updated_at
    this.created_at = created_at
    this.owner = owner
    this.new = isNew
    this.end_date = end_date ?? ''
    this.permissions = permissions
    this.status = status
    this.project_type = project_type

    this.service = new ProjectService({ project_id: id })
  }

  public async linkDocuments(body: ProjectDocumentStoreRequest): Promise<ProjectCollectionResource> {
    await API.post(`projects/${this.id}/documents`, body)
    return this
  }

  public canPerformAction(key: ProjectPermissions): boolean {
    return canPerformAction<ProjectPermissions>(this.permissions, key)
  }

  public async archive(): Promise<void> {
    return this.service.archive()
  }
}
