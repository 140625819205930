import { render, staticRenderFns } from "./suggestion.vue?vue&type=template&id=416467a3&scoped=true&"
var script = {}
import style0 from "./suggestion.vue?vue&type=style&index=0&id=416467a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416467a3",
  null
  
)

export default component.exports