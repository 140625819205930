























































import { Vue, Component, Prop, Model } from 'vue-property-decorator'

import { ProcessResource }            from '@/models/process/ProcessResource'
import { ProposalResource }           from '@/models/proposals/ProposalResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

import Modal          from '@/components/widgets/Modal.vue'
import ActionBar      from '@/components/widgets/ActionBar.vue'
import NoItems        from '@/components/partials/NoItems.vue'
import ProposalsList  from '@/components/project/ProposalsList.vue'
import PanelSplit     from '@/components/widgets/PanelSplit.vue'
import SmallLoader    from '@/components/loaders/SmallLoader.vue'
import DetailsHeader  from '@/components/header/DetailsHeader.vue'

@Component({
  components: {
    Modal,
    NoItems,
    ActionBar,
    PanelSplit,
    SmallLoader,
    DetailsHeader,
    ProposalsList,
  }
})
export default class SimilarityModal extends Vue {

  @Model('update-selected')
  private readonly selectedId!: number

  @Prop()
  private process!: ProcessResource

  @Prop()
  private proposal!: ProposalCollectionResource

  @Prop()
  private proposals!: ProposalCollectionResource[]

  private loading: boolean = false
  private currentSelectedProposal: ProposalResource | null = null


  private get namespace(): string {
    return this.process ? this.process.process_name : 'proposals'
  }

  private async viewProposal(proposal: ProposalCollectionResource): Promise<void> {
    if (this.currentSelectedProposal && this.currentSelectedProposal.id === proposal.id) return

    this.loading = true
    try {
      const { data } = await this.process.getProposal(proposal.id, proposal.similarity_id)
      this.$set(this, 'currentSelectedProposal', data)
    } finally {
      this.loading = false
    }
  }

  private setSelected(id: number): void {
    this.$emit('update-selected', id)
  }

  private close(): void {
    this.$emit('close')
  }

}
