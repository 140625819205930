







































import { Route } from 'vue-router'
import { userModule, projectModule } from '@/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import Suggestion from '@/components/suggestions/Suggestion.vue'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { ProposalResource } from '@/models/proposals/ProposalResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { SuggestionResource } from '@/models/suggestions/SuggestionResource'

type dubRoute = Route

// component
@Component({
  components: {
    Suggestion,
    SmallLoader,
  },
})
export default class ProposalSuggestions extends Vue {
  @Prop()
  private readonly suggestionFilter!: string | null

  @Prop()
  private readonly proposal!: ProposalResource

  @Prop({ default: true })
  private readonly readonly!: boolean

  @Watch('proposal.suggestions.data.length')
  private onSuggestionLengthChange(): void {
    this.$nextTick(() => {
      this.scrollBottom()
    })
  }

  private get filteredSuggestions(): SuggestionResource[] {
    if (this.suggestionFilter) {
      return this.proposal.suggestions.items.filter((c) => {
        if (this.suggestionFilter === 'resolved') {
          return c.status === 'approved' || c.status === 'declined'
        }
        return c.status === this.suggestionFilter
      })
    }

    return this.proposal.suggestions.items
  }

  private get users(): ProjectUserResource[] {
    return projectModule.users
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private async created(): Promise<void> {
    this.scrollBottom()
  }

  private async getSuggestions(): Promise<void> {
    try {
      await this.proposal.suggestions.get()
    } catch (e) {
      console.error(e)
    }
  }

  private deleteSuggestion(id: number): void {
    this.proposal.suggestions.deleteSuggestion(id)
  }

  private refresh(): void {
    this.getSuggestions()
    this.$emit('refresh')
  }

  private scrollTop(): void {
    const wrapperDiv = this.$refs.wrapper as HTMLElement
    if (!wrapperDiv) return
    wrapperDiv.scrollTop = 0
  }

  private scrollBottom(): void {
    const wrapperDiv = this.$refs.wrapper as HTMLElement
    if (!wrapperDiv) return
    wrapperDiv.scrollTop = wrapperDiv.scrollHeight
  }
}
