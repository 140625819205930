import { AxiosPromise, AxiosResponse } from 'axios'
import { BaseService }                 from '@/services/base'

import { UserResource, IUserResource }                 from '@/models/users/UserResource'
import { AuthUserResource, IAuthUserResource }         from '@/models/users/AuthUserResource'
import { NotificationResource, INotificationResource } from '@/models/notifications/NotificationResource'

import { ChangePasswordRequest } from '@/requests/auth/ChangePasswordRequest'
import { FeedbackRequest } from '@/requests/misc/FeedbackRequest'

export class GlobalService extends BaseService {
  constructor() {
    super('')
  }

  /**
   * Get all users
   */
  public async getVersion(): Promise<{ data: { channel: string, version: string } }> {
    const { data } = await this.client.get(`${this.namespace}/version`)
    return data
  }

  public async feedback(body: FeedbackRequest): Promise<AxiosResponse<void>> {
    return await this.client.post(`${this.namespace}/feedback`, body.requestBody())
  }
}
