
























import { projectModule, userModule } from '@/store'
import { Component, Vue, Prop, Watch }            from 'vue-property-decorator'
import { canPerformAction }                       from '@/helpers/canPerformAction'

import NoItems      from '@/components/partials/NoItems.vue'
import AddComment   from '@/components/comments/AddComment.vue'
import SmallLoader  from '@/components/loaders/SmallLoader.vue'

import { AuthUserResource }    from '@/models/users/AuthUserResource'
import { CommentResource }     from '@/models/comments/CommentResource'
import { ProposalResource }    from '@/models/proposals/ProposalResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

// component
@Component({
  components: {
    NoItems,
    AddComment,
    SmallLoader,
  }
})
export default class ProposalComments extends Vue {

  @Prop()
  private readonly commentFilter!: string | null

  @Prop()
  private readonly proposal!: ProposalResource

  @Prop({ default: true })
  private readonly readonly!: boolean

  private resetMessage: boolean = false

  @Watch('proposal.id')
  private onProposalIdChange(id: number, oldId: number) {
    if (id === oldId) return
    // This will trigger a watch event inside AddComment to reset message inside the AddComment component
    this.resetMessage = true
    this.$nextTick(() => {
      this.resetMessage = false
    })
  }

  @Watch('proposal.comments.data.length')
  private onProposalChange(val: number, oldVal: number): void {
    this.$nextTick(() => {
      this.scrollBottom()
    })
  }

  private get canComment(): boolean {
    return canPerformAction(this.proposal.permissions, 'can_comment') && !this.readonly
  }

  private get filteredComments(): CommentResource[] {
    if (this.commentFilter) {
      return this.proposal.comments.items.filter((c) => {
        if (this.commentFilter === 'resolved') {
          return c.status === 'resolved' || c.status === 'declined'
        }
        return c.status === this.commentFilter
      })
    }

    return this.proposal.comments.items
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private get users(): ProjectUserResource[] {
    return projectModule.users
  }

  private created(): void {
    this.scrollBottom()
  }

  private deleteComment(id: number): void {
    this.proposal.comments.deleteComment(id)
  }

  private async getComments(): Promise<void> {

    try {
      await this.proposal.comments.get()
      this.scrollBottom()
    } catch (e) {
      console.error(e)
    }
  }

  private async addComment(message: string): Promise<void> {
    this.proposal.comments.loading = true
    try {
      await this.proposal.comments.post(message)
      this.$emit('refresh')
      this.scrollBottom()
    } finally {
      this.proposal.comments.loading = false
    }
  }

  private scrollBottom(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    wrapper.scrollTop = wrapper.scrollHeight
  }
}
