import { render, staticRenderFns } from "./SelectProcessContextMenu.vue?vue&type=template&id=488b0b86&scoped=true&"
import script from "./SelectProcessContextMenu.vue?vue&type=script&lang=ts&"
export * from "./SelectProcessContextMenu.vue?vue&type=script&lang=ts&"
import style0 from "./SelectProcessContextMenu.vue?vue&type=style&index=0&id=488b0b86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488b0b86",
  null
  
)

export default component.exports