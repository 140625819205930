















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

interface SVGAnimationElement extends Element {
    beginElement(): SVGAnimationElement
}

@Component
export default class CheckmarkCross extends Vue {

  @Prop()
  private isChecked!: boolean

  @Watch('isChecked', { immediate: true })
  private onIsCheckedChange(val: boolean) {
    const tick = this.$refs.tick as SVGAnimationElement
    const cross = this.$refs.cross as SVGAnimationElement

    if (!tick || !cross) return

    if (this.isChecked) {
      tick.beginElement()
    } else {
      cross.beginElement()
    }
  }

}
