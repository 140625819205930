import { render, staticRenderFns } from "./superscript.vue?vue&type=template&id=701fa792&scoped=true&"
var script = {}
import style0 from "./superscript.vue?vue&type=style&index=0&id=701fa792&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701fa792",
  null
  
)

export default component.exports