export interface IDocumentExportByPresetRequest {
  preset_ids: number[]
  from_date?: string
}

export class DocumentExportByPresetRequest implements IDocumentExportByPresetRequest {
  public preset_ids: number[] = []
  public from_date?: string

  constructor(data: IDocumentExportByPresetRequest) {
    this.preset_ids = data.preset_ids ?? []
    this.from_date = data.from_date
  }
}
