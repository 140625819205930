import { BaseService }  from '@/services/base'
import { AxiosPromise } from 'axios'

import { AuditResource, IAuditResource }   from '@/models/audits/AuditResource'
import { CommentResource, ICommentResource } from '@/models/comments/CommentResource'
import { ProposalResource, IProposalResource }                       from '@/models/proposals/ProposalResource'
import { IProposalCollectionResource }   from '@/models/proposals/ProposalCollectionResource'
import { ProposalConnectionsResource, IProposalConnectionsResource } from '@/models/proposals/ProposalConnectionsResource'

import { ProposalUpdateRequest }                   from '@/requests/proposals/ProposalUpdateRequest'
import { SuggestionRequest }                       from '@/requests/suggestions/SuggestionRequest'
import { ProposalStatusPatchRequest }              from '@/requests/proposals/ProposalStatusPatchRequest'
import { ProposalPatchConnectionsRequest }         from '@/requests/proposals/ProposalPatchConnectionsRequest'
import { ISuggestionResource, SuggestionResource } from '@/models/suggestions/SuggestionResource'
import { ProposalAllocateUsersRequest }            from '@/requests/proposals/ProposalAllocateUsersRequest'

export class ProposalService extends BaseService {

  public project_id: number
  public process_id: number
  public proposal_id: number

  constructor({ project_id, process_id, proposal_id }: { project_id: number, process_id: number, proposal_id: number }) {
    super(`projects/${project_id}/processes/${process_id}/proposals/${proposal_id}`)
    this.project_id = project_id
    this.process_id = process_id
    this.proposal_id = proposal_id
  }

  public async get(params?: Dictionary<any>): Promise<DetailResponse<ProposalResource>> {
    const { data } = await this.client.get<DetailResponse<IProposalResource>>(`${this.namespace}`, { params })
    return {
      ...data,
      data: new ProposalResource(data.data)
    }
  }

  public async refresh(params?: Dictionary<any>): Promise<DetailResponse<IProposalResource>> {
    const { data } = await this.client.get<DetailResponse<IProposalResource>>(`${this.namespace}`, { params })
    return data
  }

  public async patchStatus(proposal: ProposalStatusPatchRequest): Promise<DetailResponse<IProposalResource>> {
    const { data } = await this.client.patch<DetailResponse<IProposalResource>>(`${this.namespace}/status`, proposal)
    return data
  }

  public async patch(body: ProposalUpdateRequest): Promise<DetailResponse<IProposalResource>> {
    const { data } = await this.client.patch<DetailResponse<IProposalResource>>(`${this.namespace}`, body)
    return data
  }

  public async patchCollection(body: ProposalUpdateRequest): Promise<DetailResponse<IProposalCollectionResource>> {
    const { data } = await this.client.patch<DetailResponse<IProposalCollectionResource>>(`${this.namespace}`, body)
    return data
  }

  public async suggest(body: SuggestionRequest): Promise<DetailResponse<SuggestionResource>> {
    const { data } = await this.client.patch<DetailResponse<ISuggestionResource>>(`${this.namespace}/suggestions`, body)
    return {
      ...data,
      data: new SuggestionResource(data.data)
    }
  }

  public delete(): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}`)
  }

  public async patchConnections(body: ProposalPatchConnectionsRequest): Promise<DetailResponse<IProposalResource>> {
    const { data } = await this.client.patch<DetailResponse<IProposalResource>>(`${this.namespace}/connections`, body)
    return data
  }

  public async getComments(params?: Dictionary<any>): Promise<IndexResponse<CommentResource>> {
    const { data } = await this.client.get(`${this.namespace}/comments`, { params })
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async getAudit(params?: Dictionary<any>): Promise<IndexResponse<AuditResource>> {
    const { data } = await this.client.get(`${this.namespace}/audit`, { params })
    return {
      ...data,
      data: data.data.map((audit: IAuditResource) => new AuditResource(audit))
    }
  }

  public async getSuggestions(params?: Dictionary<any>): Promise<IndexResponse<SuggestionResource>> {
    const { data } = await this.client.get<IndexResponse<ISuggestionResource>>(`${this.namespace}/suggestions`, { params })
    return {
      ...data,
      data: data.data.map((suggestion: ISuggestionResource) => new SuggestionResource(suggestion))
    }
  }

  public async getLinkedProposals(params?: Dictionary<any>): Promise<DetailResponse<ProposalConnectionsResource>> {
    const { data } = await this.client.get<DetailResponse<IProposalConnectionsResource>>(`${this.namespace}/linked_connections`, { params })
    return {
      ...data,
      data: new ProposalConnectionsResource(data.data)
    }
  }

  public async allocateUsers(body: ProposalAllocateUsersRequest): Promise<DetailResponse<IProposalResource>> {
    const { data } = await this.client.patch<DetailResponse<IProposalResource>>(`${this.namespace}/users`, body)
    return data as DetailResponse<IProposalResource>
  }

  public async lock(): Promise<DetailResponse<IProposalResource>> {
    const { data } = await this.client.patch<DetailResponse<IProposalResource>>(`${this.namespace}/lock`)
    return data as DetailResponse<IProposalResource>
  }

}
