export default {
  sections: {
    title: {
      title: 'Title',
      sub_sections: {
        title: {
          label: '',
          placeholder: 'Write a title',
        },
        sub_title: {
          label: 'Sub title',
          placeholder: 'Write a sub title... (optional)',
        },
      }
    },
    information: {
      title: 'Information sheet',
      sub_sections: {
        issued_by: {
          label: 'Issued by',
          placeholder: 'Full name issuer',
        },
        approved_by: {
          label: 'Approved by',
          placeholder: 'Full name approver',
        },
        effective_date: {
          label: 'Effective date',
          placeholder: 'Pick a date',
        },
        review_date: {
          label: 'Review date',
          placeholder: 'Pick a date',
        },
        name: {
          label: 'Name',
          placeholder: 'Full name contact',
        },
        email: {
          label: 'Email address',
          placeholder: 'Email address',
        },
        organization: {
          label: 'Organization',
          placeholder: 'Organization',
        },
        version: {
          label: 'Version',
          placeholder: 'Version number',
        },
        replaces: {
          label: 'Replaces',
          placeholder: 'Document Name Version (Effective Date)',
        },
      },
    },
    reading_guide: {
      title: 'Reading guide',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write a reading guide...',
        },
      },
    },
    introduction: {
      title: 'Introduction',
      sub_sections: {
        objective: {
          label: 'Objective',
          placeholder: 'Write objective...',
        },
        scope: {
          label: 'Scope',
          placeholder: 'Write scope...',
        },
        local_regulations: {
          label: 'Local regulations',
          placeholder: 'Write local regulations...',
        },
        waivers_deviations: {
          label: 'Waivers and Deviations',
          placeholder: 'Write waivers and deviations...',
        },
        implementation: {
          label: 'Implementation and embedding of the policy',
          placeholder: 'Write implementation and embedding of the policy...',
        },
      },
    },
    objective: {
      title: 'Objective',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write objective...',
        },
      },
    },
    scope: {
      title: 'Scope',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write scope...',
        },
      },
    },
    local_regulations: {
      title: 'Local regulations',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write local regulations...',
        },
      },
    },
    waivers_deviations: {
      title: 'Waivers and Deviations',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write waivers and deviations...',
        },
      },
    },
    implementation: {
      title: 'Implementation and embedding of the policy',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write implementation and embedding of the policy...',
        },
      },
    },
    principles: {
      title: 'Principles',
      sub_sections: {
        message: {
          label: 'Message',
          placeholder: 'Write principles...',
        },
      },
    },
    roles: {
      title: 'Roles & Responsibilities',
      sub_sections: {
        message: {
          label: 'Message',
          placeholder: 'Write roles...',
        },
      },
    },
    references: {
      title: 'References to other documents',
      sub_sections: {
        message: {
          label: 'Message',
          placeholder: 'Write references to other documents...',
        },
      },
    },
    requirement_introduction: {
      title: 'Introduction',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write an introduction of the data objects here...',
        },
      },
    },
    risk_introduction: {
      title: 'Introduction',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write an introduction of the data objects here...',
        },
      },
    },
    control_introduction: {
      title: 'Introduction',
      sub_sections: {
        message: {
          label: '',
          placeholder: 'Write an introduction of the data objects here...',
        },
      },
    },
    requirements: {
      title: 'Requirements',
    },
    risks: {
      title: 'Risk Assessment & Control Objectives'
    },
    controls: {
      title: 'Controls'
    }
  },
  detail: {
    pages: {
      title: {
        submit: 'Submit the title for approval',
        approve: 'Approve the title',
        decline: 'Decline the title',
        release: 'Redefine the title',
        redefine: 'Redefine the title',
        action: {
          submit: {
            title: 'Title successfully submitted',
            message: 'Title submitted for approval'
          },
          approve: {
            title: 'Title succesfully approved',
            message: 'Title succesfully approved'
          },
          decline: {
            title: 'Title succesfully declined',
            message: 'Title succesfully declined'
          },
          release: {
            title: 'Title succesfully redefined',
            message: 'Title succesfully redefined'
          },
          redefine: {
            title: 'Title succesfully redefined',
            message: 'Title succesfully redefined'
          }
        }
      },
      information: {
        submit: 'Submit information sheet for approval',
        approve: 'Approve information sheet',
        decline: 'Decline information sheet',
        release: 'Redefine information sheet',
        redefine: 'Redefine information sheet',
        action: {
          submit: {
            title: 'Information sheet succesfully submitted',
            message: 'Information sheet submitted for approval'
          },
          approve: {
            title: 'Information sheet succesfully approved',
            message: 'Information sheet succesfully approved'
          },
          decline: {
            title: 'Information sheet succesfully declined',
            message: 'Information sheet succesfully declined'
          },
          release: {
            title: 'Information sheet succesfully redefined',
            message: 'Information sheet succesfully redefined'
          },
          redefine: {
            title: 'Information sheet succesfully redefined',
            message: 'Information sheet succesfully redefined'
          }
        }
      },
      reading_guide: {
        submit: 'Submit reading guide for approval',
        approve: 'Approve reading guide',
        decline: 'Decline reading guide',
        release: 'Redefine reading guide',
        redefine: 'Redefine reading guide',
        action: {
          submit: {
            title: 'Reading guide successfully submitted',
            message: 'Reading guide submitted for approval'
          },
          approve: {
            title: 'Reading guide successfully approved',
            message: 'Reading guide successfully approved'
          },
          decline: {
            title: 'Reading guide successfully declined',
            message: 'Reading guide successfully declined'
          },
          release: {
            title: 'Reading guide successfully redefined',
            message: 'Reading guide successfully redefined'
          },
          redefine: {
            title: 'Reading guide successfully redefined',
            message: 'Reading guide successfully redefined'
          }
        }
      },
      introduction: {
        submit: 'Submit the introduction for approval',
        approve: 'Approve the introduction',
        decline: 'Decline the introduction',
        release: 'Redefine the introduction',
        redefine: 'Redefine the introduction',
        action: {
          submit: {
            title: 'Introduction succesfully submitted',
            message: 'Introduction submitted for approval'
          },
          approve: {
            title: 'Introduction succesfully approved',
            message: 'Introduction succesfully approved'
          },
          decline: {
            title: 'Introduction succesfully declined',
            message: 'Introduction succesfully declined'
          },
          release: {
            title: 'Introduction succesfully redefined',
            message: 'Introduction succesfully redefined'
          },
          redefine: {
            title: 'Introduction succesfully redefined',
            message: 'Introduction succesfully redefined'
          }
        }
      },
      objective: {
        submit: 'Submit objective for approval',
        approve: 'Approve objective',
        decline: 'Decline objective',
        release: 'Redefine objective',
        redefine: 'Redefine objective',
        action: {
          submit: {
            title: 'Objective succesfully submitted',
            message: 'Objective submitted for approval'
          },
          approve: {
            title: 'Objective succesfully approved',
            message: 'Objective succesfully approved'
          },
          decline: {
            title: 'Objective succesfully declined',
            message: 'Objective succesfully declined'
          },
          release: {
            title: 'Objective succesfully redefined',
            message: 'Objective succesfully redefined'
          },
          redefine: {
            title: 'Objective succesfully redefined',
            message: 'Objective succesfully redefined'
          }
        }
      },
      scope: {
        submit: 'Submit scope for approval',
        approve: 'Approve scope',
        decline: 'Decline scope',
        release: 'Redefine scope',
        redefine: 'Redefine scope',
        action: {
          submit: {
            title: 'Scope succesfully submitted',
            message: 'Scope submitted for approval'
          },
          approve: {
            title: 'Scope succesfully approved',
            message: 'Scope succesfully approved'
          },
          decline: {
            title: 'Scope succesfully declined',
            message: 'Scope succesfully declined'
          },
          release: {
            title: 'Scope succesfully redefined',
            message: 'Scope succesfully redefined'
          },
          redefine: {
            title: 'Scope succesfully redefined',
            message: 'Scope succesfully redefined'
          }
        }
      },
      local_regulations: {
        submit: 'Submit local regulations for approval',
        approve: 'Approve local regulations',
        decline: 'Decline local regulations',
        release: 'Redefine local regulations',
        redefine: 'Redefine local regulations',
        action: {
          submit: {
            title: 'Local regulations succesfully submitted',
            message: 'Local regulations submitted for approval'
          },
          approve: {
            title: 'Local regulations succesfully approved',
            message: 'Local regulations succesfully approved'
          },
          decline: {
            title: 'Local regulations succesfully declined',
            message: 'Local regulations succesfully declined'
          },
          release: {
            title: 'Local regulations succesfully redefined',
            message: 'Local regulations succesfully redefined'
          },
          redefine: {
            title: 'Local regulations succesfully redefined',
            message: 'Local regulations succesfully redefined'
          }
        }
      },
      waivers_deviations: {
        submit: 'Submit waivers and deviations for approval',
        approve: 'Approve waivers and deviations',
        decline: 'Decline waivers and deviations',
        release: 'Redefine waivers and deviations',
        redefine: 'Redefine waivers and deviations',
        action: {
          submit: {
            title: 'Waivers and deviations succesfully submitted',
            message: 'Waivers and deviations submitted for approval'
          },
          approve: {
            title: 'Waivers and deviations succesfully approved',
            message: 'Waivers and deviations succesfully approved'
          },
          decline: {
            title: 'Waivers and deviations succesfully declined',
            message: 'Waivers and deviations succesfully declined'
          },
          release: {
            title: 'Waivers and deviations succesfully redefined',
            message: 'Waivers and deviations succesfully redefined'
          },
          redefine: {
            title: 'Waivers and deviations succesfully redefined',
            message: 'Waivers and deviations succesfully redefined'
          }
        }
      },
      implementation: {
        submit: 'Submit implementation and embedding of the policy for approval',
        approve: 'Approve implementation and embedding of the policy',
        decline: 'Decline implementation and embedding of the policy',
        release: 'Redefine implementation and embedding of the policy',
        redefine: 'Redefine implementation and embedding of the policy',
        action: {
          submit: {
            title: 'Implementation and embedding of the policy succesfully submitted',
            message: 'Implementation and embedding of the policy submitted for approval'
          },
          approve: {
            title: 'Implementation and embedding of the policy succesfully approved',
            message: 'Implementation and embedding of the policy succesfully approved'
          },
          decline: {
            title: 'Implementation and embedding of the policy succesfully declined',
            message: 'Implementation and embedding of the policy succesfully declined'
          },
          release: {
            title: 'Implementation and embedding of the policy succesfully redefined',
            message: 'Implementation and embedding of the policy succesfully redefined'
          },
          redefine: {
            title: 'Implementation and embedding of the policy succesfully redefined',
            message: 'Implementation and embedding of the policy succesfully redefined'
          }
        }
      },
      principles: {
        submit: 'Submit principles for approval',
        approve: 'Approve principles',
        decline: 'Decline principles',
        release: 'Redefine principles',
        redefine: 'Redefine principles',
        action: {
          submit: {
            title: 'Principles succesfully submitted',
            message: 'Principles submitted for approval'
          },
          approve: {
            title: 'Principles succesfully approved',
            message: 'Principles succesfully approved'
          },
          decline: {
            title: 'Principles succesfully declined',
            message: 'Principles succesfully declined'
          },
          release: {
            title: 'Principles succesfully redefined',
            message: 'Principles succesfully redefined'
          },
          redefine: {
            title: 'Principles succesfully redefined',
            message: 'Principles succesfully redefined'
          }
        }
      },
      roles: {
        submit: 'Submit roles & responsibilities for approval',
        approve: 'Approve roles & responsibilities',
        decline: 'Decline roles & responsibilities',
        release: 'Redefine roles & responsibilities',
        redefine: 'Redefine roles & responsibilities',
        action: {
          submit: {
            title: 'Roles & responsibilities succesfully submitted',
            message: 'Roles & responsibilities submitted for approval'
          },
          approve: {
            title: 'Roles & responsibilities succesfully approved',
            message: 'Roles & responsibilities succesfully approved'
          },
          decline: {
            title: 'Roles & responsibilities succesfully declined',
            message: 'Roles & responsibilities succesfully declined'
          },
          release: {
            title: 'Roles & responsibilities succesfully redefined',
            message: 'Roles & responsibilities succesfully redefined'
          },
          redefine: {
            title: 'Roles & responsibilities succesfully redefined',
            message: 'Roles & responsibilities succesfully redefined'
          }
        }
      },
      references: {
        submit: 'Submit references to other documents for approval',
        approve: 'Approve references to other documents',
        decline: 'Decline references to other documents',
        release: 'Redefine references to other documents',
        redefine: 'Redefine references to other documents',
        action: {
          submit: {
            title: 'References to other documents succesfully submitted',
            message: 'References to other documents submitted for approval'
          },
          approve: {
            title: 'References to other documents succesfully approved',
            message: 'References to other documents succesfully approved'
          },
          decline: {
            title: 'References to other documents succesfully declined',
            message: 'References to other documents succesfully declined'
          },
          release: {
            title: 'References to other documents succesfully redefined',
            message: 'References to other documents succesfully redefined'
          },
          redefine: {
            title: 'References to other documents succesfully redefined',
            message: 'References to other documents succesfully redefined'
          }
        }
      },
      requirement_introduction: {
        submit: 'Submit requirements introduction for approval',
        approve: 'Approve requirements introduction',
        decline: 'Decline requirements introduction',
        release: 'Redefine requirements introduction',
        redefine: 'Redefine requirements introduction',
        action: {
          submit: {
            title: 'Requirements introduction succesfully submitted',
            message: 'Requirements introduction submitted for approval'
          },
          approve: {
            title: 'Requirements introduction succesfully approved',
            message: 'Requirements introduction succesfully approved'
          },
          decline: {
            title: 'Requirements introduction succesfully declined',
            message: 'Requirements introduction succesfully declined'
          },
          release: {
            title: 'Requirements introduction succesfully redefined',
            message: 'Requirements introduction succesfully redefined'
          },
          redefine: {
            title: 'Requirements introduction succesfully redefined',
            message: 'Requirements introduction succesfully redefined'
          }
        }
      },
      risk_introduction: {
        submit: 'Submit risks introduction for approval',
        approve: 'Approve risks introduction',
        decline: 'Decline risks introduction',
        release: 'Redefine risks introduction',
        redefine: 'Redefine risks introduction',
        action: {
          submit: {
            title: 'Risks introduction succesfully submitted',
            message: 'Risks introduction submitted for approval'
          },
          approve: {
            title: 'Risks introduction succesfully approved',
            message: 'Risks introduction succesfully approved'
          },
          decline: {
            title: 'Risks introduction succesfully declined',
            message: 'Risks introduction succesfully declined'
          },
          release: {
            title: 'Risks introduction succesfully redefined',
            message: 'Risks introduction succesfully redefined'
          },
          redefine: {
            title: 'Risks introduction succesfully redefined',
            message: 'Risks introduction succesfully redefined'
          }
        }
      },
      control_introduction: {
        submit: 'Submit controls introduction for approval',
        approve: 'Approve controls introduction',
        decline: 'Decline controls introduction',
        release: 'Redefine controls introduction',
        redefine: 'Redefine controls introduction',
        action: {
          submit: {
            title: 'Controls introduction succesfully submitted',
            message: 'Controls introduction submitted for approval'
          },
          approve: {
            title: 'Controls introduction succesfully approved',
            message: 'Controls introduction succesfully approved'
          },
          decline: {
            title: 'Controls introduction succesfully declined',
            message: 'Controls introduction succesfully declined'
          },
          release: {
            title: 'Controls introduction succesfully redefined',
            message: 'Controls introduction succesfully redefined'
            ,
            redefine: {
              title: 'Controls introduction succesfully redefined',
              message: 'Controls introduction succesfully redefined'
            }
          }
        }
      }
    }
  }
}
