import Vue                                     from 'vue'
import cloneDeep                               from 'lodash.clonedeep'
import { stateModule, flexibleDocumentModule } from '@/store'
import { FlexibleDocumentService }             from '@/services/flexibleDocument'

import {
  FlexibleDocumentItemCollectionResource,
  IFlexibleDocumentItemCollectionResource
} from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'

export interface IFlexibleDocumentComponentCollectionResource extends IFlexibleDocumentItemCollectionResource {
  label: string
  component_type: string
  component_value: any
  component_key: string
  options?: Array<{ value: string | number, label: string }>
  // permissions: FlexibleDocumentComponentCollectionPermissions[]
  hasChanged: boolean
}

export class FlexibleDocumentComponentCollectionResource extends FlexibleDocumentItemCollectionResource {

  public readonly label: string
  public component_value: any
  public component_type: string
  public component_key: string
  public options?: Array<{ value: string | number, label: string }>
  // public permissions: FlexibleDocumentComponentCollectionPermissions[]
  public hasChanged: boolean = false
  public items?: FlexibleDocumentComponentCollectionResource[]

  public errors: ErrorResponse = {}
  private original_component_value: string | number | Array<string | string[]>

  constructor({ label, component_value, component_type, component_key, options, ...item }: IFlexibleDocumentComponentCollectionResource, project_id: number) {
    super(item, project_id)
    this.label = label
    this.component_value = cloneDeep(component_value)
    this.original_component_value = cloneDeep(component_value)
    this.component_type = component_type
    this.component_key = component_key
    this.options = options
    // this.permissions = permissions
  }

  public canPerformAction(key?: string): boolean {
    return false
  }

  public get heading(): string {
    return this.title || this.component_type
  }

  public revertComponentValue(): void {
    this.hasChanged = false
    Vue.set(this, 'component_value', cloneDeep(this.original_component_value))
  }

  public updateComponentValue(value: any): void {
    this.hasChanged = true
    this.component_value = value
  }

  public async patchItem(): Promise<FlexibleDocumentComponentCollectionResource> {
    if (!flexibleDocumentModule.flexibleDocumentService) return this
    Vue.set(this, 'errors', {})
    try {
      const { data } = await flexibleDocumentModule.flexibleDocumentService.patchComponent({ uuid: this.uuid, body: { component_value: this.component_value } })
      this.component_value = data.component_value
      this.original_component_value = cloneDeep(data.component_value)
      this.hasChanged = false
      return this
    } catch (e) {
      if (e.errors) {
        Vue.set(this, 'errors', e.errors)
      }
      throw e
    }
  }
}
