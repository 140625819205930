export const OBJECT_VALUES_TO_STRING = (o: any): { [key: string]: string } => {
  if (o) {
    Object.keys(o)?.forEach((k) => {
      if (typeof o[k] === 'object') {
        return o[k]?.toString()
      }
      o[k] = `${o[k]}`
    })
  }
  return o
}
