







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NotificationIcon extends Vue {
  @Prop()
  private showData!: boolean
}
