















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ComponentResource }    from '@/models/widgets/ComponentResource'
import * as BaseComponents      from './index'

@Component({ components: { ...BaseComponents } })
export default class BaseComponent extends Vue {

  @Prop({ required: true })
  public readonly value!: string | string[]

  @Prop({ required: true })
  public readonly component!: ComponentResource

  public get options(): SelectItem[] | null {
    return this.component.options
  }

  public onInput(data: string | string[]): void {
    this.$emit('input', data)
  }

  public get label(): string | undefined {
    return `${this.component.label}${this.component.mandatory ? ' *' : ''}`
  }

  private componentClass(component: ComponentResource): string {
    return component.base_component
  }

}
