import { OrganisationThemeResource } from '@/models/organisation/OrganisationThemeResource'

export const setColors = (theme: OrganisationThemeResource): void => {
  if (window && window.document) {
    for (const key in theme) {
      if (theme.hasOwnProperty(key)) {
        window.document.documentElement.style.setProperty(`--${key}`, theme[key])
      }
    }
  }
}
