export default {
  create: {
    actions: {
      mark: 'Mark requirement',
      define: 'Define requirement',
      submit: 'Submit requirement',
    },
    tabs: {
      internal_references: 'Internal References',
      external_references: 'External References',
      comments: 'Comments',
      requirements: 'Requirements',
    }
  }
}
