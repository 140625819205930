export default {
  'save': 'Save',
  'cancel': 'Cancel',
  'defined_by': 'Defined by',
  'approved_by': 'Approved by',
  'close': 'Close',
  'view': 'View',
  'suggestions': {
    'title': 'Suggestions',
    '2': 'Very similar',
    '1': 'Similar',
    '80_100': 'High similarity',
    '60_80': 'Medium similarity',
    '40_60': 'Low similarity',
    '20_40': 'No similarity',
    '0_20': 'No similarity',
  },
  'similarity': {
    2: 'High similarity',
    1: 'Medium similarity',
  },
  'comments': {
    empty: 'There are no comments yet',
    add: 'Write a comment...',
  },
  'tabs': {
    'obligations': 'Obligations',
    'risks': 'Risks',
    'write': 'Write',
    'control-objectives': 'Control objectives',
    'controls': 'Controls',
    'comments': 'Comments',
    'based_on': 'Based on',
    'edit': 'Edit',
  },
  'requirements': {
    name: 'New requirement',
    approve: 'Approve requirement',
    approved: 'Approved',
    pending_approval: 'Pending',
    release: 'Release approval',
    definer_notApproved: 'Wait for requirement to be approved before creating an obligation.',
    admin_notApproved: 'Before obligations can be made, this requirement needs to be approved.',
    admin_empty: 'There are no obligations created yet. Please check back later.',
    definer_empty: 'You don’t have any obligations yet. Create your first obligation!',
    button: 'Add obligation',
    delete: 'Delete requirement',
    suggested: 'Suggested obligation',
    write: 'Write obligation',
    similarity: {
      title: ':count similarities detected',
      subtitle: 'Please select one of the existing requirements if applicable.',
      continue: 'Continue with my requirement',
    },
  },
  'obligations': {
    name: 'New obligation',
    approve: 'Approve obligation',
    approved: 'Approved',
    pending_approval: 'Pending',
    release: 'Release approval',
    definer_notApproved: 'Wait for obligation to be approved before creating a risk',
    admin_notApproved: 'Before risks can be made, this obligation needs to be approved.',
    admin_empty: 'There are no risks created yet. Please check back later.',
    definer_empty: 'You don’t have any risks yet. Create your first risk!',
    button: 'Add risk',
    delete: 'Delete obligation',
    suggested: 'Suggested risk',
    write: 'Write risk',
    elements: {
      title: 'Obligation information',
      metadata_field: {
        label: 'Metadata',
        placeholder: 'Metadata',
      },
      country: {
        label: 'Country',
        placeholder: 'Select an country',
        options: {
          nl: 'Netherlands',
          en: 'England',
        },
      },
    },
    similarity: {
      title: ':count similarities detected',
      subtitle: 'Please select one of the existing obligatios if applicable.',
      continue: 'Continue with my obligation',
    },
  },
  'risks': {
    name: 'New risk',
    approve: 'Approve risk',
    approved: 'Approved',
    pending_approval: 'Pending',
    release: 'Release approval',
    definer_notApproved: 'Wait for risk to be approved before creating a control objective',
    admin_notApproved: 'Before control objectives can be made, this risk needs to be approved.',
    admin_empty: 'There are no control objectives created yet. Please check back later.',
    definer_empty: 'You don’t have any control objectives yet. Create your first control objective!',
    button: 'Add control objective',
    delete: 'Delete risk',
    suggested: 'Suggested control objective',
    write: 'Write control objective',
    elements: {
      title: 'Risk information',
    },
    similarity: {
      title: ':count similarities detected',
      subtitle: 'Please select one of the existing risks if applicable.',
      continue: 'Continue with my risk',
    },
  },
  'control-objectives': {
    name: 'New control objective',
    approve: 'Approve control objective',
    approved: 'Approved',
    pending_approval: 'Pending',
    release: 'Release approval',
    definer_notApproved: 'Wait for control objective to be approved before creating a control',
    admin_notApproved: 'Before controls can be made, this control objective needs to be approved.',
    admin_empty: 'There are no control objectives created yet. Please check back later.',
    definer_empty: 'You don’t have any control objectives yet. Create your first control objective!',
    button: 'Add control',
    delete: 'Delete control objective',
    suggested: 'Suggested control',
    write: 'Write control',
    similarity: {
      title: ':count similarities detected',
      subtitle: 'Please select one of the existing control objectives if applicable.',
      continue: 'Continue with my control objective',
    },
  },
  'controls': {
    name: 'New control',
    approve: 'Approve control',
    approved: 'Approved',
    pending_approval: 'Pending',
    release: 'Release approval',
    delete: 'Delete control',
    definer_notApproved: 'Wait for control to be approved before creating a control',
    admin_notApproved: 'Before controls can be made, this control objective needs to be approved.',
    admin_empty: 'There are no controls created yet. Please check back later.',
    definer_empty: 'You don’t have any controls yet. Create your first control!',
    button: 'Add control',
    suggested: 'Suggested control',
    write: 'Write control',
    elements: {
      'title': 'Control information',
      'sox': {
        label: 'SOx control',
      },
      'control-requirements': {
        label: 'Control requirement',
        add: 'Add requirement',
        placeholder: 'Describe requirement',
      },
    },
    similarity: {
      title: ':count similarities detected',
      subtitle: 'Please select one of the existing controls if applicable.',
      continue: 'Continue with my control',
    },
  },
  'policy': {
    approve: 'Approve',
    approved: 'Approved',
  },
}
