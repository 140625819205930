import { ProposalResource }           from '@/models/proposals/ProposalResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

export class ProposalAllocateUsersRequest {
  public notify_user: number[]

  constructor(proposal: ProposalCollectionResource | ProposalResource) {
    this.notify_user = proposal.allocated_users?.map((user) => user.id) ?? []
  }
}
