import { UserResource, IUserResource }   from '@/models/users/UserResource'


export interface IOrganisationDocumentVersionResource {
  id: number
  project_id: number
  parent_project_id: number
  owner: string
  updated_at: string
  created_at: string
  name: string
  project_name: string
  project_status: string
  status: string
  version: string | null
  version_type: string | null
  type: string | null
  child_count: number
  pdf_link?: string | undefined
  derived_documents: IOrganisationDocumentVersionResource[] | undefined
}

export class OrganisationDocumentVersionResource {
  public id: number
  public project_id: number
  public parent_project_id: number
  public owner: string
  public updated_at: string
  public created_at: string
  public name: string
  public project_name: string
  public project_status: string
  public status: string
  public version: string | null
  public version_type: string | null
  public type: string | null
  public child_count: number
  public pdf_link: string | undefined
  public derived_documents: OrganisationDocumentVersionResource[] | undefined

  constructor(document: IOrganisationDocumentVersionResource) {
    this.id = document.id
    this.project_id = document.project_id
    this.parent_project_id = document.parent_project_id
    this.owner = document.owner
    this.updated_at = document.updated_at
    this.created_at = document.created_at
    this.name = document.name
    this.project_name = document.project_name
    this.project_status = document.project_status
    this.status = document.status
    this.version = document.version
    this.version_type = document.version_type
    this.type = document.type
    this.child_count = document.child_count
    this.pdf_link = document.pdf_link
    this.derived_documents = document.derived_documents ? document.derived_documents.map((d) => new OrganisationDocumentVersionResource(d)) : undefined
  }
}
