
























































































































































































































import isEqual from 'lodash/isEqual'
import {mixin as clickaway} from '@/plugins/vue-clickaway'
import {Component, Prop, Vue} from 'vue-property-decorator'

import {ProcessResource} from '@/models/process/ProcessResource'
import {ProposalResource} from '@/models/proposals/ProposalResource'
import {proposalSortOptions} from '@/data/proposals'

import Tabs from '@/components/widgets/Tabs.vue'
import Modal from '@/components/widgets/Modal.vue'
import NoItems from '@/components/partials/NoItems.vue'
import SearchInput from '@/components/inputs/Search.vue'
import ActionBar from '@/components/widgets/ActionBar.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import PanelSplit from '@/components/widgets/PanelSplit.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import DropdownSort from '@/components/inputs/DropdownSort.vue'
import DetailsHeader from '@/components/header/DetailsHeader.vue'
import ProposalSimple from '@/components/tiles/ProposalSimple.vue'
import ProposalsList from '@/components/project/ProposalsList.vue'

import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'

import {ProjectResource} from '@/models/projects/ProjectResource'
import {StatusResourceCollection} from '@/models/status/StatusResourceCollection'
import {ProposalSummaryCollectionResource} from '@/models/proposals/ProposalSummaryCollectionResource'
import cloneDeep from 'lodash/cloneDeep'

type AvailablePages = 'UnlinkedProposals' | 'LinkedProposals' | 'AllProposals'

const DEFAULT_FILTERS = {
  status: [],
  coverage: undefined,
  search: '',
  column: 'created_at',
  dir: 'desc',
  parent_ids: [],
  child_ids: [],
  component_values: {}
}

@Component({
  components: {
    ProposalSimple,
    Tabs,
    Modal,
    NoItems,
    ActionBar,
    PanelSplit,
    SmallLoader,
    DetailsHeader,
    ProposalsList,
    DropdownSort,
    SearchInput,
    DropdownInput
  },
  mixins: [clickaway]
})
export default class ConnectionsModal extends Vue {

  @Prop()
  private project!: ProjectResource

  @Prop()
  private process!: ProcessResource

  @Prop({default: () => []})
  private preselectedProposalIds!: number[]

  @Prop()
  private linked_proposals!: ProposalSummaryCollectionResource[] | undefined

  private proposals: ProposalSummaryCollectionResource[] = []
  private allProposals: ProposalSummaryCollectionResource[] = []

  private sortOptions: SelectItem[] = proposalSortOptions
  private proposalFilters: { [key: string]: { [key: string]: string } } = {}
  private proposalParams: ProposalIndexRequest = {...DEFAULT_FILTERS}
  private defaultFilters: ProposalIndexRequest = {...DEFAULT_FILTERS}

  private isFetchingProposal: boolean = false
  private isFetchingProposals: boolean = false

  private currentSelectedProposal: ProposalResource | null = null
  private selectedProposalIds: number[] = []
  private showFilters: boolean = false

  private currentPage: AvailablePages = 'UnlinkedProposals'

  private get combinedProposals(): ProposalSummaryCollectionResource[] {
    // These are the proposals that are already linked but not in the available call since they changed status after connection was made
    const REMAINING_LINKED_PROPOSALS = this.linked_proposals?.filter((linked_proposal) => !this.proposals.some((proposal) => proposal.id === linked_proposal.id)) ?? []
    return [...REMAINING_LINKED_PROPOSALS, ...this.proposals]
  }

  private get linkedCombinedProposals(): ProposalSummaryCollectionResource[] {
    return this.allProposals.filter((proposal) => this.linked_proposals?.some((linked_proposal) => linked_proposal.id === proposal.id) )
  }

  private get unlinkedCombinedProposals(): ProposalSummaryCollectionResource[] {
    return this.combinedProposals.filter((proposal) => !this.linked_proposals?.some((linked_proposal) => linked_proposal.id === proposal.id) )
  }

  private get itemCount(): number {
    return this.combinedProposals.length
  }

  private get previousProcess(): ProcessResource | undefined {
    return this.project.getProcessByOrder(this.process.order - 1)
  }

  private get nextProcess(): ProcessResource | undefined {
    return this.project.getProcessByOrder(this.process.order + 1)
  }

  private async created(): Promise<void> {
    this.selectedProposalIds.push(...this.preselectedProposalIds)
    await this.getProposals()
    this.setInitialFilters()
  }

  private setInitialFilters() {
    for (const { id } of this.process.componentFilters) {
      if (this.proposalParams.component_values) {
        Vue.set(this.proposalParams.component_values, id, [])
      }
    }
    // @ts-ignore
    for (const { id } of this.proposalFilters?.components ?? []) {
      if (this.proposalParams.component_values) {
        Vue.set(this.proposalParams.component_values, id, [])
      }
    }

    this.defaultFilters = cloneDeep(this.proposalParams)
  }

  private get filtersActive(): boolean {
    return !isEqual({ ...this.proposalParams }, this.defaultFilters)
  }

  private get statusFilters(): Array<Partial<StatusResourceCollection>> {
    let filters: Array<Partial<StatusResourceCollection>> = []

    if (this.process && typeof this.process.statuses === 'object') {
      filters = [...this.process.statuses]
    }

    return filters
  }

  private get parentFilters(): SelectItem[] {
    if (this?.proposalFilters?.parents && this.previousProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.previousProcess.process_name} connected`,
        },
      ]

      Object.keys(this.proposalFilters.parents).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.parents[key],
        })
      })

      return FILTERS
    }

    return []
  }

  private get childFilters(): SelectItem[] {
    if (this.proposalFilters?.children && this.nextProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.nextProcess.process_name} connected`,
        },
      ]

      Object.keys(this.proposalFilters.children).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.children[key],
        })
      })

      return FILTERS
    }

    return []
  }


  private async getProposals(): Promise<void> {
    if (!this.process) return
    this.isFetchingProposals = true
    try {
      const [allProposals, data] = await Promise.all([
        this.process.getProposalsIndex({ ...this.proposalParams }),
        this.process.getAvailableProposals({ pagination: 'false', ...this.proposalParams }),
      ])
      this.proposals = data.data
      this.allProposals = allProposals.data
      this.proposalFilters = allProposals.filters
      this.isFetchingProposals = false
    } catch (e) {
      console.error(e)
    }
  }

  private async viewProposal(proposal: ProposalSummaryCollectionResource): Promise<void> {
    if (this.currentSelectedProposal && this.currentSelectedProposal.id === proposal.id) return
    this.isFetchingProposal = true
    try {
      const {data} = await proposal.proposalService.get()
      this.$set(this, 'currentSelectedProposal', data)
    } finally {
      this.isFetchingProposal = false
    }
  }

  private deselectActiveProposal(): void {
    this.currentSelectedProposal = null
  }

  private isSelected(proposalId: number): boolean {
    return this.selectedProposalIds.includes(proposalId)
  }

  private selectProposal(proposalId: number) {
    // Remove proposal
    if (this.isSelected(proposalId)) {
      const PROPOSAL_INDEX = this.selectedProposalIds.findIndex((id) => id === proposalId)
      this.selectedProposalIds.splice(PROPOSAL_INDEX, 1)
      return
    }

    this.selectedProposalIds.push(proposalId)

  }

  private close(): void {
    this.$emit('close')
  }

  private submit(): void {
    this.$emit('submit', this.selectedProposalIds)
  }

  private setProposalParam(key: string, value: string): void {
    this.proposalParams[key] = value
  }

  private setSortParam(sort: string): void {
    const [column, dir] = sort.split('-')

    this.setProposalParam('column', column)
    this.setProposalParam('dir', dir)

    this.applyFilters()
  }

  private applyFilters(): void {
    this.closeFilters()
    this.getProposals()
  }

  private async clearFilters(): Promise<void> {
    this.proposalParams = { ...DEFAULT_FILTERS, component_values: {} }
    if (this.showFilters) {
      this.toggleFilters()
    }

    await this.getProposals()
    this.setInitialFilters()
  }

  private toggleFilters(): void {
    this.showFilters = !this.showFilters
  }

  private closeFilters(): void {
    this.showFilters = false
  }

  private get allCurrentProposalsSelected(): boolean {
    return this.combinedProposals.every((proposal) => this.isSelected(proposal.id))
  }

  private get selectComponent(): string {
    return this.allCurrentProposalsSelected ? 'selectedBoxIcon' : 'selectBoxIcon'
  }

  private toggleSelect(): void {
    // All proposals are already selected, So we deselect all proposals
    if (this.allCurrentProposalsSelected) {
      this.selectedProposalIds = []
      // Select all current proposals
    } else {
      this.selectedProposalIds.push(...this.combinedProposals.map((proposal) => proposal.id))
    }
  }

  private goToPage(page: AvailablePages): void {
    this.currentPage = page
  }
}
