import Checkbox from './Checkbox.vue'
import Dropdown from './Dropdown.vue'
import TextEditor from './TextEditor.vue'
import DatePicker from './DatePicker.vue'
import HtmlViewer from './HtmlViewer.vue'
import DocumentMeta from './DocumentMeta.vue'

export const BASE_COMPONENTS: { [key: string]: any } = {
    DocumentMeta,
    Checkbox,
    Dropdown,
    TextEditor,
    DatePicker,
    HtmlViewer
}
