import { CommentsService }                                       from '@/services/comments'
import { canPerformAction }                                      from '@/helpers/canPerformAction'
import { convertToDate, formatDate }                             from '@/helpers/formatDate'
import { CommentCreateRequest }                                  from '@/requests/comments/CommentCreateRequest'
import { ICommentResource, CommentResource }                     from '../comments/CommentResource'
import { ProjectCollectionResource, IProjectCollectionResource } from '../projects/ProjectCollectionResource'
import { IOrganisationDocumentVersionResource, OrganisationDocumentVersionResource }                  from '@/models/organisationDocuments/OrganisationDocumentVersion'

const commentsService = new CommentsService()

export interface OrganisationDocumentMetaField {
  key: string,
  label: string,
  value: string | string[]
  type: string,
  multiple: boolean
}
export interface OrganisationDocumentSection {
  section_name: string,
  fields: OrganisationDocumentMetaField[]
}

export interface IOrganisationDocumentResource {
  id: number
  document_name: string
  published_date: string
  pdf_link: string
  created_at: string
  updated_at: string
  status: string
  type: string

  project_info: IProjectCollectionResource
  comments: ICommentResource[]

  meta: OrganisationDocumentSection[]
  version_history: {
    updated_by: IOrganisationDocumentVersionResource[]
    derived_from: IOrganisationDocumentVersionResource[]
    current_version: IOrganisationDocumentVersionResource[]
    older_versions: IOrganisationDocumentVersionResource[]
  }

  permissions: string[]
}

export class OrganisationDocumentResource {
  public readonly id: number
  public document_name: string
  public published_date: string
  public pdf_link: string
  public created_at: string
  public updated_at: string
  public status: string
  public type: string

  public meta: OrganisationDocumentSection[]
  public version_history: {
    updated_by: OrganisationDocumentVersionResource[]
    derived_from: OrganisationDocumentVersionResource[]
    current_version: OrganisationDocumentVersionResource[]
    older_versions: OrganisationDocumentVersionResource[]
  }


  public comments: CommentResource[]
  public project_info: ProjectCollectionResource

  public permissions: string[]

  constructor(organisation: IOrganisationDocumentResource) {
    this.id = organisation.id
    this.document_name = organisation.document_name
    this.published_date = organisation.published_date
    this.project_info = new ProjectCollectionResource(organisation.project_info)
    this.pdf_link = organisation.pdf_link
    this.created_at = organisation.created_at
    this.updated_at = organisation.updated_at
    this.status = organisation.status
    this.type = organisation.type

    this.meta = organisation.meta
    this.version_history = {
      updated_by: organisation.version_history.updated_by ? organisation.version_history.updated_by.map((document) => new OrganisationDocumentVersionResource(document)) : [],
      derived_from: organisation.version_history.derived_from ? organisation.version_history.derived_from.map((document) => new OrganisationDocumentVersionResource(document)) : [],
      current_version: organisation.version_history.current_version ? organisation.version_history.current_version.map((document) => new OrganisationDocumentVersionResource(document)) : [],
      older_versions: organisation.version_history.older_versions ? organisation.version_history.older_versions.map((document) => new OrganisationDocumentVersionResource(document)) : []
    }

    this.permissions = organisation.permissions

    this.comments = organisation.comments ? organisation.comments.map((comment: any) => new CommentResource(comment)) : []
  }

  public get formatted_published_date(): string {
    return formatDate(convertToDate(this.published_date))
  }

  public canPerformAction(key: string): boolean {
    return canPerformAction<string>(this.permissions, key)
  }

  public async postComment(body: Omit<CommentCreateRequest, 'commentable_type' | 'commentable_id' | 'status'>): Promise<CommentResource> {
    const { data } = await commentsService.post({ ...body, commentable_id: this.id, commentable_type: 'organisation_document', status: 'unresolved' })
    this.comments.push(data)
    return data
  }

}
