import { AxiosPromise, AxiosResponse } from 'axios'
import { BaseService }                 from '@/services/base'

import { DocumentResource, IDocumentResource }                     from '@/models/documents/DocumentResource'
import { DocumentPresetResource, IDocumentPresetResource }         from '@/models/documents/DocumentPresetResource'
import { DocumentFilterResource, IDocumentFilterResource }         from '@/models/documents/DocumentFilterResource'
import { DocumentCollectionResource, IDocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'

import { DocumentIndexRequest }          from '@/requests/documents/DocumentIndexRequest'
import { DocumentPatchRequest }          from '@/requests/documents/DocumentPatchRequest'
import { DocumentCreateRequest }         from '@/requests/documents/DocumentCreateRequest'
import { DocumentVersionRequest }        from '@/requests/documents/DocumentVersionRequest'
import { DocumentPresetPatchRequest }    from '@/requests/documents/DocumentPresetPatchRequest'
import { DocumentPresetCreateRequest }   from '@/requests/documents/DocumentPresetCreateRequest'
import { cleanObject }                   from '@/helpers/query'
import { DocumentExportByPresetRequest } from '@/models/documents/DocumentExportByPresetRequest'
import { DocumentExportRequest }         from '@/models/documents/DocumentExportRequest'

export class DocumentsService extends BaseService {

  constructor() {
    super('documents')
  }

  /**
   * Get all documents by params
   */
  public async get(params: DocumentIndexRequest): Promise<IndexResponse<DocumentCollectionResource>> {
    const { data } = await this.client.post<IndexResponse<IDocumentCollectionResource>>(`${this.namespace}`, cleanObject(params))
    data.data = data.data.map((document: IDocumentCollectionResource) => new DocumentCollectionResource(document))
    return data as IndexResponse<DocumentCollectionResource>
  }

  /**
   * Get filters of overview
   */
  public async filters(): Promise<IndexResponse<DocumentFilterResource>> {
    const { data } = await this.client.get<IndexResponse<IDocumentFilterResource>>(`${this.namespace}/filters`)
    data.data = data.data.map((filter: IDocumentFilterResource) => new DocumentFilterResource(filter))
    return data as IndexResponse<DocumentFilterResource>
  }

  public async searchedFilters(params?: DocumentIndexRequest, key?: string): Promise<IndexResponse<DocumentFilterResource>> {
    if (key && params) {
      params.key = key
    }
    const { data } = await this.client.post<IndexResponse<IDocumentFilterResource>>(`${this.namespace}/search-filters`, params ? cleanObject(params) : params)
    data.data = data.data.map((filter: IDocumentFilterResource) => new DocumentFilterResource(filter))
    return data as IndexResponse<DocumentFilterResource>
  }

  /**
   * Get all presets
   */
  public async presets(): Promise<IndexResponse<DocumentPresetResource>> {
    const { data } = await this.client.get<IndexResponse<IDocumentPresetResource>>(`${this.namespace}/presets`)
    data.data = data.data.map((preset: IDocumentPresetResource) => new DocumentPresetResource(preset))
    return data as IndexResponse<DocumentPresetResource>
  }

  /**
   * Mark all current documents as read
   */
   public async markAllAsRead(): Promise<string> {
    const { data } = await this.client.patch<string>(`${this.namespace}/markAsRead`)
    return data
  }

  /**
   * Post a new preset
   */
  public async postPreset(preset: DocumentPresetCreateRequest): Promise<DetailResponse<DocumentPresetResource>> {
    const { data } = await this.client.post<DetailResponse<IDocumentPresetResource>>(`${this.namespace}/presets`, preset)
    data.data = new DocumentPresetResource(data.data)
    return data as DetailResponse<DocumentPresetResource>
  }

  /**
   * Patch preset
   */
  public async patchPreset(id: number, preset: DocumentPresetPatchRequest): Promise<DetailResponse<DocumentPresetResource>> {
    const { data } = await this.client.patch<DetailResponse<IDocumentPresetResource>>(`${this.namespace}/presets/${id}`, preset)
    data.data = new DocumentPresetResource(data.data)
    return data as DetailResponse<DocumentPresetResource>
  }

  /**
   * Delete preset
   */
  public deletePreset(key: string): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/presets/${key}`)
  }

  /**
   * Get single document by id
   */
  public async getById(id: number): Promise<DetailResponse<DocumentResource>> {
    const { data } = await this.client.get<DetailResponse<IDocumentResource>>(`${this.namespace}/${id}`)
    data.data = new DocumentResource(data.data)
    return data as DetailResponse<DocumentResource>
  }

  /**
   * Post single document by id
   */
  public async post(form: DocumentCreateRequest): Promise<DetailResponse<DocumentResource>> {
    const formData = new FormData()
    formData.append('file', form.file)
    formData.append('subject', JSON.stringify(form.subject))
    formData.append('topic', JSON.stringify(form.topic))
    formData.append('country', JSON.stringify(form.country))

    const { data } = await this.client.post<DetailResponse<IDocumentResource>>(`${this.namespace}`, formData)
    data.data = new DocumentResource(data.data)
    return data as DetailResponse<DocumentResource>
  }

  /**
   * Attach new version to document by id
   */
  public async postVersion(id: number, form: DocumentVersionRequest): Promise<DetailResponse<DocumentResource>> {
    const { data } = await this.client.post<DetailResponse<IDocumentResource>>(`${this.namespace}/${id}/version`, form)
    data.data = new DocumentResource(data.data)
    return data as DetailResponse<DocumentResource>
  }

  /**
   * Bookmark a single document by ID
   */
  public async patch(id: number, body: DocumentPatchRequest): Promise<DetailResponse<DocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IDocumentResource>>(`${this.namespace}/${id}`, body)
    data.data = new DocumentResource(data.data)
    return data as DetailResponse<DocumentResource>
  }

  /**
   * Delete a single document by id
   */
  public delete(id: number): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/${id}`)
  }

  /**
   * Export the documents.
   *
   * @param query
   */
  public async exportLibraryContents(query: DocumentExportRequest): Promise<AxiosResponse> {
    const { data } = await this.client.post(`${this.namespace}/export`, cleanObject(query))
    return data
  }

  public async exportLibraryByPresets(body: DocumentExportByPresetRequest): Promise<AxiosResponse> {
    const { data } = await this.client.post(`${this.namespace}/export-presets`, body)
    return data
  }
}


