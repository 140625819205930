import Vue                             from 'vue'
import { createSocket } from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'
import {
  convertToFlexibleDocumentCollectionItem,
  createOrderObject,
  isIFlexibleDocumentTitlePageResource,
} from '@/helpers/FlexibleDocument'

import { ELEMENT_EVENTS }                   from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { ProjectUserResource }              from '@/models/projects/ProjectUserResource'
import { FlexibleDocumentItemCollection }   from '@/models/flexibleDocument/FlexibleDocumentItemCollection'
import { IFlexibleDocumentChapterResource } from '@/models/flexibleDocument/FlexibleDocumentChapterCollectionResource'
import { FlexibleDocumentItemCollectionResource, IFlexibleDocumentItemCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'

import { FlexibleDocumentUpdateChapterRequest }   from '@/requests/flexibleDocument/FlexibleDocumentUpdateChapterRequest'
import { FlexibleDocumentUpdateItemOrderRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { Socket }                                 from 'socket.io-client'

type FlexibleDocumentTitlePageCollectionPermissions = 'can_release' | 'can_redefine' | 'can_comment' | 'can_edit' | 'can_delete' | string

export interface IFlexibleDocumentTitlePageResource extends IFlexibleDocumentItemCollectionResource {
  permissions: FlexibleDocumentTitlePageCollectionPermissions[]
  include_in_toc: boolean
  items: FlexibleDocumentItemCollection[]
  channel: string
}

export class FlexibleDocumentTitlePageCollectionResource extends FlexibleDocumentItemCollectionResource {

  public items: FlexibleDocumentItemCollection[] = []

  public permissions: FlexibleDocumentTitlePageCollectionPermissions[]

  public edit: boolean = false
  public loading: boolean = false
  public errors: ErrorResponse = {}
  public include_in_toc: boolean
  public channel: string
  public socket?: Socket

  public readonly anchor_uuid: string

  constructor({ items, permissions, include_in_toc, channel, ...chapter }: IFlexibleDocumentTitlePageResource, project_id: number) {
    super(chapter, project_id)

    this.permissions = permissions
    this.include_in_toc = include_in_toc
    this.anchor_uuid = chapter.anchor_uuid
    this.channel = channel

    this.setItems(items)

    this.openSocket()
  }

  public destroy() {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public canPerformAction(key: FlexibleDocumentTitlePageCollectionPermissions): boolean {
    return canPerformAction<FlexibleDocumentTitlePageCollectionPermissions>(this.permissions, key)
  }

  public get heading(): string {
    return this.title || 'Frontpage'
  }

  public async patch(body: FlexibleDocumentUpdateChapterRequest): Promise<FlexibleDocumentTitlePageCollectionResource> {
    this.loading = true
    try {
      const { data } = await this.service.patch({ body }) as DetailResponse<IFlexibleDocumentChapterResource>
      if (isIFlexibleDocumentTitlePageResource(data)) {
        this.title = data.title
        this.include_in_toc = data.include_in_toc
        this.edit = false
      }
      return this
    } catch ({ errors, message }) {
      if (errors) {
        Vue.set(this, 'errors', errors)
      }
      throw errors
    } finally {
      this.loading = false
    }
  }

  public async patchOrder(data: Pick<FlexibleDocumentUpdateItemOrderRequest, 'items'>): Promise<FlexibleDocumentTitlePageCollectionResource> {
    const body: FlexibleDocumentUpdateItemOrderRequest = { anchor_uuid: this.uuid, items: data.items }
    await this.service.patchOrder({ body })
    return this
  }

  public getOrderedObject(): Array<{ uuid: string, order: number }> {
    return this.items.map(createOrderObject)
  }

  public reorderItems(): FlexibleDocumentTitlePageCollectionResource {
    let index = 0
    for (const item of this.items) {
      item.order = index
      index++
    }
    return this
  }

  private setItems(items: FlexibleDocumentItemCollection[]): void {
    Vue.set(this, 'items', items?.map((item) => convertToFlexibleDocumentCollectionItem(item, this.project_id))
        .sort((a, b) => (a.order > b.order) ? 1 : -1) ?? [])
  }

  private async refresh() {
    const data  = await this.service.refresh()
    if (isIFlexibleDocumentTitlePageResource(data)) {
      this.order = data.order
      // this.include_in_toc = data.include_in_toc
      this.title = data.title
      this.comment_count = data.comment_count
      this.is_locked = data.is_locked ?? false
      this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
      this.permissions = data.permissions
    }
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case ELEMENT_EVENTS.UPDATED:
        this.refresh().then()
        break
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
