import Vue from 'vue'

// @SECTION: implementation

const HANDLER = '_vue_clickaway_handler'

const bind = (el: any, binding: any, vnode?: any) => {
  unbind(el)

  const vm = vnode.context

  const callback = binding.value

  // @NOTE: Vue binds directives in microtasks, while UI events are dispatched
  //        in macrotasks. This causes the listener to be set up before
  //        the "origin" click event (the event that lead to the binding of
  //        the directive) arrives at the document root. To work around that,
  //        we ignore events until the end of the "initial" macrotask.
  // @REFERENCE: https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
  // @REFERENCE: https://github.com/simplesmiler/vue-clickaway/issues/8
  let initialMacrotaskEnded = false
  setTimeout(() => {
    initialMacrotaskEnded = true
  }, 0)

  el[HANDLER] = (ev: any) => {
    // @NOTE: this test used to be just `el.containts`, but working with path is better,
    //        because it tests whether the element was there at the time of
    //        the click, not whether it is there now, that the event has arrived
    //        to the top.
    // @NOTE: `.path` is non-standard, the standard way is `.composedPath()`
    const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined)
    if (
      initialMacrotaskEnded &&
      (path ? path.indexOf(el) < 0 : !el.contains(ev.target))
    ) {
      return callback.call(vm, ev)
    }
  }

  if (document.documentElement) {
    document.documentElement.addEventListener('click', el[HANDLER], false)
  }
}

const unbind = (el: any) => {
  if (document.documentElement) {
    document.documentElement.removeEventListener('click', el[HANDLER], false)
  }
  delete el[HANDLER]
}

export let directive = {
  bind,
  unbind,
  update: (el: any, binding: any) => {
    if (binding.value === binding.oldValue) {
      return
    }
    bind(el, binding)
  },
}

export let mixin = {
  directives: { onClickaway: directive },
}
