import uniqBy from 'lodash/uniqBy'
import { CommentsService } from '@/services/comments'
import { CommentResource, CommentFilter, CommentableType } from '@/models/comments/CommentResource'
import { ProjectService } from '@/services/project'
import { ImplementationService } from '@/services/implementation'

export class ChatController {

  public data: IndexResponse<CommentResource> | null = null
  public loadingInitialData: boolean = false
  public loadingNextData: boolean = false
  public justLoadedNextData: boolean = false
  public indexParameters: IndexParameters = {
    page: '1',
  }

  public id: number | string
  public commentableType: CommentableType

  private service: ProjectService | ImplementationService
  private commentsService: CommentsService

  constructor({ id, commentableType, service }: { id: number | string, commentableType: CommentableType, service: ProjectService | ImplementationService }) {
    this.id = id
    this.service = service
    this.commentableType = commentableType
    this.commentsService = new CommentsService()
    this.get()
  }

  public get items(): CommentResource[] {
    if (this.data) {
      return uniqBy(this.data.data.slice().reverse(), 'id')
    }
    return []
  }

  public get hasData(): boolean {
    return this.items.length > 0
  }

  public get lastPageReached(): boolean {
    return this.data?.pagination?.current_page === this.data?.pagination?.last_page
  }

  public get hasMoreThenOnePage(): boolean {
    return (this.data?.pagination?.last_page ?? 0) > 1
  }

  public async get(): Promise<void> {
    try {
      this.loadingInitialData = true
      this.data = await this.getData()
    } finally {
      this.loadingInitialData = false
    }
  }

  public async getNextMessages(): Promise<void> {
    // If end of list we wont fetch new items
    if (this.lastPageReached) return
    // If there is no data something probably went wrong already
    if (!this.data) return

    this.loadingNextData = true
    // convert string page to INT increase page with one and convert page to string
    this.indexParameters.page = (+(this.indexParameters?.page ?? '1') + 1).toString()
    const data = await this.getData()
    this.justLoadedNextData = true
    this.data.pagination = data.pagination
    this.data.data.push(...data.data)
    this.loadingNextData = false

    setTimeout(() => {
      this.justLoadedNextData = false
    }, 2000)
  }

  public async createMessage(message: string): Promise<void> {
    const { data } = await this.commentsService.post({
      message,
      commentable_type: this.commentableType,
      commentable_id: this.id,
      status: 'resolved',
    })
    if (this.data) {
      this.data.data.unshift(data)
    }
  }

  public addMessage(message: CommentResource): void {
    // There is no data to push to
    if (!this.data) return
    // This comment already exists, Probably created it him/her self
    if (this.data.data.some((comment) => comment.id === message.id)) return
    this.data.data.unshift(message)
  }

  public deleteComment(id: number): void {
    if (this.data && this.data.data) {
      const index = this.data.data.findIndex((comment) => comment.id === id)
      if (index > -1) {
        this.data.data.splice(index, 1)
      }
    }
  }

  private async getData(): Promise<IndexResponse<CommentResource>> {
    return await this.service.getChat({ ...this.indexParameters })
  }

}
