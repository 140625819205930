import { createSocket } from '@/api'
import { CommentsController }          from '@/controllers/comments/CommentsController'
import { canPerformAction } from '@/helpers/canPerformAction'
import { isIFlexibleDocumentChapterResource } from '@/helpers/FlexibleDocument'

import { AuditsResource }                                                              from '@/models/audits/AuditsResource'
import { ELEMENT_EVENTS, FlexibleDocumentItemResource, IFlexibleDocumentItemResource } from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { IProjectUserResource, ProjectUserResource }                                   from '@/models/projects/ProjectUserResource'
import { CommentsService }                                                             from '@/services/comments'
import { FlexibleDocumentElementService }                                              from '@/services/flexibleDocumentElement'
import { Socket }                                                                      from 'socket.io-client'

type FlexibleDocumentChapterPermissions = 'can_comment' | 'can_edit' | 'can_delete' | string

export interface IFlexibleDocumentChapterResource extends IFlexibleDocumentItemResource {
  include_in_toc: boolean

  permissions: FlexibleDocumentChapterPermissions[]
  actor?: IProjectUserResource
  defined_by: IProjectUserResource
  comment_count: number
  channel: string
}

export class FlexibleDocumentChapterResource extends FlexibleDocumentItemResource {

  public include_in_toc: boolean

  public permissions: FlexibleDocumentChapterPermissions[]
  public actor?: ProjectUserResource
  public defined_by: ProjectUserResource
  public comment_count: number

  public elementService: FlexibleDocumentElementService
  public commentsService: CommentsService

  public comments: CommentsController
  public audits: AuditsResource

  public channel: string
  public socket?: Socket

  constructor({ include_in_toc, permissions, actor, defined_by, comment_count, channel, ...chapter }: IFlexibleDocumentChapterResource, project_id: number) {
    super(chapter, project_id)
    this.include_in_toc = include_in_toc

    this.permissions = permissions
    this.defined_by = defined_by ? new ProjectUserResource(defined_by) : defined_by
    this.actor = actor ? new ProjectUserResource(actor) : actor
    this.comment_count = comment_count

    this.elementService = new FlexibleDocumentElementService({ project_id, element_uuid: chapter.uuid })
    this.commentsService = new CommentsService()

    this.comments = new CommentsController({ id: chapter.uuid, service: this.elementService, commentableType: 'document_chapter', project_id })
    this.audits = new AuditsResource({ service: this.elementService })

    this.channel = channel
    this.project_id = project_id

    this.openSocket()
  }

  public destroy() {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public get heading(): string {
    return this.title || 'Chapter'
  }

  public canPerformAction(key: FlexibleDocumentChapterPermissions): boolean {
    return canPerformAction<FlexibleDocumentChapterPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<FlexibleDocumentChapterResource> {
    this.updateData(await this.service.refresh())
    return this
  }

  private updateData(data: IFlexibleDocumentItemResource): void {
    if (!isIFlexibleDocumentChapterResource(data)) return
    this.order = data.order
    this.title = data.title
    this.permissions = data.permissions
    this.actor = data.actor ? new ProjectUserResource(data.actor) : data.actor
    this.comment_count = data.comment_count
    this.is_locked = data.is_locked ?? false
    this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case ELEMENT_EVENTS.UPDATED:
        this.refresh().then()
        break
      case ELEMENT_EVENTS.COMMENT_CREATED:
      case ELEMENT_EVENTS.COMMENT_DELETED:
        this.comments.get().then()
        break
    }
    // always fetch audits
    this.audits.get().then()
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
