import { IProjectUserResource, ProjectUserResource }      from '@/models/projects/ProjectUserResource'
import { FlexibleDocumentUpdateItemAllocateUsersRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemAllocateUsersRequest'
import { FlexibleDocumentElementService }                 from '@/services/flexibleDocumentElement'

export interface IFlexibleDocumentItemCollectionResource {
  uuid: string
  order: number
  anchor_uuid: string
  type: 'Widget' | 'Component' | 'Chapter' | 'TitlePage' | 'Section'
  title: string
  comment_count: number
  blacklisted: boolean
  // Not available for components
  is_locked?: boolean
  allocated_users?: IProjectUserResource[]
  channel?: string
}

export class FlexibleDocumentItemCollectionResource {
  public readonly uuid: string
  public order: number
  public anchor_uuid: string
  public type: 'Widget' | 'Component' | 'Chapter' | 'TitlePage' | 'Section'
  public title: string
  public comment_count: number
  public blacklisted: boolean
  public is_locked: boolean
  public allocated_users: ProjectUserResource[]

  public project_id: number

  protected service: FlexibleDocumentElementService

  constructor(item: IFlexibleDocumentItemCollectionResource, project_id: number) {
    this.uuid = item.uuid
    this.order = item.order
    this.anchor_uuid = item.anchor_uuid
    this.title = item.title
    this.type = item.type
    this.comment_count = item.comment_count
    this.blacklisted = item.blacklisted
    this.is_locked = item.is_locked ?? false
    this.allocated_users = item.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []

    this.project_id = project_id

    this.service = new FlexibleDocumentElementService({ project_id, element_uuid: item.uuid })
  }

  public get heading(): string {
    return this.title
  }

  public async delete(): Promise<FlexibleDocumentItemCollectionResource> {
    await this.service.delete()
    return this
  }

  public async allocateUsers(body: FlexibleDocumentUpdateItemAllocateUsersRequest): Promise<FlexibleDocumentItemCollectionResource> {
    const data = await this.service.allocateUsers({ body })
    this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
    return this
  }

  public async lock(): Promise<FlexibleDocumentItemCollectionResource> {
    const data = await this.service.lock()
    this.is_locked = data.is_locked ?? false
    return this
  }

}


