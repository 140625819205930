import { FlexibleDocumentMetaFieldResource, IFlexibleDocumentMetaFieldResource } from './FlexibleDocumentMetaFieldResource'

export interface IFlexibleDocumentMetaSectionResource {
  section_name: string
  fields: IFlexibleDocumentMetaFieldResource[]
}

export class FlexibleDocumentMetaSectionResource {
  public section_name: string
  public fields: FlexibleDocumentMetaFieldResource[]
  constructor({ section_name, fields }: IFlexibleDocumentMetaSectionResource) {
    this.section_name = section_name
    this.fields = fields.map((field) => new FlexibleDocumentMetaFieldResource(field))
  }
}
