



































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SearchInput extends Vue {
  // Props
  @Prop()
  private name!: string

  @Prop()
  private label!: string

  @Prop()
  private errors!: ErrorResponse[]

  @Prop()
  private placeholder!: string

  @Prop({ default: 'text' })
  private type!: string
  // V-model prop
  @Prop()
  private value!: string

  @Prop({ type: String })
  private icon!: string | undefined

  @Prop({ type: Boolean })
  private search!: boolean | false

  @Prop({ type: String, default: 'right', validator(value: string): boolean {
      return ['right', 'left'].includes(value)
    }
  })
  private iconPosition!: string

  // Computed properties
  private get hasErrors(): boolean {
    return typeof this.errors === 'object' ? this.errors.length > 0 : false
  }

  private get errorString(): string | undefined {
    return this.hasErrors ? this.errors.join(', ') : undefined
  }

  private get labelString(): string {
    return this.errorString ? this.errorString : this.label
  }

  private clearSearch(): void {
    this.$emit('input', '')
    this.$nextTick(() => {
      this.$emit('search')
    })
  }
}
