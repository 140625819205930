







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SwitchInput extends Vue {

  @Prop()
  private value!: boolean

  @Prop()
  private name!: string

  @Prop()
  private label!: string | undefined

  @Prop({default: 'right'})
  private labelPosition!: 'left' | 'right' | 'top' | 'bottom'

  @Prop({ default: false })
  private loading!: boolean

  @Prop({ default: false })
  private disabled!: boolean

  private get classes(): Dictionary<boolean> {
    return {
      top: this.labelPosition === 'top',
      bottom: this.labelPosition === 'bottom',
      left: this.labelPosition === 'left',
      right: this.labelPosition === 'right',
      disabled: this.disabled
    }
  }
}
