















import { Vue, Component, Prop } from 'vue-property-decorator'

import { FlexibleDocumentWidgetCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'

@Component
export default class TableContentsWidgetItem extends Vue {
  @Prop()
  private index!: string | null

  @Prop()
  private item!: FlexibleDocumentWidgetCollectionResource

  private get headingText(): string {
    return this.index ? `${this.index} ${this.item.heading}` : `${this.item.heading}`
  }

  private get hasProposals(): boolean {
    return !!this.item.proposals && this.item.proposals.length > 0
  }

  private get showWidgetItem(): boolean {
    return this.item.widget_type === 'DataObject' || this.item.widget_type === 'TableList'
  }
}
