import * as Mutations                           from './mutation-types'
import { SystemNotification }                   from './types'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { GlobalService }                        from '@/services/global'

const globalService = new GlobalService()

@Module({
  namespaced: true,
  name: 'state'
})
class StateModule extends VuexModule {

  private _loading: number = 0
  private _notifications: SystemNotification[] = []
  private _open: boolean | null = null
  private _version: string = `v${process.env.PACKAGE_VERSION || '0'}`
  private _isNewVersionAvailable: boolean = false
  private _feedbackFormOpen: boolean = false

  public get loading (): boolean {
    return this._loading > 0
  }

  public get notifications (): SystemNotification[] {
    return this._notifications
  }

  public get openNotifications (): boolean | null {
    return this._open
  }

  public get version (): string {
    return this._version
  }

  public get isNewVersionAvailable (): boolean {
    return this._isNewVersionAvailable
  }

  public get feedbackFormOpen (): boolean {
    return this._feedbackFormOpen
  }

  @Action({ rawError: true, commit: Mutations.SET_NOTIFICATIONS })
  public setNotification (notification: SetSystemNotification): SetSystemNotification {
    return notification
  }

  @Action({ rawError: true, commit: Mutations.SET_FEEDBACK_FORM })
  public setFeedbackForm (payload: boolean): boolean {
    return payload
  }

  @Action({ rawError: true, commit: Mutations.SET_LOADING })
  public setLoading (payload: boolean): boolean {
    return payload
  }

  @Action({ rawError: true, commit: Mutations.SET_OPEN })
  public setNotificationsOpen (payload: boolean): boolean {
    return payload
  }

  @Action({ rawError: true, commit: Mutations.COMPARE_VERSIONS })
  public async checkVersion (): Promise<{ channel: string, version: string }> {
    try {
      const { data } = await globalService.getVersion()
      return data
    } catch (e) {
      throw e
    }
  }


  @Mutation
  private [Mutations.SET_NOTIFICATIONS] (notification: SetSystemNotification): void {
    const NOTIFICATION = new SystemNotification(notification)
    this._notifications.unshift(NOTIFICATION)
  }

  @Mutation
  private [Mutations.SET_LOADING] (payload: boolean): void {
    if (payload) {
      this._loading++
    } else {
      setTimeout(() => {
        this._loading--
      }, 200)
    }
  }

  @Mutation
  private [Mutations.SET_OPEN] (payload: boolean): void {
    this._open = payload
  }

  @Mutation
  private [Mutations.SET_FEEDBACK_FORM] (payload: boolean): void {
    this._feedbackFormOpen = payload
  }

  @Mutation
  private [Mutations.COMPARE_VERSIONS] (payload: { channel: string, version: string }): void {
    this._isNewVersionAvailable = payload.version !== this._version
  }

}

export default StateModule
