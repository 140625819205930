export default {
  editor: {
    actions: {
      mark: 'Mark text',
      view_changes: 'View changes',
      download: 'Download',
      font_switch: 'Change font size',
      view_publication: 'Open original publication'
    },
    filters: {
      all: 'Entire document',
      mine: 'Delegated to me',
      requirements: 'Requirements',
      marked: 'Marked requirements',
    },
    changes: {
      title: 'View changes',
      cancel: 'Cancel'
    }
  }
}
