var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"dropdownInput",class:_vm.classes},[(_vm.label)?_c('label',{staticClass:"label",attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.labelString)+" "),(_vm.description)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.description),expression:"description"}],staticClass:"tooltip-icon"},[_c('infoIcon')],1):_vm._e()]):_vm._e(),_vm._t("button",function(){return [_c('button',{staticClass:"main-button",attrs:{"type":"button"},on:{"click":_vm.toggleDropdown}},[_vm._t("value",function(){return [(!_vm.open && _vm.multiple && (_vm.wide || _vm.withBadges))?_c('span',{staticClass:"value"},[(_vm.selectedItems && _vm.selectedItems.length > 0)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              delay: [500, 0],
              content: _vm.optionLabel(_vm.selectedItems[0]),
              placement: 'bottom',
            }),expression:"{\n              delay: [500, 0],\n              content: optionLabel(selectedItems[0]),\n              placement: 'bottom',\n            }"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.truncatedLabel(_vm.selectedItems[0].label))+" ")]):_vm._e(),(_vm.selectedItems && _vm.selectedItems.length > 1)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              delay: [500, 0],
              content: _vm.multipleOptionsTooltip(_vm.selectedItems),
              placement: 'bottom',
            }),expression:"{\n              delay: [500, 0],\n              content: multipleOptionsTooltip(selectedItems),\n              placement: 'bottom',\n            }"}],staticClass:"badge",domProps:{"textContent":_vm._s(("+" + (_vm.selectedItems.length - 1)))}}):_vm._e(),(_vm.value.length === 0)?_c('span',{staticClass:"placeholder",domProps:{"textContent":_vm._s(_vm.placeholder)}}):_vm._e()]):[(_vm.value && _vm.value.length === 0)?_c('span',{staticClass:"value"},[_c('span',{staticClass:"placeholder",domProps:{"textContent":_vm._s(_vm.placeholder)}})]):[(!_vm.selectedItems)?_c('span',{staticClass:"placeholder",domProps:{"textContent":_vm._s(_vm.buttonText)}}):_c('span',{domProps:{"textContent":_vm._s(_vm.buttonText)}})]]]},{"value":_vm.value}),_c('chevronIcon',{class:{ up: _vm.open, down: !_vm.open, chevronicon: true }})],2)]},{"value":_vm.value,"open":_vm.open,"toggle":_vm.toggleDropdown}),_c('transition',{attrs:{"name":_vm.transition}},[(_vm.open)?_c('ul',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],class:_vm.positionClasses},[(_vm.multiple && _vm.wide)?_c('li',{staticClass:"fixed-top"},[_c('div',{staticClass:"values"},_vm._l((_vm.selectedItems),function(item,index){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              delay: [500, 0],
              content: _vm.optionLabel(item),
              placement: 'bottom',
            }),expression:"{\n              delay: [500, 0],\n              content: optionLabel(item),\n              placement: 'bottom',\n            }"}],key:index,staticClass:"badge active"},[_vm._v(" "+_vm._s(_vm.truncatedLabel(item.label))+" "),_c('span',{staticClass:"close",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selectItem({ option: item })}}})])}),0),(_vm.canSearch)?_c('SearchInput',{ref:"search",attrs:{"placeholder":'search ' + this.label.toLowerCase() + '...',"search":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('button',{staticClass:"choose-button all",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.deSelectMultipleText)},on:{"click":_vm.toggleSelectAll}})],1):_vm._e(),(!_vm.wide && _vm.canSearch)?_c('li',{staticClass:"fixed-top"},[_c('SearchInput',{ref:"search",attrs:{"placeholder":'search ' + this.label.toLowerCase() + '...',"search":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.canDeselect)?[(_vm.multiple && !_vm.wide)?_c('li',[_c('button',{staticClass:"choose-button all",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.deSelectMultipleText)},on:{"click":_vm.toggleSelectAll}})]):_c('li',[_c('button',{staticClass:"choose-button all",attrs:{"type":"button"},domProps:{"textContent":_vm._s("Deselect")},on:{"click":_vm.deSelect}})])]:_vm._e(),_vm._l((_vm.filteredOptions),function(option){return _c('MultiLayerDropdown',{key:option.value,attrs:{"value":_vm.value,"option":option,"multiple":_vm.multiple,"parentSelected":false,"search":_vm.search},on:{"input":_vm.selectItem}},[_vm._t("li",null,{"option":option})],2)})],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }