export interface IComponentValueResource {
  base_component: string
  component_id: number
  widget_id: number
  value: string
  order: number
  multiple: boolean
  label: string
  start_id?: number
  end_id?: number
  hide_label: boolean
  visible: boolean
}

export class ComponentValueResource {
  public base_component: string
  public component_id: number
  public widget_id: number
  public value: string
  public order: number
  public multiple: boolean
  public label: string
  public start_id?: number
  public end_id?: number
  public hide_label: boolean
  public visible: boolean = true

  constructor(component: IComponentValueResource) {
    this.base_component = component.base_component
    this.component_id = component.component_id
    this.widget_id = component.widget_id
    this.value = component.value
    this.order = component.order
    this.multiple = component.multiple
    this.label = component.label
    this.start_id = component.start_id
    this.end_id = component.end_id
    this.hide_label = component.hide_label
    this.visible = component.visible ?? true
  }
}
