// Scrolls element to the visible screen position

import { DirectiveOptions } from 'vue'
let fromTop: number = 0 // oryginal binding from top

export const visibleposition: DirectiveOptions = {
    bind(el, binding): void {
        fromTop = binding.value

        el.classList.add('visible-scroll-position')
        document.addEventListener('scroll', () => setBlockPos(el))
        setTimeout(() => { setBlockPos(el) }, 500)
    },
    unbind(): void {
        document.removeEventListener('scroll', () => setBlockPos(null))
    },
    update(el): void {
        setTimeout(() => { setBlockPos(el) })
    }
}

const setBlockPos = (el: any) => {
    let margin = 0
    const scrollPos = window.scrollY
    const elemH = el.clientHeight
    const docH = getDocHeight()

    if (scrollPos + elemH > docH) {
        margin = docH - elemH - fromTop
    } else {
        if (scrollPos > fromTop) margin = scrollPos - fromTop
    }
    el.style.marginTop = margin + 'px'
}

const getDocHeight = () => {
    return document.documentElement.scrollHeight
}
