export interface IFlexibleDocumentMetaFieldResource {
  key: string
  label: string
  placeholder?: string
  type: string
  span: number
  multiple?: boolean
  options?: SelectItem[]
  value?: string
}

export class FlexibleDocumentMetaFieldResource {
  public key: string
  public label: string
  public placeholder?: string
  public type: string
  public span: number
  public multiple?: boolean
  public options?: SelectItem[]
  public value?: string

  constructor({ key, label, placeholder, type, span, multiple, options, value }: IFlexibleDocumentMetaFieldResource) {
    this.key = key
    this.label = label
    this.placeholder = placeholder
    this.type = type
    this.span = span
    this.multiple = multiple
    this.options = options
    this.value = value
  }
}
