export default {
  overview: {
    table: {
      headers: {
        name: 'Name',
        entities_count: 'Number of entities',
        updated_at: 'Last update',
        end_date: 'Project deadline',
        created_at: 'Creation date',
        initiator: 'Initiator',
        status: 'Implementation status',
        project_type: 'Project type',
        implementation_lead: 'Implementation lead'
      },
    },
  },
}
