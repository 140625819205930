export const formatDate = (date: Date | null | undefined): string => {
  if (date) {
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`
  }

  return ''
}

export const formatTime = (date: Date): string => {
  return `${date.getHours().toString().padStart(2, '0')}:${(date.getMinutes() + 1).toString().padStart(2, '0')}`
}

export const formatFullDate = (date: Date): string => {
  return `${formatDate(date)} ${formatTime(date)}`
}

export const convertToDate = (date: string): Date => {
  return new Date(date)
}

export const formatDateForBackend = (date: Date | null): string => {
  if (date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
  }

  return ''
}

export const getHumanReadableDate = (date: Date, defaultFormatter: (date: Date) => string = formatDate): string => {
  if (isToday(date)) {
    return 'Today'
  } else if (isTomorrow(date)) {
    return 'Tomorrow'
  } else if (isYesterday(date)) {
    return 'Yesterday'
  }
  return defaultFormatter(date)
}

export const getHumanReadableDateWithTime = (date: Date, defaultFormatter: (date: Date) => string = formatFullDate): string => {
  if (isToday(date)) {
    return `Today at ${formatTime(date)}`
  } else if (isTomorrow(date)) {
    return `Tomorrow at ${formatTime(date)}`
  } else if (isYesterday(date)) {
    return `Yesterday at ${formatTime(date)}`
  }
  return defaultFormatter(date)
}

export const isToday = (date: Date) => {
  return isSpecificDay(date, 0)
}

export const isTomorrow = (date: Date) => {
  return isSpecificDay(date, 1)
}

export const isYesterday = (date: Date) => {
  return isSpecificDay(date, -1)
}

export const isSpecificDay = (date: Date, offset: number = 0) => {
  const day = new Date()
  day.setDate(day.getDate() + offset)
  return date.getDate() === day.getDate() &&
    date.getMonth() === day.getMonth() &&
    date.getFullYear() === day.getFullYear()
}
