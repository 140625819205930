import { AxiosPromise, AxiosResponse } from 'axios'
import { BaseService }                 from '@/services/base'

import { UserResource, IUserResource }                 from '@/models/users/UserResource'
import { AuthUserResource, IAuthUserResource, NotificationSettings }         from '@/models/users/AuthUserResource'
import { NotificationResource, INotificationResource } from '@/models/notifications/NotificationResource'

import { ChangePasswordRequest } from '@/requests/auth/ChangePasswordRequest'

export class UserService extends BaseService {
  constructor() {
    super('users')
  }

  /**
   * Get all users
   */
  public async get(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}`)
    data.data = data.data.map((user: IUserResource) => new UserResource((user)))
    return data as IndexResponse<UserResource>
  }

  /**
   * Get sounding users
   */
  public async getSounding(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/sounding`)
    data.data = data.data.map((user: any) => new UserResource((user)))
    return data as IndexResponse<UserResource>
  }

  /**
   * Get authenticated user
   */
  public async getMe(): Promise<DetailResponse<AuthUserResource>> {
    const { data } = await this.client.get<DetailResponse<IAuthUserResource>>(`${this.namespace}/me`)
    data.data = new AuthUserResource(data.data)
    return data as DetailResponse<AuthUserResource>
  }

  public async getNotifications(namespace: 'activities' | 'notifications'): Promise<IndexResponse<NotificationResource>> {
    const { data } = await this.client.get<IndexResponse<INotificationResource>>(`${this.namespace}/${namespace}`)
    return {
      ...data,
      data: data.data.map((notification: INotificationResource) => new NotificationResource((notification)))
    }
  }

  public async readNotifications(namespace: 'activities' | 'notifications'): Promise<void> {
    await this.client.patch<IndexResponse<INotificationResource>>(`${this.namespace}/${namespace}/read`)
    return
  }


  /**
   * Get authenticated user
   */
  public changePassword(data: ChangePasswordRequest): AxiosPromise<void> {
    return this.client.patch<void>(`${this.namespace}/password`, data)
  }

  /**
   * User logout
   */
  public logout(): AxiosPromise<void> {
    return this.client.post<void>(`${this.namespace}/logout`)
  }

  /**
   * Update the notification settings
   *
   * @param data
   */
  public updateNotificationSetting(data: NotificationSettings): AxiosPromise<void> {
    return this.client.patch<void>(`${this.namespace}/notification-settings`, data)
  }
}
