import { ProjectUserResource, IProjectUserResource } from '@/models/projects/ProjectUserResource'
import { formatDate, getHumanReadableDate } from '@/helpers/formatDate'

export interface INotificationResource {
  readonly id: number
  new: string | boolean
  type: string
  date: string
  time: string
  user: IProjectUserResource
  text: string
  link: string
  created_at?: string
}

export class NotificationResource {
  public readonly id: number
  public new: string | boolean
  public type: string
  public date: string
  public time: string
  public user: ProjectUserResource
  public text: string
  public link: string
  public created_at: Date

  constructor(notification: INotificationResource) {
    this.id = notification.id
    this.new = notification.new
    this.type = notification.type
    this.date = notification.date
    this.time = notification.time
    this.user = new ProjectUserResource(notification.user)
    this.text = notification.text
    this.link = notification.link
    this.created_at = notification.created_at ? new Date(notification.created_at) : new Date()
  }

  public get day(): string {
    return formatDate(this.created_at)
  }

  public get formattedCreatedAt(): string {
    return getHumanReadableDate(this.created_at, formatDate)
  }

  public get formattedDate(): string {
    return getHumanReadableDate(this.created_at)
  }
}
