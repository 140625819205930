import Vue                  from 'vue'
import { canPerformAction } from '@/helpers/canPerformAction'
import { ProcessService }   from '@/services/process'

import { WidgetResource }                                      from '@/models/widgets/WidgetResource'
import { ProposalResource }                                    from '@/models/proposals/ProposalResource'
import { IStatusResourceCollection, StatusResourceCollection } from '@/models/status/StatusResourceCollection'
import { ProposalCollectionResource }                          from '@/models/proposals/ProposalCollectionResource'
import { ProposalSummaryCollectionResource }                   from '@/models/proposals/ProposalSummaryCollectionResource'

import { ProposalIndexRequest }          from '@/requests/proposals/ProposalIndexRequest'
import { ProposalCreateRequest }         from '@/requests/proposals/ProposalCreateRequest'
import { ProposalSimilarityRequest }     from '@/requests/proposals/ProposalSimilarityRequest'
import { LinkedProposalIndexRequest }    from '@/requests/proposals/LinkedProposalIndexRequest'
import { ProposalsLockRequest }          from '@/requests/proposals/multiple/ProposalsLockRequest'
import { ProposalsDeleteRequest }        from '@/requests/proposals/multiple/ProposalsDeleteRequest'
import { ProposalsStatusPatchRequest }   from '@/requests/proposals/multiple/ProposalsStatusPatchRequest'
import { ProposalsAllocateUsersRequest } from '@/requests/proposals/multiple/ProposalsAllocateUsersRequest'

export type ProcessPermissions = 'can_create_proposal'

export type processType = 'marking' | 'document' | 'default'

export type componentFilter = { id: number, label: string | undefined, options: SelectItem[] }

export interface IProcessResource {
  id: number
  project_id: number
  process_name?: string
  name?: string
  description: string
  singular_name: string
  process_type?: processType
  order: number
  project_name: string
  proposals_count?: number
  proposals_allocation_count?: number
  statuses: IStatusResourceCollection[]
  widgets?: WidgetResource[]
  permissions: ProcessPermissions[]
  inherit_parent_title?: boolean
}

export class ProcessResource {
  public id: number
  public project_id: number
  public process_name: string
  public description: string
  public singular_name: string
  public order: number
  public proposals_count?: number
  public proposals_allocation_count: number
  public process_type?: processType
  public project_name: string
  public inherit_parent_title: boolean = false

  public statuses: StatusResourceCollection[] = []
  public widgets: WidgetResource[] = []

  public permissions: ProcessPermissions[]

  // public proposalsIndex: IndexResponse<ProposalSummaryCollectionResource> | null = null

  // Proposals
  // public proposals: IndexResponse<ProposalCollectionResource> | null = null
  // public proposalFilters: { [key: string]: { [key: string]: string } } = {}
  // public proposalsParams: ProposalIndexRequest = {
  //   status: undefined,
  //   page: '1',
  //   coverage: undefined,
  //   search: '',
  //   column: 'created_at',
  //   dir: 'desc',
  //   parent_ids: [],
  //   child_ids: [],
  //   component_values: {}
  // }

  public get coverageOptions(): SelectItem[] {
    return [
      { value: undefined, label: 'All' },
      { value: 'Covered', label: 'Covered' },
      { value: 'Uncovered', label: 'Uncovered' }
    ]
  }

  private processService: ProcessService

  constructor(process: IProcessResource) {
    this.id = process.id
    this.project_id = process.project_id
    this.process_name = (process.process_name || process.name) ?? ''
    this.description = process.description
    this.singular_name = process.singular_name
    this.process_type = process.process_type
    this.order = process.order
    this.project_name = process.project_name
    this.statuses = process.statuses.map((s) => new StatusResourceCollection(s))
    this.widgets = process.widgets ? process.widgets.map((item: any) => new WidgetResource(item)) : []
    this.processService = new ProcessService({ project_id: process.project_id, process_id: this.id })
    this.permissions = process.permissions
    this.inherit_parent_title = process.inherit_parent_title ?? false
    this.proposals_count = process.proposals_count ?? 0
    this.proposals_allocation_count = process.proposals_allocation_count ?? 0

    // this.setInitialFilters()
  }

  public get name() {
    return this.process_name
  }

  public get initialFilterValues(): { [key: string]: string[] } {
    const filters: { [key: number]: string[] } = {}
    for (const filter of this.componentFilters) {
      filters[filter.id] = []
    }
    return filters
  }

  public get componentFilters(): componentFilter[] {
    return this.widgets
        .flatMap(({ components }) => components.filter(({ base_component }) => base_component === 'Checkbox' || base_component === 'Dropdown'))
        .map(({ id, options, label = '' }) => options ? { id, label, options } : {
          id,
          label,
          options: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]
        })
  }

  public async refresh(): Promise<ProcessResource> {
    const { data } = await this.processService.get()
    this.statuses = data.statuses
    return this
  }

  public async getProposals(params: ProposalIndexRequest | LinkedProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    const data = await this.processService.getProposals(params)
    return data
  }

  public async getDocumentProposals(documentId: number, params: ProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    const data = await this.processService.getDocumentProposals(documentId, params)
    return data
  }

  public async getProposalsIndex(params?: ProposalIndexRequest): Promise<IndexResponse<ProposalSummaryCollectionResource>> {
    const data = await this.processService.getSummaryProposals(params)
    return data
  }

  public async getAvailableProposals(params?: ProposalIndexRequest): Promise<IndexResponse<ProposalSummaryCollectionResource>> {
    return await this.processService.getAvailableProposals(params)
  }

  public async getLinkedIncludedProposals(connected_process_id: number, body: LinkedProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    return await this.processService.getLinkedIncludedProposals(connected_process_id, body)
  }

  public async getProposal(proposal_id: number, similarity_id?: number): Promise<DetailResponse<ProposalResource>> {
    return await this.processService.getProposal(proposal_id, similarity_id)
  }

  public async checkSimilarity(body: ProposalSimilarityRequest): Promise<{ proposal: ProposalCollectionResource, similarity: ProposalCollectionResource[] }> {
    return await this.processService.checkSimilarity(body)
  }

  public async createProposal(body: ProposalCreateRequest) {
    return await this.processService.createProposal(body)
  }

  public async createProposals(body: ProposalCreateRequest) {
    return await this.processService.createProposals(body)
  }

  public canPerformAction(key: ProcessPermissions): boolean {
    return canPerformAction<ProcessPermissions>(this.permissions, key)
  }

  public async allocateUsersToProposals(body: ProposalsAllocateUsersRequest) {
    return await this.processService.allocateUsersToProposals(body)
  }

  public async patchStatusOfProposals(body: ProposalsStatusPatchRequest) {
    return await this.processService.patchStatusOfProposals(body)
  }

  public async toggleLockOfProposals(body: ProposalsLockRequest) {
    return await this.processService.toggleLockOfProposals(body)
  }

  public async deleteProposals(body: ProposalsDeleteRequest) {
    return await this.processService.deleteProposals(body)
  }


}
