















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CheckboxInput extends Vue {
  // Props
  @Prop() private name!: string
  @Prop() private label!: string
  @Prop() private title!: string
  @Prop() private errors!: ErrorResponse[]
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: false }) private secondaryColor!: boolean

  // V-model prop
  @Prop() private value!: string | boolean

  private get isChecked(): boolean {
    return typeof this.value === 'string' ? this.value === '1' : this.value
  }

  // Computed properties
  private get hasErrors(): boolean {
    return typeof this.errors === 'object' ? this.errors.length > 0 : false
  }

  private get errorString(): string | undefined {
    return this.hasErrors ? this.errors.join(', ') : undefined
  }

  private get labelString(): string {
    return this.errorString ? this.errorString : this.label
  }
}
