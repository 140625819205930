














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {
  @Prop() private loading!: boolean

}
