import { API }                    from '@/api'
import { canPerformAction }       from '@/helpers/canPerformAction'
import { CommentResource }        from '@/models/comments/CommentResource'
import { CommentCreateRequest }   from '@/requests/comments/CommentCreateRequest'
import { DocumentPatchRequest }   from '@/requests/documents/DocumentPatchRequest'
import { DocumentVersionRequest } from '@/requests/documents/DocumentVersionRequest'

export type DocumentPermissions = 'can_bookmark' | 'can_comment' | 'can_change_relevance' | 'can_create_version' | 'can_mark' | 'can_upload'

interface DocumentVersionsResource {
  id: number
  child: number | null
  parent_version_id: number
  version: string
}

export interface IDocumentResource {
  id: number
  subject: string
  name: string
  link: string
  version: string
  type: string
  issuer: string
  country: string
  theme: string
  service_line: string
  publish_date: string
  created_at: string
  implementation_date: string
  consultation_date: any
  end_date: string
  industry: string
  topic: string[]
  segment: string[]
  tags: string[]
  bookmarked: boolean
  relevance: string
  has_changes: boolean
  embedment: number
  comments: CommentResource[]
  document: string
  content: string
  proposal_id?: number
  permissions: DocumentPermissions[]
  previous_versions: DocumentVersionsResource[]
  projects: Array<{ name: string, owner: string }>
}

export class DocumentResource {
  public id: number
  public subject: string
  public name: string
  public link: string
  public version: string
  public type: string
  public issuer: string
  public country: string
  public theme: string
  public service_line: string
  public publish_date: string
  public created_at: string
  public implementation_date: string
  public consultation_date: any
  public end_date: string
  public industry: string
  public topic: string[]
  public segment: string[]
  public tags: string[]
  public bookmarked: boolean
  public relevance: string
  public has_changes: boolean
  public embedment: number
  public comments: CommentResource[]
  public document: string
  public content: string
  public proposal_id?: number
  public permissions: DocumentPermissions[]
  public previous_versions: DocumentVersionsResource[]
  public projects: Array<{ name: string, owner: string }>

  constructor(document: IDocumentResource) {
    this.id = document.id
    this.subject = document.subject
    this.name = document.name
    this.link = document.link
    this.version = document.version
    this.type = document.type
    this.issuer = document.issuer
    this.country = document.country
    this.theme = document.theme
    this.service_line = document.service_line
    this.publish_date = document.publish_date
    this.created_at = document.created_at
    this.implementation_date = document.implementation_date
    this.consultation_date = document.consultation_date
    this.end_date = document.end_date
    this.industry = document.industry
    this.topic = document.topic
    this.segment = document.segment
    this.tags = document.tags
    this.document = document.document
    this.embedment = document.embedment
    this.bookmarked = document.bookmarked
    this.relevance = document.relevance
    this.has_changes = document.has_changes
    this.proposal_id = document.proposal_id

    this.content = document.content
    this.permissions = document.permissions

    this.previous_versions = document.previous_versions
    this.projects = document.projects

    this.comments = document.comments ? document.comments.map((comment: any) => new CommentResource(comment)) : []
  }

  public async postVersion(body: DocumentVersionRequest): Promise<DocumentResource> {
    const { data } = await API.post(`documents/${this.id}/version`, body)
    return new DocumentResource(data.data)
  }

  public async patch(body: DocumentPatchRequest): Promise<DocumentResource> {
    const { data } = await API.patch(`documents/${this.id}`, body)
    this.bookmarked = data.data.bookmarked
    this.relevance = data.data.relevance
    return this
  }

  public async delete(): Promise<DocumentResource> {
    await API.delete(`documents/${this.id}`)
    return this
  }

  public async postComment(body: Omit<CommentCreateRequest, 'commentable_type' | 'commentable_id' | 'status'>): Promise<DocumentResource> {
    const { data } = await API.post(`comments`, { ...body, commentable_id: this.id, commentable_type: 'document', status: 'unresolved' })
    this.comments.push(new CommentResource(data.data))
    return this
  }

  public canPerformAction(key: DocumentPermissions): boolean {
    return canPerformAction<DocumentPermissions>(this.permissions, key)
  }

}
