import { createSocket } from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'

import { CommentsService } from '@/services/comments'
import { ProposalService } from '@/services/proposal'

import { IStatusResource, StatusResource }                     from '@/models/status/StatusResource'
import { AuditsResource }                                      from '@/models/audits/AuditsResource'
import { IWidgetResource, WidgetResource }                     from '@/models/widgets/WidgetResource'
import { CommentsController }                                  from '@/controllers/comments/CommentsController'
import { IProjectUserResource, ProjectUserResource }           from '@/models/projects/ProjectUserResource'
import { IStatusResourceCollection, StatusResourceCollection } from '@/models/status/StatusResourceCollection'
import { ProposalConnectionsResource }                         from '@/models/proposals/ProposalConnectionsResource'

import { ProposalStatusPatchRequest }                      from '@/requests/proposals/ProposalStatusPatchRequest'
import { ProposalPatchConnectionsRequest }                 from '@/requests/proposals/ProposalPatchConnectionsRequest'
import { SuggestionsResource }                             from '@/models/suggestions/SuggestionsResource'
import { ComponentValueResource, IComponentValueResource } from '../widgets/ComponentValueResource'
import { ProposalAllocateUsersRequest }                    from '@/requests/proposals/ProposalAllocateUsersRequest'
import { Socket }                                          from 'socket.io-client'


export const PROPOSAL_EVENTS = {
  UPDATED: 'proposal updated',
  COMMENT_CREATED: 'comment created',
  COMMENT_DELETED: 'comment deleted',
  SUGGESTION_CREATED: 'suggestion created'
}

export type ProposalCoverage = 'Covered' | 'Uncovered'
export type ProposalPermissions =
    'can_change_status'
    | 'can_release'
    | 'can_redefine'
    | 'can_comment'
    | 'can_create_proposal'
    | 'can_change_connections'
    | 'can_submit'
    | 'can_define'
    | 'can_approve'
    | 'can_decline'
    | 'can_edit_proposal'
    | 'can_delete_proposal'
    | 'can_suggest'
    | 'can_toggle_lock'

export type proposalResetType = 'release' | 'redefine' | 'define'

export interface IProposalResource {
  id: number
  proposal_name: string
  process_name: string
  process_singular_name: string
  actor?: IProjectUserResource
  defined_by: IProjectUserResource
  status: IStatusResource
  permissions: ProposalPermissions[]
  edited: boolean
  comment_count: number
  suggestion_count: number
  widgets: IWidgetResource[]
  statuses: IStatusResourceCollection[]
  components: IComponentValueResource[]
  channel: string
  is_locked: boolean
  allocated_users: IProjectUserResource[]
  // components: IComponentValueResource[]

  previous_linked_proposals: Array<{ proposal_name: string }>
  next_linked_proposals: Array<{ proposal_name: string }>

  previous_process_id: number
  next_process_id: number

  project_id: number
  process_id: number
}


export class ProposalResource {
  public readonly id: number
  public proposal_name: string
  public process_name: string
  public process_singular_name: string
  public actor?: ProjectUserResource
  public defined_by: ProjectUserResource
  public status: StatusResource
  public permissions: ProposalPermissions[]
  public edited: boolean
  public comment_count: number
  public suggestion_count: number
  public widgets: WidgetResource[]
  public statuses: StatusResourceCollection[]
  public components: ComponentValueResource[]
  public channel: string
  public is_locked: boolean
  public allocated_users: ProjectUserResource[]
  // public components: ComponentValueResource[]

  public previous_linked_proposals: Array<{ proposal_name: string }>
  public next_linked_proposals: Array<{ proposal_name: string }>

  public previous_process_id: number
  public next_process_id: number

  public project_id: number
  public process_id: number

  public proposalService: ProposalService
  public commentsService: CommentsService

  public readonly similarity_id?: number

  public comments: CommentsController
  public audits: AuditsResource
  public suggestions: SuggestionsResource

  public socket?: Socket

  private _deleted: boolean = false

  public get deleted(): boolean {
    return this._deleted
  }

  constructor(proposal: IProposalResource, similarity_id?: number) {
    this.id = proposal.id
    this.proposal_name = proposal.proposal_name
    this.process_name = proposal.process_name
    this.process_singular_name = proposal.process_singular_name

    this.status = new StatusResource(proposal.status)
    this.permissions = proposal.permissions
    this.edited = proposal.edited
    this.comment_count = proposal.comment_count
    this.suggestion_count = proposal.suggestion_count
    this.statuses = proposal.statuses.map((s) => new StatusResourceCollection(s))
    this.components = proposal.components ? proposal.components.map((item) => new ComponentValueResource(item)).sort((a, b) => a.order - b.order) : []
    this.channel = proposal.channel

    this.is_locked = proposal.is_locked ?? false
    this.allocated_users = proposal.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []

    this.previous_linked_proposals = proposal.previous_linked_proposals
    this.next_linked_proposals = proposal.next_linked_proposals

    this.previous_process_id = proposal.previous_process_id
    this.next_process_id = proposal.next_process_id

    this.defined_by = proposal.defined_by ? new ProjectUserResource(proposal.defined_by) : proposal.defined_by
    this.actor = proposal.actor ? new ProjectUserResource(proposal.actor) : proposal.actor
    this.widgets = proposal.widgets ? proposal.widgets.map((widget: any) => new WidgetResource(widget)) : []

    this.project_id = proposal.project_id
    this.process_id = proposal.process_id

    this.proposalService = new ProposalService({ project_id: proposal.project_id, process_id: proposal.process_id, proposal_id: proposal.id })
    this.commentsService = new CommentsService()

    this.similarity_id = similarity_id

    this.comments = new CommentsController({ id: proposal.id, service: this.proposalService, commentableType: 'proposal', params: { similarity_id } })
    this.audits = new AuditsResource({ service: this.proposalService, params: { similarity_id } })
    this.suggestions = new SuggestionsResource({
      id: proposal.id,
      suggestableType: 'proposal',
      service: this.proposalService,
      project_id: proposal.project_id
    })
    this.suggestions.get()

    this.openSocket()
  }

  public destroy(): void {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public async refresh(): Promise<ProposalResource> {
    const { data } = await this.proposalService.refresh()
    this.updateData(data)
    return this
  }

  public async patchStatus(body: ProposalStatusPatchRequest): Promise<ProposalResource> {
    const { data } = await this.proposalService.patchStatus(body)
    this.updateData(data)
    return this
  }

  public async patchConnections(body: ProposalPatchConnectionsRequest): Promise<ProposalResource> {
    const { data } = await this.proposalService.patchConnections(body)
    this.updateData(data)
    return this
  }

  public async getLinkedProposals(): Promise<ProposalConnectionsResource> {
    const { data } = await this.proposalService.getLinkedProposals({ similarity_id: this.similarity_id })
    return data
  }

  public async delete(): Promise<void> {
    await this.proposalService.delete()
  }

  public async allocateUsers(body: ProposalAllocateUsersRequest) {
    const data = await this.proposalService.allocateUsers(body)
    this.allocated_users = data.data.allocated_users.map((user) => new ProjectUserResource(user))
    return this
  }

  public async lock() {
    const data = await this.proposalService.lock()
    this.is_locked = data.data.is_locked
    return this
  }

  public canPerformAction(key: ProposalPermissions): boolean {
    return canPerformAction<ProposalPermissions>(this.permissions, key)
  }

  private updateData(proposal: IProposalResource): void {
    this.proposal_name = proposal.proposal_name
    this.process_name = proposal.process_name

    this.status = new StatusResource(proposal.status)
    this.permissions = proposal.permissions
    this.edited = proposal.edited
    this.comment_count = proposal.comment_count
    this.suggestion_count = proposal.suggestion_count
    this.statuses = proposal.statuses.map((s) => new StatusResourceCollection(s))
    this.channel = proposal.channel
    this.components = proposal.components ? proposal.components.map((item) => new ComponentValueResource(item)).sort((a, b) => a.order - b.order) : []

    this.previous_linked_proposals = proposal.previous_linked_proposals
    this.next_linked_proposals = proposal.next_linked_proposals
    this.allocated_users = proposal.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
    this.actor = proposal.actor ? new ProjectUserResource(proposal.actor) : proposal.actor
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case PROPOSAL_EVENTS.UPDATED:
        this.refresh().then()
        this.audits.get().then()
        this.suggestions.get().then()
        break
      case PROPOSAL_EVENTS.COMMENT_CREATED:
      case PROPOSAL_EVENTS.COMMENT_DELETED:
        this.refresh().then()
        this.comments.get().then()
        this.audits.get().then()
        break
      case PROPOSAL_EVENTS.SUGGESTION_CREATED:
        this.refresh().then()
        this.suggestions.get().then()
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
