import { convertToDate, formatDate }                          from '@/helpers/formatDate'
import { ProjectUserResource }                                from '@/models/projects/ProjectUserResource'
import { ProjectPermissions, ProjectTypes, IProjectResource } from './ProjectResource'

import { IFlexibleDocumentMetaResource }  from '@/models/flexibleDocument/flexibleDocumentMetaResource'
import { FlexibleDocumentStatusResource } from '@/models/status/FlexibleDocumentStatusResource'

export class ProjectParentResource {
  public readonly id: number
  public project_name: string
  public owner: ProjectUserResource | string
  public permissions: ProjectPermissions[]
  public end_date: Date | string
  public last_update: string
  public has_template: boolean
  public type: ProjectTypes
  public document_title: string | undefined
  public document_status: FlexibleDocumentStatusResource | undefined
  public parent_project?: ProjectParentResource | null
  public meta?: IFlexibleDocumentMetaResource | null
  public meta_values?: IFlexibleDocumentMetaResource | null
  public pdf_link?: string | null
  public documents_count: number

  constructor(project: IProjectResource) {
    this.id = project.id
    this.project_name = project.project_name
    this.owner = project.owner && typeof project.owner !== 'string' ? new ProjectUserResource(project.owner) : project.owner
    this.permissions = project.permissions
    this.end_date = convertToDate(project.end_date as string)
    this.last_update = project.last_update
    this.has_template = project.has_template
    this.type = project.type
    this.document_title = project.document_title
    this.meta = project.meta
    this.meta_values = project.meta_values
    this.pdf_link = project.pdf_link
    this.documents_count = project.documents_count ?? 0

    if (project.document_status) {
      this.document_status = new FlexibleDocumentStatusResource(project.document_status)
    }

    if (project.parent_project) {
      this.parent_project = new ProjectParentResource(project.parent_project)
    }
  }

  public get endDateFormatted(): string {
    return formatDate(this.end_date as Date)
  }

}
