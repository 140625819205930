




import { Component, Vue, Prop } from 'vue-property-decorator'
import WysiwygEditor from '@/components/editors/Wysiwyg.vue'

@Component({components: {
    WysiwygEditor
}})
export default class TextEditor extends Vue {
    @Prop({required: true, default: null})
    public readonly value!: any

    @Prop({ default: false })
    private noToolbar!: boolean

    @Prop({ default: false })
    private disabled!: boolean
}
