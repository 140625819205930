import { API } from '@/api'
import { AxiosInstance } from 'axios'

export class BaseService {
  /**
   *  Axios instance
   *
   * @protected
   * @type {AxiosInstance}
   * @memberof BaseService
   */
  public client: AxiosInstance

  /**
   *  Url for users API
   *
   * @private
   * @type {string}
   * @memberof UserService
   */
  protected namespace: string

  /**
   * Creates an instance of BaseService .
   * @memberof BaseService
   */
  constructor(namespace: string) {
    this.namespace = namespace
    this.client = API
  }

}
