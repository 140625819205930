import { render, staticRenderFns } from "./ProposalCard.vue?vue&type=template&id=d3c9084c&scoped=true&"
import script from "./ProposalCard.vue?vue&type=script&lang=ts&"
export * from "./ProposalCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProposalCard.vue?vue&type=style&index=0&id=d3c9084c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c9084c",
  null
  
)

export default component.exports