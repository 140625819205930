export const openModal = (): void => {
  document.body.classList.add('modal-open')
}

export const closeModal = (): void => {
  document.body.classList.remove('modal-open')
}

export const toggleModal = (val: boolean): void => {
  if (val) {
    openModal()
  } else {
    closeModal()
  }
}
