











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Tabs extends Vue {

}
