import { FlexibleDocumentItemWithToc }                 from '@/models/flexibleDocument/FlexibleDocumentItemWithToc'
import {
  FlexibleDocumentItemResource,
  IFlexibleDocumentItemResource
}                                                      from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemWithItems }               from '@/models/flexibleDocument/FlexibleDocumentItemWithItems'
import { FlexibleDocumentItemCollection }              from '@/models/flexibleDocument/FlexibleDocumentItemCollection'
import {
  FlexibleDocumentWidgetResource,
  IFlexibleDocumentWidgetResource
}                                                      from '@/models/flexibleDocument/FlexibleDocumentWidgetResource'
import {
  FlexibleDocumentChapterResource,
  IFlexibleDocumentChapterResource
}                                                      from '@/models/flexibleDocument/FlexibleDocumentChapterResource'
import {
  FlexibleDocumentSectionResource,
  IFlexibleDocumentSectionResource
}                                                      from '@/models/flexibleDocument/FlexibleDocumentSectionResource'
import { FlexibleDocumentItemWithComments }            from '@/models/flexibleDocument/FlexibleDocumentItemWithComments'
import {
  FlexibleDocumentTitlePageResource,
  IFlexibleDocumentTitlePageResource
}                                                      from '@/models/flexibleDocument/FlexibleDocumentTitlePageResource'
import { FlexibleDocumentComponentResource }           from '@/models/flexibleDocument/FlexibleDocumentComponentResource'
import {
  FlexibleDocumentItemCollectionResource,
  IFlexibleDocumentItemCollectionResource
}                                                      from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'
import { FlexibleDocumentWidgetCollectionResource }    from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'
import { FlexibleDocumentChapterCollectionResource }   from '@/models/flexibleDocument/FlexibleDocumentChapterCollectionResource'
import { FlexibleDocumentSectionCollectionResource }   from '@/models/flexibleDocument/FlexibleDocumentSectionCollectionResource'
import { FlexibleDocumentTitlePageCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentTitlePageCollectionResource'
import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'
import { FlexibleDocumentItemWithSuggestions }         from '@/models/flexibleDocument/FlexibleDocumentItemWithSuggestions'

export type FlexibleDocumentResetTypes = 'release' | 'redefine'

export const convertToFlexibleDocumentCollectionItem = (item: FlexibleDocumentItemCollectionResource, project_id: number) => {
  if (isFlexibleDocumentWidgetCollectionResource(item)) {
    return new FlexibleDocumentWidgetCollectionResource(item, project_id)
  } else if (isFlexibleDocumentComponentCollectionResource(item)) {
    return new FlexibleDocumentComponentCollectionResource(item, project_id)
  } else if (isFlexibleDocumentChapterCollectionResource(item)) {
    return new FlexibleDocumentChapterCollectionResource(item, project_id)
  } else if (isFlexibleDocumentSectionCollectionResource(item)) {
    return new FlexibleDocumentSectionCollectionResource(item, project_id)
  } else if (isFlexibleDocumentTitlePageCollectionResource(item)) {
    return new FlexibleDocumentTitlePageCollectionResource(item, project_id)
  }

  // if nothing, return the class all classes inherit from just to tell typescript this will never return undefined
  return new FlexibleDocumentItemCollectionResource(item, project_id)
}

export const convertToFlexibleDocumentItem = (item: FlexibleDocumentItemResource, project_id: number) => {
  if (isFlexibleDocumentWidgetResource(item)) {
    return new FlexibleDocumentWidgetResource(item, project_id)
  } else if (isFlexibleDocumentComponentResource(item)) {
    return new FlexibleDocumentComponentResource(item, project_id)
  } else if (isFlexibleDocumentSectionResource(item)) {
    return new FlexibleDocumentSectionResource(item, project_id)
  } else if (isFlexibleDocumentChapterResource(item)) {
    return new FlexibleDocumentChapterResource(item, project_id)
  } else if (isFlexibleDocumentTitlePageResource(item)) {
    return new FlexibleDocumentTitlePageResource(item, project_id)
  }

  // if nothing, return the class all classes inherit from just to tell typescript this will never return undefined
  return new FlexibleDocumentItemResource(item, project_id)
}

export const includedInTocSubItems = (items: FlexibleDocumentItemCollectionResource[]): FlexibleDocumentChapterCollectionResource[] => {
  return items.filter((item) => {
    return isFlexibleDocumentItemWithToc(item) || isFlexibleDocumentWidgetCollectionResourceVisibleInToc(item)
  }) as FlexibleDocumentChapterCollectionResource[]
}

export const createOrderObject = ({ uuid, order }: FlexibleDocumentItemCollectionResource): { uuid: string; order: number } => {
  return { uuid, order }
}

export const canBeMoved = ({ item, depth }: { item: FlexibleDocumentItemCollection; depth: number }): boolean => {
  // Chapters and title pages can only be dragged on depth level 0
  const isChapterOrTitlePageAndDepthZero =
      isFlexibleDocumentChapterCollectionResource(item) || (isFlexibleDocumentTitlePageCollectionResource(item) && depth === 0)
  // Sections can only be dragged on depth level 1
  const isSectionAndDepthOne = isFlexibleDocumentSectionCollectionResource(item) && depth === 1
  // Widgets can be dragged on depth levels greater than 0
  // We cannot really filter out the footer rules here because when moving to another draggable area we don't get the context of the new group
  // So we filter this in menuItem.vue based on two separate groups (itemsWithFooter, itemsWithoutFooter)
  const isWidgetAndDepthGreaterOrEqualToOne = isFlexibleDocumentWidgetCollectionResource(item) && depth >= 1
  // Footers can only be dragged on depth level 1 (chapters)
  const isWidgetFooter = isFlexibleDocumentWidgetCollectionResource(item) && item.widget_type === 'Footer'
  const isWidgetFooterAndDepthEqualToOne = isWidgetFooter && depth === 1

  if (isChapterOrTitlePageAndDepthZero || isSectionAndDepthOne) {
    return true
  } else if (isWidgetFooter) {
    return isWidgetFooterAndDepthEqualToOne
  } else {
    return isWidgetAndDepthGreaterOrEqualToOne
  }
}

// Flatten all sub items as one array
export const flatten = (items: FlexibleDocumentItemCollection[]): FlexibleDocumentItemCollection[] => {
  return items.reduce((acc: FlexibleDocumentItemCollection[], i: FlexibleDocumentItemCollection) => {
    if (i.items) {
      acc.push(i)
      if (isFlexibleDocumentItemWithItems(i) && i.items.length) {
        acc = [...acc, ...flatten(i.items)]
      }
    }
    return acc
  }, [])
}

// Collections
export const isFlexibleDocumentTitlePageCollectionResource = (
    item: FlexibleDocumentItemCollectionResource
): item is FlexibleDocumentTitlePageCollectionResource => {
  return item.type === 'TitlePage'
}

export const isFlexibleDocumentComponentCollectionResource = (
    item: FlexibleDocumentItemCollectionResource
): item is FlexibleDocumentComponentCollectionResource => {
  return item.type === 'Component'
}

export const isFlexibleDocumentChapterCollectionResource = (
    item: FlexibleDocumentItemCollectionResource
): item is FlexibleDocumentChapterCollectionResource => {
  return item.type === 'Chapter'
}

export const isFlexibleDocumentSectionCollectionResource = (
    item: FlexibleDocumentItemCollectionResource
): item is FlexibleDocumentSectionCollectionResource => {
  return item.type === 'Section'
}

export const isFlexibleDocumentWidgetCollectionResource = (
    item: FlexibleDocumentItemCollectionResource
): item is FlexibleDocumentWidgetCollectionResource => {
  return item.type === 'Widget'
}
export const isFlexibleDocumentWidgetCollectionResourceVisibleInToc = (
    item: FlexibleDocumentItemCollectionResource
): item is FlexibleDocumentWidgetCollectionResource => {
  return isFlexibleDocumentWidgetCollectionResource(item) &&
      (// item.widget_type === 'DataObject' ||
          item.widget_type === 'TableList')
}
export const isFlexibleDocumentTitlePageResource = (item: FlexibleDocumentItemResource | FlexibleDocumentItemCollectionResource): item is FlexibleDocumentTitlePageResource => {
  return item.type === 'TitlePage'
}

export const isFlexibleDocumentComponentResource = (item: FlexibleDocumentItemResource | FlexibleDocumentItemCollectionResource): item is FlexibleDocumentComponentResource => {
  return item.type === 'Component'
}

export const isFlexibleDocumentChapterResource = (item: FlexibleDocumentItemResource | FlexibleDocumentItemCollectionResource): item is FlexibleDocumentChapterResource => {
  return item.type === 'Chapter'
}

export const isFlexibleDocumentSectionResource = (item: FlexibleDocumentItemResource | FlexibleDocumentItemCollectionResource): item is FlexibleDocumentSectionResource => {
  return item.type === 'Section'
}

export const isFlexibleDocumentWidgetResource = (item: FlexibleDocumentItemResource | FlexibleDocumentItemCollectionResource): item is FlexibleDocumentWidgetResource => {
  return item.type === 'Widget'
}

export const isIFlexibleDocumentTitlePageResource = (item: IFlexibleDocumentItemResource | IFlexibleDocumentItemCollectionResource): item is IFlexibleDocumentTitlePageResource => {
  return item.type === 'TitlePage'
}

export const isIFlexibleDocumentChapterResource = (item: IFlexibleDocumentItemResource | IFlexibleDocumentItemCollectionResource): item is IFlexibleDocumentChapterResource => {
  return item.type === 'Chapter'
}

export const isIFlexibleDocumentSectionResource = (item: IFlexibleDocumentItemResource | IFlexibleDocumentItemCollectionResource): item is IFlexibleDocumentSectionResource => {
  return item.type === 'Section'
}

export const isIFlexibleDocumentWidgetResource = (item: IFlexibleDocumentItemResource | IFlexibleDocumentItemCollectionResource): item is IFlexibleDocumentWidgetResource => {
  return item.type === 'Widget'
}

export const isFlexibleDocumentItemWithItems = (item: FlexibleDocumentItemCollectionResource): item is FlexibleDocumentItemWithItems => {
  return (
      isFlexibleDocumentTitlePageCollectionResource(item) ||
      isFlexibleDocumentChapterCollectionResource(item) ||
      isFlexibleDocumentSectionCollectionResource(item)
  )
}

export const isFlexibleDocumentItemWithToc = (item: FlexibleDocumentItemCollectionResource): item is FlexibleDocumentItemWithToc => {
  return isFlexibleDocumentChapterCollectionResource(item) || isFlexibleDocumentSectionCollectionResource(item)
}

export const isFlexibleDocumentItemWithComments = (item: FlexibleDocumentItemResource): item is FlexibleDocumentItemWithComments => {
  return (
      isFlexibleDocumentWidgetResource(item) ||
      isFlexibleDocumentChapterResource(item) ||
      isFlexibleDocumentTitlePageResource(item) ||
      isFlexibleDocumentSectionResource(item)
  )
}
export const isFlexibleDocumentItemWithSuggestions = (item: FlexibleDocumentItemResource): item is FlexibleDocumentItemWithSuggestions => {
  return isFlexibleDocumentWidgetResource(item)
}

export const getTocIndex = (item: FlexibleDocumentItemCollectionResource, collection: FlexibleDocumentItemCollectionResource[]): number => {
  if (isFlexibleDocumentChapterCollectionResource(item) || isFlexibleDocumentSectionCollectionResource(item) || isFlexibleDocumentWidgetCollectionResourceVisibleInToc(item)) {
    const collectionFiltered = collection.filter((i) => {
      return (isFlexibleDocumentChapterCollectionResource(i) || isFlexibleDocumentSectionCollectionResource(i) ? i.include_in_toc : false) || isFlexibleDocumentWidgetCollectionResourceVisibleInToc(i)
    })
    return collectionFiltered.findIndex((i) => i.uuid === item.uuid)
  }
  return -1
}

export const isWidgetIncludedInToc = (widget: FlexibleDocumentWidgetCollectionResource): boolean => {
  const TOC_COMPONENT = widget.items.find((item) => item.component_key === 'include_widget_in_toc') ?? null

  if (TOC_COMPONENT) {
    return TOC_COMPONENT.component_value === true
  }

  return false
}
