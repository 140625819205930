
import {Component, Vue, Watch, Ref, Prop} from 'vue-property-decorator'

const animationsComplete = (element: HTMLElement) =>
    Promise.allSettled(
        element.getAnimations().map((animation) =>
            animation.finished))

@Component
export default class FullScreenModal extends Vue {

  @Prop()
  private readonly open!: boolean

  @Ref('dialog')
  private readonly dialog!: HTMLDialogElement

  private initialLoading: boolean = true

  @Watch('open')
  private onOpenChange(val: boolean) {
    val ? this.dialog.show() : this.dialog.close()
  }

  private mounted() {
    this.handleInitialLoading()
    this.dialog.addEventListener('close', this.dialogClose)
    window.addEventListener('keydown', this.keyboardClose)
  }

  private async handleInitialLoading() {
    await animationsComplete(this.dialog)
    this.initialLoading = false
  }

  private beforeDestroy() {
    window.removeEventListener('keydown', this.keyboardClose)
  }

  private keyboardClose(event: KeyboardEvent) {
    if (event.key === 'Escape') this.dialogClose()
  }

  private async dialogClose() {
    this.$emit('close')
  }

}
