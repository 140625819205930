export const LANGUAGES = {
  en: {
    auth: require('./en/auth').default,
    users: require('./en/users').default,
    modals: require('./en/modals').default,
    policy: require('./en/policy').default,
    projects: require('./en/projects').default,
    document: require('./en/document').default,
    documents: require('./en/documents').default,
    navigation: require('./en/navigation').default,
    paginations: require('./en/paginations').default,
    requirements: require('./en/requirements').default,
    implementations: require('./en/implementations').default,
  }
}
