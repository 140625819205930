import { IUserResource, UserResource } from '@/models/users/UserResource'

export interface IProjectUserResource extends IUserResource {
  id: number
  project_id: number
  project_role: string
  image: string
  first_name: string
  last_name: string
  permissions: string[]
  created_at: string
  updated_at: string
  available_roles?: string[]
}

export class ProjectUserResource extends UserResource {

  public permissions: string[]
  public project_id: number
  public project_role: string

  constructor (user: IProjectUserResource) {
    super(user)
    this.project_id = user.project_id
    this.permissions = user.permissions
    this.project_role = user.project_role
  }
}
