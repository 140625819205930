export class ProposalStatusPatchRequest {
  public status: string
  public status_message?: string
  public notify_user?: number[]

  constructor({ status, status_message, notify_user }: any) {
    this.status = status
    this.status_message = status_message
    this.notify_user = notify_user
  }
}
