import { BaseService } from '@/services/base'

import { ProcessResource, IProcessResource }                     from '@/models/process/ProcessResource'
import { ProjectResource, IProjectResource }                     from '@/models/projects/ProjectResource'
import { DocumentResource, IDocumentResource }                   from '@/models/documents/DocumentResource'
import { ProjectUserResource, IProjectUserResource }             from '@/models/projects/ProjectUserResource'
import { NotificationResource, INotificationResource }           from '@/models/notifications/NotificationResource'
import { ProjectCollectionResource, IProjectCollectionResource } from '@/models/projects/ProjectCollectionResource'

import { ProjectCreateRequest }                                          from '@/requests/projects/ProjectCreateRequest'
import { ProjectUserIndexRequest }                                       from '@/requests/projects/ProjectUserIndexRequest'
import { ProjectTypeResourceCollection, IProjectTypeResourceCollection } from '@/models/projectType/ProjectTypeResourceCollection'
import { ProjectCloneRequest }                                           from '@/requests/projects/ProjectCloneRequest'
import { AxiosResponse }                                                 from 'axios'

export class ProjectsService extends BaseService {

  constructor() {
    super('projects')
  }

  /**
   * Get all projects with params
   */
  public async get(params: IndexParameters): Promise<IndexResponse<ProjectCollectionResource>> {
    const body = await this.client.get(`${this.namespace}`, { params })
    const projects = body.data.data.map((project: IProjectCollectionResource) => new ProjectCollectionResource(project))
    const { data, ...rest } = body.data
    return { data: projects, ...rest }
  }

  /**
   * Get all library updates
   */
  public async getLibUpdates(): Promise<IndexResponse<ProjectCollectionResource>> {
    const body = await this.client.get(`${this.namespace}/library/updates`)
    const projects = body.data.data.map((project: IProjectCollectionResource) => new ProjectCollectionResource(project))
    const { data, ...rest } = body.data
    return { data: projects, ...rest }
  }

  /**
   *  Retrieve project users (team members) by their role.
   */
  public async getUsers(project_id: number, params?: ProjectUserIndexRequest): Promise<IndexResponse<ProjectUserResource>> {
    const { data } = await this.client.get(`${this.namespace}/${project_id}/users`, { params })
    data.data = data.data.map((item: IProjectUserResource) => new ProjectUserResource(item))
    return data as IndexResponse<ProjectUserResource>
  }

  /**
   *  Retrieve project process.
   */
  public async getProcess(project_id: number, process_id: number): Promise<DetailResponse<ProcessResource>> {
    const { data } = await this.client.get(`${this.namespace}/${project_id}/processes/${process_id}`)
    data.data = new ProcessResource(data.data)
    return data as DetailResponse<ProcessResource>
  }

  /**
   * Get recent projects
   */
  public async getRecent(): Promise<IndexResponse<ProjectCollectionResource>> {
    const body = await this.client.get(`${this.namespace}/recent`)
    const projects = body.data.data.map((project: IProjectCollectionResource) => new ProjectCollectionResource(project))
    const { data, ...rest } = body.data
    return { data: projects, ...rest }
  }

  /**
   * Get project by project ID
   */
  public async getById(project_id: number): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.get(`${this.namespace}/${project_id}`)
    data.data = new ProjectResource(data.data)
    return data as DetailResponse<ProjectResource>
  }

  /**
   * Create a new project
   */
  public async create(body: ProjectCreateRequest): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.post(`${this.namespace}`, body.getRequestData())
    data.data = new ProjectResource(data.data)
    return data as DetailResponse<ProjectResource>
  }

  /**
   * Get document by document ID and project ID
   */
  public async getDocument(project_id: number, document_id: number): Promise<DetailResponse<DocumentResource>> {
    const { data } = await this.client.get(`${this.namespace}/${project_id}/documents/${document_id}`)
    data.data = new DocumentResource(data.data)
    return data as DetailResponse<DocumentResource>
  }

  /**
   * Get document by document ID and project ID
   */
  public async clone(body: ProjectCloneRequest): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.post(`${this.namespace}/clone`, body.getRequestData())
    data.data = new ProjectResource(data.data)
    return data
  }

  /**
   * Export the project contents.
   *
   * @param projectIds
   */
  public async exportProjectContents(projectIds: number[]): Promise<AxiosResponse> {
    const { data } = await this.client.post(`${this.namespace}/export`, { project_ids: projectIds })

    return data
  }

}
